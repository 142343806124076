import * as React from 'react'
import Datetime from 'react-datetime'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { showConfirmModal, showCalendarEventModal } from '../store/modals/actions'
import ScrollToTopOnMount from '../components/Effects/ScrollToTopOnMount'
import TopNavigation from '../components/Navigation/TopNavigation'
import { AppState } from '../store'
import { WithTranslation, withTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router'
import { momentLocalizer, stringOrDate, View } from 'react-big-calendar'
import moment from '../utilities/Moment'
import styled, { css } from 'styled-components'
import { Style } from '../styles'
import { CalendarController } from '../controllers'
import ERoute from '../ERoute'
import RouteHelper from '../helpers/RouteHelper'
import { Moment } from 'moment'
import CalendarSidebarFilter, { ICalendarFilter } from '../components/Calendar/CalendarSidebarFilter'
import Fuse from 'fuse.js'
import UrlHelper from '../helpers/UrlHelper'
import LocalStorage, { LocalStorageKey } from '../LocalStorage'
import Images from '../images'
import CurrentUserHelper from '../helpers/CurrentUserHelper'
import FullPageContent from '../components/Page/FullPageContent'
import { CalendarEvent, CalendarViewEvent, CalendarViewEventType, CurrentUser } from '../types'
import CalendarSidebarIntegrations from '../components/Calendar/CalendarSidebarIntegrations'
import Icon from '../components/Icons/Icon'
import SidebarClose from '../components/Sidebar/SidebarClose'
import CalendarComponent from '../components/Calendar/Calendar'
import ReactToPrint from 'react-to-print';


const PageContent = styled(FullPageContent)`
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &::after {
    display: none;
  }

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    padding-left: 0;
    padding-right: 0;
  }
`

const PageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  overflow-y: auto;
  z-index: 6;
`

const CalendarTodayButton = styled.div`
  background-color: #FCFCFC;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.04);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  padding: 8px;
  width: 100%;
  cursor: pointer;
  margin-bottom: ${Style.spacing.x2_5};

  &:hover {
    background-color: #E3E3E3;
  }
`

const CalendarMobileHeader = styled.div`
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 15px;

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    display: flex;
  }

  ${CalendarTodayButton} {
    margin-bottom: 0;
  }
`

const OpenMenuButton = styled.svg`
  cursor: pointer;
`

const CalendarSidebar = styled.div<{ active?: boolean }>`
  padding: 10px ${Style.spacing.x2_5};
  width: 280px; 
  min-width: 280px;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  background: #F7F7F7;

  @media screen and (max-width: ${Style.breakpoints.SMALL}){
    padding-top: 20px;
    position: fixed;
    left: -100%;
    width: 100%;
    z-index: 7;

    ${props => props.active && css`
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    `}
  }
`

const CalendarSidebarCloseContainer = styled.div`
  display: none;
  width: 100%;
  position: relative;
  height: 14px;

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    display: block;
  }
`

const CalendarTypeItems = styled.div`
  display: flex;
  flex-direction: row;

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    justify-content: center;
  }
`

const CalendarTypeItem = styled.div<{ active?: boolean }>`
  font-size: 14px;
  overflow: hidden;
  cursor: pointer;
  padding: 2px 10px;
  border-radius: 100px;

  &:not(:last-child) {
    margin-right: 2px;
  }

  &:hover {
    background: #E3E3E3;
  }

  ${props => props.active && css`
    background: #E3E3E3;
  `}
`

const CalendarDatePicker = styled.div`
  margin-bottom: 10px;

  .rdt, .rdtPicker {
    overflow: visible;
    width: 100%;
    background-color: transparent;
    color: black;
    font-weight: normal;
    line-height: 24px;
    padding: 0;
  }

  .rdtPicker {
    td {
      font-weight: normal;
    }
  }

  .rdtToday {
    &::before {
      border-bottom-color: black !important;
    }
  }

  .rdtDays {
    table {
      border-collapse: collapse;
    }
    th {
      &.dow {
        color: black;
        font-weight: 500;
      }
    }

    .rdtPrev, .rdtNext {
      margin-bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 25px;
        width: 40px;
        height: 40px;
        min-width: 40px;
        min-height: 40px;
        max-width: 40px;
        max-height: 40px;
        border-radius: 50%;
        color: black;
        font-weight: 400;
        padding-bottom: 4px;

        &:hover {
          background: #BFBFBF;
        }
      }
    }

    .rdtSwitch {
      font-weight: 500;
    }
  }

  .rdtDay {
    padding: 1px 0;
    &.rdtOld, &.rdtNew {
      color: #9A9A9A;
    }
    &.rdtActive {
      background: transparent;
      color: black;

      &:hover {
        background: transparent;
        color: black;
      }

      &::before {
        color: black;
        border-bottom-color: black !important;
      }
    }
  }
`

const DatetimeDay = styled.td<{ active?: boolean }>`
  width: calc(224px / 7) !important;
  min-width: calc(224px / 7) !important;

  ${props => props.active && css`
    border: 1px solid black;
    background: white !important;
    color: black !important;
    border-radius: 0 !important;
  `}
`

const CalendarSidebarSection = styled.div`
  &:not(:last-child) {
    margin-bottom: ${Style.spacing.x2_5};
  }
`

const CalendarSidebarSectionTitle = styled.div`
  color: #333333;
  border-bottom: 1px solid #E3E3E3;
  font-weight: 500;
  margin: 15px 9px 5px;
  padding-bottom: 5px;
  border-bottom-style: solid;
  border-bottom-width: 2px;
`

const CalendarSidebarActionLinks = styled.div`
  display: flex;
  flex-direction: column;
`

const CalendarSidebarActionLink = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  align-items: center;
  width: 100%;
  padding: 3px 5px;
  border-radius: 6px;
  white-space: nowrap;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  cursor: pointer;

  &:hover {
    background: #E3E3E3;
  }

  svg {
    width: 16px;
    height: 16px;
  }

  i {
    font-size: 16px;
  }

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    min-width: 24px;
  }
`

interface IStateToProps {
  currentUser: CurrentUser
}

interface IDispatchToProps {
  showCalendarEventModal: typeof showCalendarEventModal
  showConfirmModal: typeof showConfirmModal
}

type IProps = IStateToProps & IDispatchToProps & WithTranslation & RouteComponentProps<any>

interface IState {
  events: CalendarViewEvent[]
  start: Moment
  end: Moment
  calendarView: View
  didInitialLoad: boolean
  sidebarMobileActive: boolean
  sidebarFilterActive: boolean
  sidebarIntegrationsActive: boolean
  filter: ICalendarFilter
}

class Calendar extends React.Component<IProps, IState> {
  private datetime = React.createRef<Datetime>()
  private printContainer = React.createRef<HTMLDivElement>()

  constructor(props: IProps) {
    super(props)

    this.state = {
      events: [],
      start: moment().startOf('week'),
      end: moment().endOf('week'),
      didInitialLoad: false,
      calendarView: 'week',
      sidebarMobileActive: false,
      sidebarFilterActive: false,
      sidebarIntegrationsActive: false,
      filter: this.getLocalStorageFilter()
    }

    this.onNewEventClick = this.onNewEventClick.bind(this)
    this.onCalendarEventSubmit = this.onCalendarEventSubmit.bind(this)
    this.onCalendarEventDelete = this.onCalendarEventDelete.bind(this)
    this.onCalendarViewTypeChange = this.onCalendarViewTypeChange.bind(this)
    this.onCalendarViewChange = this.onCalendarViewChange.bind(this)
    this.onCalendarNavigate = this.onCalendarNavigate.bind(this)
    this.onToggleMobileMenu = this.onToggleMobileMenu.bind(this)
    this.onTodayClick = this.onTodayClick.bind(this)
    this.onCalendarRangeChange = this.onCalendarRangeChange.bind(this)
    this.onCalendarFilterClick = this.onCalendarFilterClick.bind(this)
    this.onCalendarFilterChange = this.onCalendarFilterChange.bind(this)
    this.onCalendarFilterCloseClick = this.onCalendarFilterCloseClick.bind(this)
    this.onCalendarIntegrationClick = this.onCalendarIntegrationClick.bind(this)
    this.onViewAllCalendarSettingsClick = this.onViewAllCalendarSettingsClick.bind(this)
    this.onCalendarIntegrationSidebarCloseClick = this.onCalendarIntegrationSidebarCloseClick.bind(this)
    this.renderDatetimeDay = this.renderDatetimeDay.bind(this)
  }

  getLocalStorageFilter() {
    const defaultFilter: ICalendarFilter = { searchValue: '', eventTypes: [] }
    try {
      const filter: ICalendarFilter = JSON.parse(
        LocalStorage.get(LocalStorageKey.CALENDAR_FILTER, JSON.stringify(defaultFilter))
      )
      return filter
    } catch (ex) {
      console.error(ex)
      return defaultFilter
    }
  }

  componentDidMount() {
    this.fetchEvents()
    this.parseQueryParams()
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
    if (prevProps.location.search !== this.props.location.search) {
      this.parseQueryParams()
    }
  }

  parseQueryParams() {
    const queryParams = UrlHelper.getParams(this.props.location.search)
    const eventId = queryParams.eventId

    if (eventId) {
      requestAnimationFrame(() => {
        this.props.showCalendarEventModal({
          calendarEvent: { id: eventId },
          onSubmit: this.onCalendarEventSubmit,
          onDelete: this.onCalendarEventDelete
        })
      })
    }
  }

  async fetchEvents() {
    const { start, end } = this.state

    try {
      const { events } = await CalendarController.getEvents({
        start: moment(start).toISOString(),
        end: moment(end).toISOString()
      })

      this.setState({
        events: events.map(calendarEvent => ({
          ...calendarEvent,
          start: new Date(calendarEvent.start),
          end: new Date(calendarEvent.end),
        }))
      })
    } catch (ex) {
      console.error(ex)
    }
  }

  getEvents() {
    const { events, filter } = this.state
    let calendarEvents = events

    if (!filter) return calendarEvents

    const fuse = new Fuse(calendarEvents, {
      shouldSort: true,
      threshold: 0.5,
      location: 0,
      distance: 100,
      minMatchCharLength: 1,
      keys: [
        'title',
        'resource.attendees',
        'resource.description'
      ]
    })

    const { searchValue, eventTypes } = filter;

    // Search tasks based on searchValue
    if (searchValue.length > 0) {
      calendarEvents = fuse.search(searchValue).map(result => result.item)
    }

    if (eventTypes.length > 0) {
      calendarEvents = calendarEvents.filter(calendarEvent => eventTypes.includes(calendarEvent.type))
    }

    return calendarEvents
  }

  getActiveFilterCount() {
    const { filter } = this.state

    let activeFilterCount = 0

    if (filter.searchValue !== '') activeFilterCount += 1
    if (filter.eventTypes.length > 0) activeFilterCount += 1

    return activeFilterCount
  }

  onNewEventClick() {
    this.props.showCalendarEventModal({
      onSubmit: this.onCalendarEventSubmit,
      onDelete: this.onCalendarEventDelete,
    })
  }

  onCalendarEventSubmit(calendarEvent: CalendarEvent) {
    const { events } = this.state

    const calendarEventIndex = events.findIndex(event => event.type === CalendarViewEventType.CALENDAR_EVENT && event.resource.id === calendarEvent.id)

    if (calendarEventIndex !== -1) {
      const currentCalendarEvent = events[calendarEventIndex]

      events[calendarEventIndex] = {
        ...currentCalendarEvent,
        title: calendarEvent.title,
        start: moment(calendarEvent.start).toDate(),
        end: moment(calendarEvent.end).toDate(),
        all_day: calendarEvent.all_day,
        type: CalendarViewEventType.CALENDAR_EVENT,
        resource: calendarEvent,
      }

      this.setState({ events: [...events] }, this.fetchEvents)
    } else {
      this.setState({
        events: [
          ...events, {
            title: calendarEvent.title,
            start: moment(calendarEvent.start).toDate(),
            end: moment(calendarEvent.end).toDate(),
            all_day: calendarEvent.all_day,
            type: CalendarViewEventType.CALENDAR_EVENT,
            resource: calendarEvent,
          }]
      }, this.fetchEvents)
    }
  }

  onCalendarEventDelete(calendarEventId: string) {
    const { events } = this.state

    const filteredEvents = events.filter(event => event.resource.id !== calendarEventId)

    this.setState({ events: filteredEvents }, this.fetchEvents)
  }

  onCalendarViewTypeChange(view: View) {
    const { start } = this.state
    this.onCalendarNavigate(start.toDate(), view)
  }

  onCalendarViewChange(view: View) {
    this.setState({ calendarView: view })
  }

  onCalendarNavigate(date: Date, view: View) {
    switch (view) {
      case 'day':
        this.setState({
          start: moment(date).startOf('day'),
          end: moment(date).endOf('day'),
          calendarView: view,
        }, this.fetchEvents)
        break
      case 'week':
        this.setState({
          start: moment(date).startOf('week'),
          end: moment(date).endOf('week'),
          calendarView: view,
        }, this.fetchEvents)
        break
      case 'month':
      case 'agenda':
        this.setState({
          start: moment(date).startOf('month'),
          end: moment(date).endOf('month'),
          calendarView: view,
        }, this.fetchEvents)
        break
    }
  }

  onToggleMobileMenu() {
    this.setState({ sidebarMobileActive: !this.state.sidebarMobileActive })
  }

  onTodayClick() {
    const { calendarView } = this.state
    const today = moment()

    this.onCalendarNavigate(today.toDate(), calendarView)
  }

  onCalendarRangeChange(range: Date[] | { start: stringOrDate; end: stringOrDate }, view: View | undefined) {
    switch (view) {
      case 'day':
        const dateMoment = moment(range[0])
        this.setState({ start: dateMoment.startOf('day'), end: moment(dateMoment).endOf('day') }, this.fetchEvents)
        break
      case 'week':
        if (Array.isArray(range)) {
          this.setState({
            start: moment(range[0]),
            end: moment(range[range.length - 1])
          }, this.fetchEvents)
        }
        break
      case 'month':
      case 'agenda':
        this.setState({
          // @ts-ignore
          start: moment(range.start),
          // @ts-ignore
          end: moment(range.end)
        }, this.fetchEvents)
        break
    }
  }

  onCalendarFilterClick() {
    const { sidebarFilterActive } = this.state
    this.setState({ sidebarFilterActive: !sidebarFilterActive })
  }

  onCalendarFilterChange(filter: ICalendarFilter) {
    // Save to localstorage
    LocalStorage.set(LocalStorageKey.CALENDAR_FILTER, JSON.stringify(filter))

    this.setState({ filter: filter })
  }

  onCalendarFilterCloseClick() {
    this.setState({ sidebarFilterActive: false })
  }

  onCalendarIntegrationClick() {
    const { sidebarFilterActive } = this.state
    this.setState({ sidebarIntegrationsActive: !sidebarFilterActive })
  }

  onViewAllCalendarSettingsClick() {
    this.props.history.push(RouteHelper.process(ERoute.PATH_SETTINGS_DISPLAY_PREFERENCES))
  }

  onCalendarIntegrationSidebarCloseClick() {
    this.setState({ sidebarIntegrationsActive: false })
  }

  renderDatetimeDay(props: any, currentDate: Moment, selectedDate: Moment) {
    const { calendarView, start, end } = this.state
    const active = currentDate.isBetween(start, end, 'day', '[]')
    return <DatetimeDay
      {...props}
      active={active}>{currentDate.format('D')}
    </DatetimeDay>
  }

  render() {
    const { t } = this.props
    const { start, calendarView, sidebarMobileActive, sidebarFilterActive, sidebarIntegrationsActive, filter } = this.state

    const events = this.getEvents()

    return (
      <>
        <Helmet>
          <title>{t('Calendar::{{__appName}} | Calendar')}</title>
        </Helmet>

        <ScrollToTopOnMount />

        <TopNavigation
          icon='calendar-day'
          title={t('Calendar::Calendar')}
        />


        <PageContent>
          <CalendarMobileHeader>
            <OpenMenuButton onClick={this.onToggleMobileMenu} viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg" height="16" width="20"><rect x="3" width="9" height="2" rx="1" fill="currentColor"></rect><rect x="3" y="14" width="9" height="2" rx="1" fill="currentColor"></rect><rect y="7" width="12" height="2" rx="1" fill="currentColor"></rect><path d="M15 4.207a.5.5 0 0 1 .854-.353l3.792 3.792a.5.5 0 0 1 0 .708l-3.792 3.792a.5.5 0 0 1-.854-.353V4.207Z" fill="currentColor"></path></OpenMenuButton>
            <div>
              <CalendarTodayButton onClick={this.onTodayClick}>
                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" height="16" width="16" aria-hidden="true"><path d="M14.75 4.75 19.25 9l-4.5 4.25" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M19.25 9H8.75a4 4 0 0 0-4 4v6.25" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                {t('Calendar::Jump to today')}
              </CalendarTodayButton>
            </div>
          </CalendarMobileHeader>
          <PageWrapper>
            <CalendarSidebar active={sidebarMobileActive}>
              <CalendarSidebarCloseContainer>
                <SidebarClose onClick={this.onToggleMobileMenu} />
              </CalendarSidebarCloseContainer>
              <CalendarSidebarSection>
                <CalendarTypeItems>
                  <CalendarTypeItem active={calendarView === 'day'} onClick={() => this.onCalendarViewTypeChange('day')}>{t('Calendar::Day')}</CalendarTypeItem>
                  <CalendarTypeItem active={calendarView === 'week'} onClick={() => this.onCalendarViewTypeChange('week')}>{t('Calendar::Week')}</CalendarTypeItem>
                  <CalendarTypeItem active={calendarView === 'month'} onClick={() => this.onCalendarViewTypeChange('month')}>{t('Calendar::Month')}</CalendarTypeItem>
                  <CalendarTypeItem active={calendarView === 'agenda'} onClick={() => this.onCalendarViewTypeChange('agenda')}>{t('Calendar::Agenda')}</CalendarTypeItem>
                </CalendarTypeItems>

                <CalendarDatePicker>
                  <Datetime
                    ref={this.datetime}
                    input={false}
                    timeFormat={false}
                    value={start}
                    onChange={(date) => this.onCalendarNavigate(moment(date).toDate(), calendarView)}
                    renderDay={this.renderDatetimeDay}
                  />
                </CalendarDatePicker>

                <CalendarTodayButton onClick={this.onTodayClick}>
                  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" height="16" width="16" aria-hidden="true"><path d="M14.75 4.75 19.25 9l-4.5 4.25" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M19.25 9H8.75a4 4 0 0 0-4 4v6.25" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                  {t('Calendar::Jump to today')}
                </CalendarTodayButton>
              </CalendarSidebarSection>

              <CalendarSidebarSection>
                <CalendarSidebarSectionTitle>
                  {t('Calendar::More options')}
                </CalendarSidebarSectionTitle>
                <CalendarSidebarActionLinks>
                  <CalendarSidebarActionLink onClick={this.onCalendarFilterClick}>
                    <span>
                      <Icon icon='filter' />
                    </span>
                    {this.getActiveFilterCount() > 0 ? t('Calendar::{{count}} filters active', { count: this.getActiveFilterCount() }) : t('Calendar::Filter')}
                  </CalendarSidebarActionLink>
                  <ReactToPrint
                    trigger={() => {
                      return (
                        <CalendarSidebarActionLink>
                          <span>
                            <Icon icon='print' />
                          </span>
                          {t('Calendar::Print calendar')}
                        </CalendarSidebarActionLink>
                      )
                    }}
                    content={() => this.printContainer.current}
                  />

                  <CalendarSidebarActionLink onClick={this.onCalendarIntegrationClick}>
                    <span>
                      <Icon icon='bolt' />
                    </span>
                    {t('Calendar::Configure integration')}
                  </CalendarSidebarActionLink>
                  <CalendarSidebarActionLink onClick={this.onViewAllCalendarSettingsClick}>
                    <span>
                      <Icon icon='setting' />
                    </span>
                    {t('Calendar::View all calendar settings')}
                  </CalendarSidebarActionLink>
                </CalendarSidebarActionLinks>
              </CalendarSidebarSection>
            </CalendarSidebar>

            <CalendarComponent
              view={calendarView}
              printContainerRef={this.printContainer}
              events={events}
              date={start}
              onView={this.onCalendarViewChange}
              onNavigate={this.onCalendarNavigate}
              onRangeChange={this.onCalendarRangeChange}
              onCalendarEventSubmit={this.onCalendarEventSubmit}
              onCalendarEventDelete={this.onCalendarEventDelete}
            />

            <CalendarSidebarFilter
              active={sidebarFilterActive}
              filter={filter}
              onFilterChange={this.onCalendarFilterChange}
              onCloseClick={this.onCalendarFilterCloseClick}
            />
            <CalendarSidebarIntegrations
              active={sidebarIntegrationsActive}
              onCloseClick={this.onCalendarIntegrationSidebarCloseClick}
            />
          </PageWrapper>
        </PageContent>
      </>
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    }
  } = state

  return {
    currentUser: currentUser,
  }
}

const mapDispatchToProps: IDispatchToProps = {
  showCalendarEventModal,
  showConfirmModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Calendar))