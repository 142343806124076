import * as React from 'react'
import styled, { css } from 'styled-components'
import { Style } from '../../styles'


const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  border: 1px solid ${Style.color.border};
  padding: ${Style.spacing.x2_5};
  border-radius: ${Style.variables.baseBorderRadius};
`

const Container = styled.div<{ active?: boolean }>`
  width: 100%;
  padding: ${Style.spacing.x1_5} ${Style.spacing.x1};
  cursor: pointer;

  ${props => props.active && css`
    ${Wrapper} {
      background: #4389fc21;
      border-color: ${Style.color.brandPrimary};
      box-shadow: 0 0 0 1px ${Style.color.brandPrimary};
    }
  `}

  &:hover {
    ${Wrapper} {
      box-shadow: 0 0 0 1px ${Style.color.brandPrimary}, 0 0 12px 0 rgba(0,163,189,.3);
    }
  }
`

const InnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 56px;
  padding: 12px;

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    flex-direction: column;
  }
`

const ContentTemplateItemIcon = styled.img`
  min-width: 100px;
  max-height: 120px;
  margin-right: ${Style.spacing.x3};

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    margin-bottom: ${Style.spacing.x3};
  }
`

const ContentTemplateItemContent = styled.div`
  display: flex;
  flex-direction: column;
`

const ContentTemplateItemTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: ${Style.spacing.x1};
`
const ContentTemplateItemDescription = styled.div`
  font-size: 16px;
`

interface IProps {
  icon: string
  title: string
  description: string
  active?: boolean
  onClick?: () => void
}

const ContentTemplateItem = (props: IProps) => {
  const { icon, title, description, active, onClick } = props
  return (
    <Container active={active} onClick={onClick}>
      <Wrapper>
        <InnerWrapper>
          <ContentTemplateItemIcon src={icon} />
          <ContentTemplateItemContent>
            <ContentTemplateItemTitle>
              {title}
            </ContentTemplateItemTitle>
            <ContentTemplateItemDescription>
              {description}
            </ContentTemplateItemDescription>
          </ContentTemplateItemContent>
        </InnerWrapper>
      </Wrapper>
    </Container>
  )
}

export default ContentTemplateItem