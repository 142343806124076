import * as React from 'react';
import copy from 'copy-to-clipboard';
import { Helmet } from 'react-helmet';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';
import ActionList, { IActionListItem } from '../../components/ActionList/ActionList';
import BlockEditor from '../../components/BlockEditor/BlockEditor';
import BlockEditorSidebar from '../../components/BlockEditor/BlockEditorSidebar';
import BlockEditorSidebarSection from '../../components/BlockEditor/BlockEditorSidebarSection';
import Button from '../../components/Button/Button';
import ScrollToTopOnMount from '../../components/Effects/ScrollToTopOnMount';
import PowerSelect from '../../components/Form/PowerSelect';
import ReactSelectTheme from '../../components/Form/ReactSelectTheme';
import ResourceCreatablePowerSelect from '../../components/Form/ResourceCreatablePowerSelect';
import TopNavigation from '../../components/Navigation/TopNavigation';
import FullPageContent from '../../components/Page/FullPageContent';
import PageContent from '../../components/Page/PageContent';
import PageLoader from '../../components/Page/PageLoader';
import Popover from '../../components/Popover/Popover';
import ActionCableConsumer from '../../consumers/ActionCableConsumer';
import ProposalsController from '../../controllers/ProposalsController';
import ERoute from '../../ERoute';
import { AppState } from '../../store';
import {
	createContentblock,
	deleteContentBlock,
	setInitialContentBlockEditorState,
	updateContentBlock
} from '../../store/content-blocks/actions';
import { showShareLinksModal } from '../../store/modals/actions'
import { Style } from '../../styles';
import Notification from '../../utilities/Notification';
import Utils from '../../utilities/Utils';
import { showConfirmModal, showSendProposalModal } from '../../store/modals/actions';
import RouteHelper from '../../helpers/RouteHelper';
import { ShareableLink } from '../../components/Modals/ShareLinksModal';
import ProposalHelper from '../../helpers/ProposalHelper';
import DateInput from '../../components/Form/DateInput';
import moment from '../../utilities/Moment';
import ReactTooltip from 'react-tooltip';
import { Contact, ContentBlock, ContentBlockResource, ContentBlockType, ContentBlockVariables, CurrencyOption, CurrentUser, Deal, DisplayableError, LedgerItemType, Proposal, ProposalChannelEvent, ProposalChannelEventType, ProposalStatus, WorkspaceTax } from '../../types';
import Alert from '../../components/Alert/Alert';
import Icon from '../../components/Icons/Icon';

const Container = styled.div`
	display: flex;
	flex-direction: row;
	flex: 1;
	height: 100%;

	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
		flex-direction: column-reverse;
		height: auto;
	}
`

const BlockEditorSidebarAction = styled.div`
	position: relative;
	width: 100%;

	> * {
		width: 100%;
	}

	.button {
		width: 100%;
	}
`

interface IStateToProps {
	currentUser: CurrentUser
	contentBlocks: ContentBlock[]
}

interface IDispatchToProps {
	setInitialContentBlockEditorState: typeof setInitialContentBlockEditorState
	createContentblock: typeof createContentblock
	updateContentBlock: typeof updateContentBlock
	deleteContentBlock: typeof deleteContentBlock
	showConfirmModal: typeof showConfirmModal
	showSendProposalModal: typeof showSendProposalModal
	showShareLinksModal: typeof showShareLinksModal
}

type IProps = IStateToProps & IDispatchToProps & RouteComponentProps<{ id?: string }> & WithTranslation

interface IState {
	didInitialLoad: boolean
	proposal: Proposal | null
	currencies: CurrencyOption[]
	taxes: WorkspaceTax[]
	actionPopoverActive: boolean
	isSubmitting: boolean
	errors: DisplayableError[]
	previewModeEnabled: boolean
	variables: ContentBlockVariables
}

class ProposalView extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props)

		this.state = {
			didInitialLoad: false,
			proposal: null,
			currencies: [],
			taxes: [],
			actionPopoverActive: false,
			isSubmitting: false,
			errors: [],
			previewModeEnabled: false,
			variables: {}
		}

		this.onNameChange = this.onNameChange.bind(this)
		this.onStatusChange = this.onStatusChange.bind(this)
		this.onExpiresOnChange = this.onExpiresOnChange.bind(this)
		this.onContactChange = this.onContactChange.bind(this)
		this.onProjectChange = this.onProjectChange.bind(this)
		this.onCurrencyChange = this.onCurrencyChange.bind(this)
		this.onSigneesChange = this.onSigneesChange.bind(this)
		this.onDealChange = this.onDealChange.bind(this)
		this.onFormSubmit = this.onFormSubmit.bind(this)
		this.debouncedFormSubmit = Utils.debounce(this.onFormSubmit, 500, false)
		this.onTogglePreviewClick = this.onTogglePreviewClick.bind(this)
		this.onActionsClick = this.onActionsClick.bind(this)
		this.onActionPopoverClose = this.onActionPopoverClose.bind(this)
		this.onActionPopoverClick = this.onActionPopoverClick.bind(this)
		this.onCreateInvoiceClick = this.onCreateInvoiceClick.bind(this)
		this.onSendProposalClick = this.onSendProposalClick.bind(this)
		this.onCopyLinksClick = this.onCopyLinksClick.bind(this)
		this.onDownloadClick = this.onDownloadClick.bind(this)
		this.onDuplicateClick = this.onDuplicateClick.bind(this)
		this.onDeleteClick = this.onDeleteClick.bind(this)


		// Action cable
		this.onActionCableConnected = this.onActionCableConnected.bind(this)
		this.onActionCableDisconnected = this.onActionCableDisconnected.bind(this)
		this.onActionCableReceived = this.onActionCableReceived.bind(this)


		this.onCreateBlock = this.onCreateBlock.bind(this)
		this.onUpdateBlock = this.onUpdateBlock.bind(this)
		this.onDeleteBlock = this.onDeleteBlock.bind(this)
	}

	componentDidMount() {
		this.fetchForm().catch(console.error)
	}

	componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
		if (prevProps.match.params.id !== this.props.match.params.id) {
			this.fetchForm().catch(console.error)
		}

		ReactTooltip.rebuild();
	}

	onActionCableConnected() {
		console.log('[ProposalChannel] connected')
	}

	onActionCableDisconnected() {
		console.log('[ProposalChannel] Disconnected')
	}

	onActionCableReceived(event: ProposalChannelEvent) {
		const { t } = this.props

		console.log('[ProposalChannel] received event', event)

		switch (event.type) {
			case ProposalChannelEventType.PROPOSAL_CREATE:
				// Not really fired since you can't subscribe on a proposal that hasn't been created yet
				break
			case ProposalChannelEventType.PROPOSAL_UPDATE:
				this.setState({ proposal: event.data.proposal })
				break
			case ProposalChannelEventType.PROPOSAL_DELETE:
				Notification.notifySuccess(t('Proposal::Proposal has been deleted'))
				this.props.history.replace(ERoute.PATH_PROPOSALS)
				break
			case ProposalChannelEventType.CONTENT_BLOCK_CREATE:
				this.props.createContentblock(event.data.content_block)
				break
			case ProposalChannelEventType.CONTENT_BLOCK_UPDATE:
				this.props.updateContentBlock(event.data.content_block)
				break
			case ProposalChannelEventType.CONTENT_BLOCK_DELETE:
				this.props.deleteContentBlock(event.data.content_block_id)
				break
			default:
				throw Error('Invalid event type')
		}
	}

	onCreateBlock(contentBlock: ContentBlock) {
		this.props.createContentblock(contentBlock)
	}

	onUpdateBlock(contentBlock: ContentBlock) {
		this.props.updateContentBlock(contentBlock)
	}

	onDeleteBlock(contentBlock: ContentBlock) {
		this.props.deleteContentBlock(contentBlock.id)
	}

	async fetchForm() {
		try {
			const {
				proposal,
				currencies,
				variables,
				taxes
			} = await ProposalsController.getForm({ id: this.props.match.params.id })

			this.props.setInitialContentBlockEditorState({ contentBlocks: proposal.content_blocks })

			this.setState({
				didInitialLoad: true,
				proposal: proposal,
				currencies: currencies,
				variables: variables,
				taxes: taxes,
			})
		} catch (ex) {
			console.error(ex)
		}
	}

	onNameChange(e) {
		const name = e.currentTarget.value

		this.setState({
			proposal: {
				...this.state.proposal,
				name: name,
			}
		}, this.debouncedFormSubmit)
	}

	onStatusChange(option) {
		const { t } = this.props

		if (option.value === ProposalStatus.DRAFT) {
			requestAnimationFrame(() => {
				this.props.showConfirmModal({
					title: t('Proposal::Proposal status change'),
					description: t('Proposal::Changing the status of a proposal back to draft will remove all the current signatures and reset signatures for signees. Are you sure you want to change the status?'),
					action: { label: t('Proposal::Confirm') },
					onConfirm: () => {
						this.setState({
							proposal: {
								...this.state.proposal,
								status: option.value,
							}
						}, this.debouncedFormSubmit)
					}
				})
			})
		} else {
			this.setState({
				proposal: {
					...this.state.proposal,
					status: option.value,
				}
			}, this.debouncedFormSubmit)
		}
	}

	onExpiresOnChange(value) {
		const expiresOn = moment(value);

		this.setState({
			proposal: {
				...this.state.proposal,
				expires_on: expiresOn.isValid() ? expiresOn.format('YYYY-MM-DD') : null
			}
		}, this.debouncedFormSubmit)
	}

	onContactChange(contactId?: string, contact: Contact | null = null) {
		const { proposal } = this.state

		this.setState({
			proposal: {
				...this.state.proposal,
				contact_id: contactId,
				project_id: null,
				deal_id: null,
				currency: contact ? contact.currency : proposal.currency
			}
		}, this.debouncedFormSubmit)
	}

	onCurrencyChange(option) {
		this.setState({
			proposal: {
				...this.state.proposal,
				currency: option.value,
			}
		}, this.debouncedFormSubmit)
	}

	onProjectChange(projectId?: string) {
		this.setState({
			proposal: {
				...this.state.proposal,
				project_id: projectId,
				deal_id: null,
			}
		}, this.debouncedFormSubmit)
	}

	onSigneesChange(value: string[], signees: Contact[]) {
		this.setState({
			proposal: {
				...this.state.proposal,
				signee_ids: value ? value : [],
				signees: signees ? signees : []
			}
		}, this.debouncedFormSubmit)
	}

	onDealChange(dealId: string, deal: Deal) {
		this.setState({
			proposal: {
				...this.state.proposal,
				deal_id: dealId || null
			}
		}, this.debouncedFormSubmit)
	}

	debouncedFormSubmit() {
		this.onFormSubmit().catch(console.error)
	}

	async onFormSubmit(e?: any) {
		const { isSubmitting } = this.state
		if (e) e.preventDefault()

		if (!isSubmitting) {
			try {
				this.setState({ isSubmitting: true })

				const response = await ProposalsController.update(this.state.proposal)

				if (response.errors) {
					this.setState({ errors: response.errors })
				} else {
					this.setState({ errors: [] })
				}

				await this.fetchForm()
			} catch (ex) {
				console.error(ex)
			} finally {
				this.setState({ isSubmitting: false })
			}
		}
	}

	onTogglePreviewClick() {
		this.setState({ previewModeEnabled: !this.state.previewModeEnabled })
	}

	onActionsClick() {
		this.setState({ actionPopoverActive: true })
	}

	onActionPopoverClose() {
		this.setState({ actionPopoverActive: false })
	}

	async onActionPopoverClick(key: string) {
		const { proposal } = this.state
		this.setState({ actionPopoverActive: false })

		switch (key) {
			case 'create-invoice':
				this.onCreateInvoiceClick()
				break
			case 'send-proposal':
				this.onSendProposalClick()
				break
			case 'copy-link':
				this.onCopyLinksClick()
				break
			case 'download':
				this.onDownloadClick()
				break
			case 'duplicate':
				this.onDuplicateClick()
				break
			case 'delete':
				this.onDeleteClick()
				break
		}
	}

	onCreateInvoiceClick() {
		const { proposal } = this.state

		this.props.history.push(RouteHelper.process(ERoute.PATH_INVOICES_NEW, {
			type: LedgerItemType.INVOICE,
			proposal_id: proposal.id
		}))
	}

	onSendProposalClick() {
		const { proposal } = this.state

		requestAnimationFrame(() => {
			this.props.showSendProposalModal({
				id: proposal.id,
			})
		})
	}

	async onCopyLinksClick() {
		const { t } = this.props
		const { proposal } = this.state

		try {
			const response = await ProposalsController.getSigneeLinks(proposal.id)

			const shareableLinks: ShareableLink[] = response.signee_links.map(signeeLink => {
				return {
					content: signeeLink.signee.name,
					url: signeeLink.url
				}
			})

			if (shareableLinks.length > 1) {
				requestAnimationFrame(() => {
					this.props.showShareLinksModal({
						title: t('Proposal::Share proposal'),
						shareableLinks: shareableLinks
					})
				})
			} else if (shareableLinks.length === 1) {
				const shareableLink = shareableLinks[0]
				copy(shareableLink.url)
				Notification.notifySuccess(t('ShareLinksModal::Copied to clipboard'))
			}
		} catch (ex) {
			console.error(ex)
		}
	}

	onDownloadClick() {
		const { proposal } = this.state
		ProposalHelper.download(proposal.id)
	}

	async onDuplicateClick() {
		const { proposal } = this.state

		try {
			const response = await ProposalsController.duplicate(proposal.id)
			const duplicatedProposal = response

			this.props.history.replace(RouteHelper.process(ERoute.PATH_PROPOSAL, { id: duplicatedProposal.id }))
		} catch (ex) {
			console.error(ex)
		}
	}

	async onDeleteClick() {
		const { t, showConfirmModal } = this.props
		const { proposal } = this.state

		requestAnimationFrame(() => {
			showConfirmModal({
				title: t('Proposal::Delete proposal'),
				description: t('Proposal::You are about to delete this proposal. Deleting this proposal also deletes all its associated data. Are you sure?'),
				action: { label: t('Proposal::Delete'), isDestructive: true },
				onConfirm: async () => {
					try {
						await ProposalsController.delete(proposal.id)
						this.props.history.replace(ERoute.PATH_PROPOSALS)
					} catch (ex) {
						console.error(ex)
					}
				}
			})
		})
	}

	render() {
		const { t, contentBlocks, currentUser: { workspace: { setting } } } = this.props
		const {
			didInitialLoad,
			proposal,
			variables,
			currencies,
			taxes,
			previewModeEnabled,
			actionPopoverActive
		} = this.state

		const invoiceable = proposal?.status === ProposalStatus.ACCEPTED
		const editable = proposal ? proposal.status === ProposalStatus.DRAFT : false
		const sendable = proposal ? ProposalHelper.sendable(proposal) : false

		const actions: IActionListItem[] = [
			{ key: 'create-invoice', icon: 'invoice', content: t('Proposal::Create invoice'), visible: invoiceable },
			{ key: 'send-proposal', icon: 'send', content: t('Proposal::Send proposal'), visible: sendable },
			{ key: 'copy-link', icon: 'link', content: t('Proposal::Copy link'), visible: sendable },
			{ key: 'download', icon: 'download-circle', content: t('Proposal::Download') },
			{ key: 'duplicate', icon: 'duplicate', content: t('Proposal::Duplicate') },
			{ key: 'save-as-template', content: t('Proposal::Save as template'), visible: false },
			{ key: 'delete', icon: 'trash-alt-solid', content: t('Proposal::Delete'), destructive: true },
		]

		const statusOptions = [
			{ label: t(`ProposalStatus::${ProposalStatus.DRAFT}`), value: ProposalStatus.DRAFT },
			{ label: t(`ProposalStatus::${ProposalStatus.PENDING}`), value: ProposalStatus.PENDING },
			{ label: t(`ProposalStatus::${ProposalStatus.ACCEPTED}`), value: ProposalStatus.ACCEPTED },
			{ label: t(`ProposalStatus::${ProposalStatus.DECLINED}`), value: ProposalStatus.DECLINED },
			{ label: t(`ProposalStatus::${ProposalStatus.CANCELLED}`), value: ProposalStatus.CANCELLED }
		]

		const currencyOptions = currencies.map(c => ({ label: c.label, value: c.value }))

		let selectedStatusOption = null
		let selectedCurrencyOption = null

		if (proposal) {
			selectedStatusOption = statusOptions.find(statusOption => statusOption.value === proposal.status)
			selectedCurrencyOption = currencies.find(option => option.value === proposal.currency)
		}


		return (
			<>
				<Helmet>
					<title>{t('Proposal::{{__appName}} | Proposal')}</title>
				</Helmet>

				<ScrollToTopOnMount />

				<TopNavigation
					icon='proposal'
					title={t('Proposal::Proposal')}
				/>

				<FullPageContent>
					<Container>
						{!didInitialLoad && <PageContent><PageLoader /></PageContent>}

						{didInitialLoad && <>
							<ActionCableConsumer
								channel={{ channel: 'ProposalChannel', id: proposal.id }}
								onConnected={this.onActionCableConnected}
								onDisconnected={this.onActionCableDisconnected}
								onReceived={this.onActionCableReceived}
							>
								<BlockEditor
									resource={ContentBlockResource.PROPOSAL}
									resourceId={proposal.id}
									blockTypes={[
										ContentBlockType.TEXT,
										ContentBlockType.IMAGE,
										ContentBlockType.PDF,
										ContentBlockType.ITEMS,
										ContentBlockType.SUMMARY,
										ContentBlockType.INTRO,
									]}
									variables={variables}
									currency={proposal.currency}
									taxes={taxes}
									numberFormat={setting.number_format}
									blocks={contentBlocks}
									signatures={proposal.signatures}
									previewModeEnabled={previewModeEnabled}
									editable={editable}
									onTogglePreviewClick={this.onTogglePreviewClick}
									onCreateBlock={this.onCreateBlock}
									onUpdateBlock={this.onUpdateBlock}
									onDeleteBlock={this.onDeleteBlock}
								/>

								<BlockEditorSidebar>
									<form onSubmit={this.onFormSubmit}>
										<BlockEditorSidebarSection>
											<div className='form-item'>
												<label>{t('Proposal::Name')}</label>
												<input
													type='text'
													value={proposal.name}
													onChange={this.onNameChange}
													placeholder={t('Proposal::Name')}
												/>
											</div>
											<div className='form-item'>
												<label>{t('Proposal::Status')}</label>
												<PowerSelect
													options={statusOptions}
													value={selectedStatusOption}
													onChange={this.onStatusChange}
													isClearable={false}
													theme={ReactSelectTheme}
												/>
											</div>
											<div className='form-item'>
												<label>{t('Proposal::Currency')}</label>
												<PowerSelect
													options={currencyOptions}
													value={selectedCurrencyOption}
													onChange={this.onCurrencyChange}
													noOptionsMessage={(value) => String(t('ContactModal::No currency option found'))}
													theme={ReactSelectTheme}
												/>
											</div>
											<div className='form-item'>
												<label>{t('ProjectModal::Expires on')}</label>
												<DateInput
													name='expires_on'
													dateFormat={setting.date_format}
													timeFormat={false}
													value={moment(proposal.expires_on)}
													inputProps={{ placeholder: setting.date_format }}
													onChange={this.onExpiresOnChange}
													closeOnSelect
												/>
											</div>
											<div className='form-item'>
												<label>{t('Proposal::Contact')}</label>
												<ResourceCreatablePowerSelect
													type='contact'
													params={{ archived: false }}
													value={proposal.contact_id}
													onChange={this.onContactChange}
													isClearable={true}
													isDisabled={!editable}
												/>
											</div>

											<div className='form-item'>
												<label>{t('Proposal::Project')}</label>
												<ResourceCreatablePowerSelect
													type='project'
													value={proposal.project_id}
													onChange={this.onProjectChange}
													isDisabled={!proposal.contact_id}
													isClearable={true}
													params={{ 'contact_id': proposal.contact_id }}
													createParams={{ 'contact_id': proposal.contact_id }}
												/>
											</div>

											<div className='form-item'>
												<label>{t('Proposal::Signees')}</label>
												<ResourceCreatablePowerSelect
													type='contact_with_email'
													value={proposal.signee_ids}
													onChange={this.onSigneesChange}
													params={{ archived: false }}
													isClearable={true}
													isMulti={true}
													isDisabled={!editable}
												/>
											</div>

											<div className='form-item'>
												<label>
													{t('Proposal::Deal')}
												</label>
												<ResourceCreatablePowerSelect
													type='deal'
													value={proposal.deal_id}
													onChange={this.onDealChange}
													isDisabled={!proposal.contact_id}
													isClearable={true}
													params={{ 'contact_id': proposal.contact_id, 'project_id': proposal.project_id }}
													createParams={{ 'contact_id': proposal.contact_id, 'project_id': proposal.project_id }}
												/>
											</div>
										</BlockEditorSidebarSection>
									</form>

									<BlockEditorSidebarSection>
										<BlockEditorSidebarAction>
											<Popover
												activator={
													<div>
														<Button
															text={t('Proposal::Actions')}
															type='default'
															onClick={this.onActionsClick}
														/>
													</div>
												}
												active={actionPopoverActive}
												placement='top'
												onClose={this.onActionPopoverClose}
											>
												<ActionList
													actions={actions}
													onClick={(key) => this.onActionPopoverClick(key)}
													hasIndicator
												/>
											</Popover>
										</BlockEditorSidebarAction>
									</BlockEditorSidebarSection>
								</BlockEditorSidebar>
							</ActionCableConsumer>
						</>}
					</Container>
				</FullPageContent>
			</>
		)
	}
}

const mapStateToProps = (state: AppState): IStateToProps => {
	const {
		authentication: {
			currentUser,
		},
		contentBlockEditor: {
			contentBlocks,
		}
	} = state

	return {
		currentUser: currentUser,
		contentBlocks: contentBlocks,
	}
}

const mapDispatchToProps: IDispatchToProps = {
	setInitialContentBlockEditorState,
	createContentblock,
	updateContentBlock,
	deleteContentBlock,
	showConfirmModal,
	showSendProposalModal,
	showShareLinksModal
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ProposalView))