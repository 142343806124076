import React from 'react';
import styled from 'styled-components';
import { Style } from '../../styles';
import { useTranslation } from 'react-i18next';
import { Activity, ActivityType } from '../../types';
import moment from '../../utilities/Moment';
import Icon from './ActivityIcon';
import ActivityActions from './ActivityActions';
import ActivityAction from './ActivityAction';

const Container = styled.div`
  position: relative;
  padding: ${Style.spacing.x2_5} 0;

  &:hover {
    ${ActivityActions} {
      visibility: visible;
    }
  }

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }

  &:not(:last-child) {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-color: ${Style.color.border};
  }
`

const DateHeader = styled.div`
  font-size: 15px;
  font-weight: 500;
  white-space: nowrap;
`

const Wrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 10px 0;
`



const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  margin-top: 5px;
  overflow: hidden;
`

const ContentHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #333333;

`

const Description = styled.div`
`
const Timestamp = styled.div`
`

const Preview = styled.div`
  font-size: 15px;
  line-height: 21px;
  letter-spacing: -0.24px;
  display: flex;
  margin: 2px 0;
  position: relative;
  text-indent: 0px;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid ${Style.color.border};
  padding: 15px 15px;
  background: #FCFCFC;
`

const PreviewContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
  flex-wrap: nowrap;
  gap: 12px;

  > div:first-child {
    width: 100%;
    flex: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`

export interface ActivityAction {
  label: string
  onClick: () => void
}

interface IProps {
  activity: Activity
  badge?: string
  timestamp?: string | React.ReactNode
  description: string | React.ReactNode
  preview?: string | React.ReactNode
  mainAction?: ActivityAction
  actions?: ActivityAction[]
}

const ActivityItem = (props: IProps) => {
  const { activity, badge, timestamp, description, preview, mainAction, actions } = props
  const { t } = useTranslation()

  const creationMoment = moment(activity.created_at)

  return (
    <Container>
      <DateHeader>{creationMoment.format('dddd, D MMMM')}</DateHeader>

      <Wrapper>
        <Icon
          activity={activity}
          badge={badge}
        />

        <Content>
          <ContentHeader>
            <Description>{description}</Description>
            <Timestamp>{timestamp || creationMoment.format('h:mm a')}</Timestamp>
          </ContentHeader>

          {preview && <Preview>
            <PreviewContent>
              <div>{preview}</div>
              {mainAction && <ActivityAction onClick={mainAction.onClick}>
                {mainAction.label}
              </ActivityAction>}
            </PreviewContent>
          </Preview>}

          {actions?.length > 0 && <ActivityActions>
            {actions.map((action, index) => (
              <ActivityAction
                key={index}
                onClick={action.onClick}
              >
                {action.label}
              </ActivityAction>
            ))}
          </ActivityActions>}
        </Content>
      </Wrapper>
    </Container >
  );
};

export default ActivityItem