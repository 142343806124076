import styled, { css } from "styled-components";
import { Style } from "../../styles";

const SidebarFlyoutContainer = styled.div<{ active: boolean }>`
  display: none;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 240px;
  bottom: 0;
  z-index: 8;
  padding: 20px;

  ${props => props.active && css`
    display: flex;
  `}

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    display: none;
  }

  .rbc-time-view {
    border-top: none;
  }

  .rbc-time-content {
    overflow: visible;
  }
`

export default SidebarFlyoutContainer;