import * as React from 'react'
import { Dispatch } from 'redux';
import { connect } from 'react-redux'
import { AppState } from '../store'
import CardEmptyInfo from '../components/Card/CardEmptyInfo'
import { showConfirmModal } from '../store/modals/actions'
import { TransactionsController } from '../controllers'
import ScrollToTopOnMount from '../components/Effects/ScrollToTopOnMount'
import PageLoader from '../components/Page/PageLoader'
import LocalStorage, { LocalStorageKey } from '../LocalStorage';
import { Helmet } from 'react-helmet';
import { withTranslation, WithTranslation } from 'react-i18next';
import ResourceTable from '../components/Resource/ResourceTable';
import ResourceTableRow from '../components/Resource/ResourceTableRow';
import ResourceTableRowData from '../components/Resource/ResourceTableRowData';
import { RouteComponentProps } from 'react-router';
import { Transaction, CurrentUser, ResourceListFilterType } from '../types';
import PageContent from '../components/Page/PageContent';
import NumberFormatter from '../utilities/NumberFormatter';
import { Style } from '../styles';
import TopNavigation from '../components/Navigation/TopNavigation';
import PageHeader from '../components/Page/PageHeader';
import moment from '../utilities/Moment';
import RouteHelper from '../helpers/RouteHelper';
import ERoute from '../ERoute';
import Icon from '../components/Icons/Icon';

interface IStateToProps {
  currentUser: CurrentUser
}

interface IDispatchToProps {
  showConfirmModal: typeof showConfirmModal
}

type IProps = IStateToProps & IDispatchToProps & WithTranslation & RouteComponentProps<any>

interface IState {
  transactions: Transaction[],
  currentPage: number,
  totalPages: number
  didInitialLoad: boolean
  isFetching: boolean
  sortValue: string
  filters: any
  selectedTransactionIds: string[]
  searchValue: string
}

class Transactions extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      transactions: [],
      selectedTransactionIds: [],
      currentPage: 0,
      totalPages: 0,
      didInitialLoad: false,
      isFetching: false,
      sortValue: LocalStorage.get(LocalStorageKey.TRANSACTION_SORT_VALUE, 'execution_time_desc'),
      filters: {},
      searchValue: ''
    }

    this.onTableTransactionClick = this.onTableTransactionClick.bind(this)
    this.onTableActionClick = this.onTableActionClick.bind(this)
    this.onTableSelectionChange = this.onTableSelectionChange.bind(this)
    this.onTableRowSelectionChange = this.onTableRowSelectionChange.bind(this)
    this.onTransactionFormSubmit = this.onTransactionFormSubmit.bind(this)
    this.onTransactionUpdateFormSubmit = this.onTransactionUpdateFormSubmit.bind(this)
    this.onTransactionFiltersChange = this.onTransactionFiltersChange.bind(this)
    this.onTransactionSortValueChange = this.onTransactionSortValueChange.bind(this)
    this.onTransactionSearchChange = this.onTransactionSearchChange.bind(this)
    this.onTransactionSearchSubmit = this.onTransactionSearchSubmit.bind(this)
    this.onTransactionClearFilters = this.onTransactionClearFilters.bind(this)
    this.onAddTransactionIntegrationClick = this.onAddTransactionIntegrationClick.bind(this)
  }

  componentWillMount() {
    this.fetchTransactions(1)
  }

  fetchTransactions(page: number) {
    const { searchValue, sortValue, filters } = this.state
    this.setState({
      isFetching: true
    }, () => {
      TransactionsController
        .getTransactions({ page: page, search: searchValue, order: `${sortValue}`, ...filters })
        .then(response => {
          const { transactions, current_page, total_pages, total_entries } = response;

          this.setState({
            transactions: [...transactions],
            currentPage: current_page,
            totalPages: total_pages,
            didInitialLoad: true,
            isFetching: false
          });
        })
        .catch(console.error)
    });
  }

  onTableTransactionClick(transaction: Transaction) {
    this.props.history.push(RouteHelper.process(ERoute.PATH_TRANSACTION, { id: transaction.id }))
  }

  onTableActionClick(key: string, transaction: Transaction) {
    switch (key) {
      default:
        throw Error('[Transactions] Unimplemented onTableActionClick')
    }
  }

  onTableSelectionChange(selectedTransactionIds: string[]) {
    this.setState({ selectedTransactionIds: selectedTransactionIds })
  }

  onTableRowSelectionChange(selected: boolean, transactionId: string) {
    const { selectedTransactionIds } = this.state

    if (selected) {
      this.setState({ selectedTransactionIds: [...selectedTransactionIds, transactionId] })
    } else {
      this.setState({ selectedTransactionIds: selectedTransactionIds.filter(selectedTransactionId => selectedTransactionId !== transactionId) })
    }
  }

  onTransactionFormSubmit(transaction: Transaction) {
    const { transactions } = this.state;

    const newTransactions = [transaction, ...transactions];

    this.setState({ transactions: newTransactions });
  }

  onTransactionUpdateFormSubmit(transaction: Transaction) {
    const { transactions } = this.state

    const transactionIndex = transactions.findIndex(c => c.id === transaction.id);

    if (transactionIndex !== -1) {
      transactions[transactionIndex] = transaction
    }

    this.setState({
      transactions: [
        ...transactions,
      ]
    })
  }

  onTransactionFiltersChange(filters: any) {
    this.setState({ filters: filters }, () => {
      this.fetchTransactions(1)
    })
  }

  onTransactionSortValueChange(value: string) {
    LocalStorage.set(LocalStorageKey.TRANSACTION_SORT_VALUE, value)
    this.setState({
      sortValue: value
    }, () => {
      this.fetchTransactions(1)
    })
  }

  onTransactionSearchChange(searchValue) {
    this.setState({ searchValue: searchValue })
  }

  onTransactionSearchSubmit(searchValue) {
    this.setState({ searchValue: searchValue }, () => this.fetchTransactions(1))
  }

  onTransactionClearFilters() {
    this.setState({
      searchValue: '',
      filters: {}
    }, () => this.fetchTransactions(1))
  }

  onAddTransactionIntegrationClick() {
    this.props.history.push(RouteHelper.process(ERoute.PATH_INTEGRATION, { type: "ponto" }))
  }

  render() {
    const { t, currentUser } = this.props
    const { setting } = currentUser.workspace
    const {
      transactions,
      selectedTransactionIds,
      didInitialLoad,
      isFetching,
      filters,
      sortValue,
      searchValue,
      currentPage,
      totalPages
    } = this.state

    const filtersActive = searchValue?.length > 0 || Object.keys(filters).length > 0

    return (
      <>
        <Helmet>
          <title>{t('Transactions::{{__appName}} | Transactions')}</title>
        </Helmet>

        <TopNavigation
          icon='arrow-right-arrow-left'
          title={t('Transactions::Transactions')}
        />

        <ScrollToTopOnMount />

        <PageContent className='is-transactions'>
          <PageHeader title={t('Transactions::Transactions')} />
          {!didInitialLoad && <PageLoader />}
          {didInitialLoad && <ResourceTable
            data={transactions}
            headers={[
              { title: '' },
              { title: t('Transactions::Counterpart name') },
              { title: t('Transactions::Account number') },
              { title: t('Transactions::Remittance information') },
              { title: t('Transactions::Execution date'), align: 'right' },
              { title: t('Transactions::Amount'), align: 'right' },
            ]}
            renderRow={(transaction: Transaction) => {
              const isPositive = Number(transaction.amount) > 0
              return (
                <ResourceTableRow
                  key={transaction.id}
                  selected={selectedTransactionIds.includes(transaction.id)}
                >
                  <ResourceTableRowData onClick={() => this.onTableTransactionClick(transaction)} maxWidth='30px'>
                    {transaction.amount === transaction.linked_amount && <span style={{ color: Style.color.brandSuccess }}><Icon icon='check' /></span>}
                    {transaction.amount !== transaction.linked_amount && <span style={{ color: Style.color.brandDanger }}><Icon icon='close' /></span>}
                  </ResourceTableRowData>
                  <ResourceTableRowData onClick={() => this.onTableTransactionClick(transaction)} maxWidth='150px' ellipse>
                    {transaction?.counterpart_name || '-'}
                  </ResourceTableRowData>
                  <ResourceTableRowData onClick={() => this.onTableTransactionClick(transaction)}>
                    {transaction?.counterpart_account_number || '-'}
                  </ResourceTableRowData>
                  <ResourceTableRowData onClick={() => this.onTableTransactionClick(transaction)} maxWidth='150px' ellipse>
                    {transaction.remittance_information}
                  </ResourceTableRowData>
                  <ResourceTableRowData onClick={() => this.onTableTransactionClick(transaction)} textAlign='right'>
                    {moment(transaction.execution_time).format(setting.date_format)}
                  </ResourceTableRowData>
                  <ResourceTableRowData
                    onClick={() => this.onTableTransactionClick(transaction)}
                    textAlign='right'
                    style={{ fontWeight: 'bold', color: isPositive ? Style.color.brandSuccess : Style.color.brandDanger }}
                  >
                    {NumberFormatter.formatCurrency(transaction.currency, setting.number_format, transaction.amount)}
                  </ResourceTableRowData>
                </ResourceTableRow>
              )
            }}
            renderEmpty={<CardEmptyInfo
              icon={filtersActive ? 'search' : 'arrow-right-arrow-left'}
              description={filtersActive ? t('Transactions::No transactions found') : t('Transactions::No transactions have been added yet')}
              descriptionActionText={filtersActive ? t('Transactions::Clear filters') : t('Transactions::Add integration')}
              onDescriptionActionClick={filtersActive ? this.onTransactionClearFilters : this.onAddTransactionIntegrationClick}
            />}
            filters={[
              { name: 'creditor_name', label: t('Transactions::Name'), type: ResourceListFilterType.STRING },
              { name: 'creditor_account', label: t('Transactions::Account'), type: ResourceListFilterType.STRING },
              { name: 'remittance_information', label: t('Transactions::Remittance information'), type: ResourceListFilterType.STRING },
              { name: 'execution_date', label: t('Transactions::Execution date'), type: ResourceListFilterType.DATE },
              { name: 'amount', label: t('Transactions::Amount'), type: ResourceListFilterType.NUMBER },
              { name: 'created_at', label: t('Transactions::Created date'), type: ResourceListFilterType.DATE },
            ]}
            onFiltersChange={this.onTransactionFiltersChange}
            sortOptions={[
              { label: '-', value: '-' },
              { label: t('Transactions::Name (A-Z)'), value: 'counterpart_name_asc' },
              { label: t('Transactions::Name (Z-A)'), value: 'counterpart_name_desc' },
              { label: t('Transactions::Execution date ↑'), value: 'execution_time_asc' },
              { label: t('Transactions::Execution date ↓'), value: 'execution_time_desc' },
              { label: t('Transactions::Created at ↑'), value: 'created_at_asc' },
              { label: t('Transactions::Created at ↓'), value: 'created_at_desc' },
            ]}
            sortValue={sortValue}
            onSortChange={this.onTransactionSortValueChange}
            pagination={{ page: currentPage, pageCount: totalPages }}
            onPageChange={(page) => this.fetchTransactions(page)}
            isLoading={isFetching}
            stickyHeader={true}
            searchValue={searchValue}
            onSearchChange={this.onTransactionSearchChange}
            onSearchSubmit={this.onTransactionSearchSubmit}
            maxHeight='55vh'
          />}
        </PageContent>
      </>
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    }
  } = state

  return {
    currentUser: currentUser,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    showConfirmModal: (options) => dispatch(showConfirmModal(options)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Transactions))