import * as React from 'react'
import { withRouter, RouteComponentProps } from 'react-router';
import styled, { css } from 'styled-components'
import { Style } from '../../styles'
import moment from '../../utilities/Moment'
import { NavigateButton } from '../Calendar/CalendarToolbar'
import FlyoutHeader from '../Flyout/FlyoutHeader'
import { useTranslation } from 'react-i18next'
import ERoute from '../../ERoute';
import { CalendarViewEvent, Contact, ContactGroup, CurrentUser, UserWorkspaceSettingScope } from '../../types';
import { Moment } from 'moment';
import SidebarFlyoutContainer from './SidebarFlyoutContainer';
import FlyoutContent from '../Flyout/FlyoutContent';
import GroupsSimplebar from './SidebarContact/GroupsSimplebar';
import RouteHelper from '../../helpers/RouteHelper';
import StarredContactsSimplebar from './SidebarContact/StarredContactsSimplebar';
import RecentContactsSimplebar from './SidebarContact/RecentContactsSimplebar';
import UserWorkspaceSettingHelper from '../../helpers/UserWorkspaceSettingHelper';

const Wrapper = styled.div`
  position: relative;
  width: 300px;
  min-width: 550px;
  min-height: 620px;
  height: 620px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.25);
  overflow-x: hidden;
  overflow-y: auto;
  border: 1px solid ${Style.color.border};
`

const HeaderContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 8;
  background-color: rgba(238,238,238,.8);
`

const VerticalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28px;
`

type IProps = {
  active: boolean
  onClose: () => void
} & RouteComponentProps

interface IState {
  events: CalendarViewEvent[]
  date: Moment

}

const SidebarContact = (props: IProps) => {
  const { t } = useTranslation()
  const { active, history, onClose } = props

  const onNavigateToContacts = () => {
    history.push(ERoute.PATH_CONTACTS)
    onClose()
  }

  const onContactGroupItemClick = (contactGroup: ContactGroup) => {
    history.push(RouteHelper.process(ERoute.PATH_CONTACTS, { groupId: contactGroup.id }))
    onClose()
  }

  const onManageGroupsClick = () => {
    history.push(ERoute.PATH_SETTINGS_CONTACTS)
    onClose()
  }

  const onContactItemClick = (contact: Contact) => {
    history.push(RouteHelper.process(ERoute.PATH_CONTACT, { id: contact.id }))
    onClose()
  }

  return (
    <SidebarFlyoutContainer active={active}>
      <Wrapper>
        <HeaderContainer>
          <FlyoutHeader
            icon='user'
            title={t('SidebarContact::Contacts')}
            action={{
              label: t('SidebarContact::View contacts'),
              onClick: onNavigateToContacts
            }}
          />
        </HeaderContainer>

        <FlyoutContent>
          <div className='grid'>
            <div className='grid-cell with-6col'>
              <GroupsSimplebar
                active={active}
                onItemClick={onContactGroupItemClick}
                onManageGroupsClick={onManageGroupsClick}
              />
            </div>

            <div className='grid-cell with-6col'>
              <VerticalContainer>
                <StarredContactsSimplebar
                  active={active}
                  onItemClick={onContactItemClick}
                />
                <RecentContactsSimplebar
                  active={active}
                  onItemClick={onContactItemClick}
                />
              </VerticalContainer>
            </div>
          </div>
        </FlyoutContent>
      </Wrapper>
    </SidebarFlyoutContainer>
  )
}

export default withRouter(SidebarContact)