import * as React from 'react'
import styled, { css } from 'styled-components'
import { Style } from '../styles'
import { useTranslation } from 'react-i18next'
import Icon from '../components/Icons/Icon'
import ContentableTemplateItem from '../components/ContentTemplate/ContentTemplateItem'
import TabItem from '../components/Navigation/TabItem'
import TabItems from '../components/Navigation/TabItems'
import BlocksRenderer from '../components/BlockEditor/BlocksRenderer'
import { useSelector } from 'react-redux'
import { AppState } from '../store'
import Button from '../components/Button/Button'
import { RouteComponentProps } from 'react-router-dom'
import PlaybookHelper, { PlaybookTemplate } from '../helpers/PlaybookHelper'
import { PlaybooksController, ResourceContentBlocksController } from '../controllers'
import { ContentBlockResource } from '../types'
import RouteHelper from '../helpers/RouteHelper'
import ERoute from '../ERoute'
import NavigationToggle from '../components/ContentTemplate/NavigationToggle'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
`

const Header = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  min-height: 56px;
  width: 100%;
  background: #1e3946;
  padding: 0 ${Style.spacing.x2_5};
`

const HeaderBackButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  color: white;
  fill: white;
  font-weight: bold;

  i {
    font-size: 20px;
    margin-right: ${Style.spacing.x1};
  }

  span {
    &:hover {
      text-decoration: underline;
    }
  }
`

const ContentWrapper = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: row;
  height: calc(100% - 56px);

  ${NavigationToggle} {
    display: none;

    @media screen and (max-width: ${Style.breakpoints.SMALL}) {
      display: flex;
    }
  }
`

const Sidebar = styled.div<{ active?: boolean }>`
  display: flex;
  flex: 1;
  flex-direction: column;
  border-right: 1px solid ${Style.color.border};
  overflow-y: auto;
  overflow-x: hidden;
  min-width: 550px;
  max-width: 550px;
  width: 550px;
  background: white;  
  padding: ${Style.spacing.x2_5};
  z-index: 1;

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    position: absolute;
    top: 0;
    left: -550px;
    bottom: 0;

    ${props => props.active && css`
      left: 0;
      width: 100%;
      min-width: initial;
      max-width: initial;
    `}
  }
`

const SidebarTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: #1e3946;
  position: sticky;
  background: white;
  top: -20px;
`

const Content = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  overflow-y: auto;
  overflow-x: hidden;
  background: #f7f7fa;
  padding: ${Style.spacing.x2_5};
`

const InfoContainer = styled.div`
  background: white;
  width: 100%;
  max-width: 800px;
`

const InfoHeader = styled.div`
  padding: 0 28px;
  padding-top: ${Style.spacing.x0_5};
  border-bottom: 1px solid ${Style.color.border};

  ${TabItems} {
    margin-bottom: -1px;
  }
`

const InfoContent = styled.div`
  padding: 32px 28px;
`

const ToggleSidebarContainer = styled.div<{ active?: boolean }>`
  position: absolute;
  top: 85px;
  z-index: 2;
  right: 462px;
  display: none;
  left: 0;

  @media screen and (max-width: ${Style.breakpoints.SMALL}) {
    display: flex;

    ${props => props.active && css`
      top: 75px;
      left: initial;
      right: 25px;
  `}
  }
`

enum PlaybookTab {
  HOW_IT_WORKS = 'HOW_IT_WORKS',
  PREVIEW = 'PREVIEW'
}

type IProps = RouteComponentProps<any>

interface IState {
  navigationActive: boolean
  activeTab: PlaybookTab
  activePlaybookTemplate: PlaybookTemplate
}

const PlaybooksCreate = (props: IProps) => {
  const { t } = useTranslation()
  const currentUser = useSelector((state: AppState) => state.authentication.currentUser)
  const { workspace: { setting } } = currentUser
  const playbookTemplateCategories = PlaybookHelper.getPlaybookTemplateCategories()
  const [state, setState] = React.useState<IState>({
    navigationActive: false,
    activeTab: PlaybookTab.HOW_IT_WORKS,
    activePlaybookTemplate: playbookTemplateCategories[0].templates[0],
  })
  const { navigationActive, activeTab, activePlaybookTemplate } = state

  const onBackClick = () => {
    props.history.goBack()
  }

  const onCreatePlaybookClick = async () => {
    try {
      const playbook = await PlaybooksController.create({ name: activePlaybookTemplate.title })

      for (const block of activePlaybookTemplate.contentBlocks) {
        await ResourceContentBlocksController.create(ContentBlockResource.PLAYBOOK, playbook.id, block)
      }

      props.history.replace(RouteHelper.process(ERoute.PATH_PLAYBOOK, { id: playbook.id }))
    } catch (ex) {
      console.error(ex)
    }
  }

  const onToggleTemplateClick = () => {
    setState({ ...state, navigationActive: !state.navigationActive })
  }

  const onTabChange = (tab: PlaybookTab) => {
    setState({ ...state, activeTab: tab })
  }

  const onPlaybookTemplateClick = (playbookTemplate: PlaybookTemplate) => {
    setState({ ...state, navigationActive: false, activePlaybookTemplate: playbookTemplate })
  }

  return (
    <Container>
      <Header>
        <HeaderBackButton onClick={onBackClick}>
          <Icon icon='chevron-left' />
          <span>{t('PlaybooksCreate::Back')}</span>
        </HeaderBackButton>

        <Button
          text={t('PlaybooksCreate::Create playbook')}
          onClick={onCreatePlaybookClick}
          type='success'
        />
      </Header>
      <ToggleSidebarContainer active={navigationActive}>
        <NavigationToggle onClick={onToggleTemplateClick}>
          <Icon icon={navigationActive ? 'chevrons-left' : 'chevrons-right'} />
        </NavigationToggle>
      </ToggleSidebarContainer>
      <ContentWrapper>
        <Sidebar active={navigationActive}>
          {playbookTemplateCategories.map((category, index) => {
            return (
              <>
                <SidebarTitle>
                  {category.title}
                </SidebarTitle>

                {category?.templates?.map((template, index) => {
                  return (
                    <ContentableTemplateItem
                      key={index}
                      icon={template.icon}
                      title={template.title}
                      description={template.description}
                      onClick={() => onPlaybookTemplateClick(template)}
                      active={activePlaybookTemplate.title === template.title}
                    />
                  )
                })}
              </>
            )
          })}
        </Sidebar>

        <Content>
          <InfoContainer>
            <InfoHeader>
              <TabItems>
                <TabItem
                  active={activeTab === PlaybookTab.HOW_IT_WORKS}
                  onClick={() => onTabChange(PlaybookTab.HOW_IT_WORKS)}
                >
                  {t('PlaybooksCreate::How it works')}
                </TabItem>
                <TabItem
                  active={activeTab === PlaybookTab.PREVIEW}
                  onClick={() => onTabChange(PlaybookTab.PREVIEW)}
                >
                  {t('PlaybooksCreate::Preview')}
                </TabItem>
              </TabItems>
            </InfoHeader>

            <InfoContent>
              {activeTab === PlaybookTab.HOW_IT_WORKS && <div className="text-editor">
                <h4>{t('PlaybooksCreate::Create a playbook')}</h4>
                <p>
                  {t('PlaybooksCreate::Equip your team with the ultimate advantage by providing them with sales content precisely when and where they require it. Build a custom playbook by adding content including text, links, images or embed codes.')}
                </p>
                <h4>{t('PlaybooksCreate::Add interactive questions')}</h4>
                <p>
                  {t('PlaybooksCreate::Integrate strategic questions into your playbook to effortlessly gather and structure essential information. As you input responses, they are directly saved into your CRM, ensuring no valuable detail goes unnoticed.')}
                </p>
                <h4>{t('PlaybooksCreate::Start using playbooks')}</h4>
                <p>
                  {t('PlaybooksCreate::Look up and open your playbooks directly from the contact, company, or deal record. When you make notes within the playbook, they will be stored as engagements in the timeline of your record.')}
                </p>
              </div>}
              {activeTab === PlaybookTab.PREVIEW && <BlocksRenderer
                blocks={activePlaybookTemplate?.contentBlocks || []}
                variables={{}}
                currency='EUR'
                numberFormat={setting.number_format}
              />}
            </InfoContent>
          </InfoContainer>
        </Content>
      </ContentWrapper>
    </Container>
  )
}

export default PlaybooksCreate