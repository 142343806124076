import * as React from 'react'
import { withRouter, RouteComponentProps } from 'react-router'
import { Calendar as ReactBigCalendar, momentLocalizer, SlotInfo, View } from 'react-big-calendar'
import styled, { css } from "styled-components"
import { Style } from "../../styles"
import CalendarWeekHeader from "./CalendarDateHeader"
import CalendarToolbar from "./CalendarToolbar"
import CalendarEventWrapper from "./CalendarEventWrapper"
import CalendarEventView from "./CalendarEventView"
import moment from '../../utilities/Moment'
import { CalendarEvent, CalendarViewEvent, CalendarViewEventType } from '../../types'
import { Moment } from 'moment'
import RouteHelper from '../../helpers/RouteHelper'
import ERoute from '../../ERoute'
import { showCalendarEventModal, showConfirmModal } from '../../store/modals/actions'
import { AppState } from '../../store'
import { connect } from 'react-redux'

const Container = styled.div<{ view: View }>`
  width: 100%;
  height: 100%;

  .rbc-time-view, .rbc-month-view {
    @media screen and (max-width: ${Style.breakpoints.SMALL}) {
      overflow-x: auto;
    }

    // Week
    .rbc-time-header, .rbc-time-content,
    // Month
    .rbc-month-header,.rbc-month-row {
      @media screen and (max-width: ${Style.breakpoints.SMALL}) {
        ${(props) => !['day', 'agenda'].includes(props.view) && css`
          width: 1200px;
        `}
      }
    }
  }
`

interface IStateToProps { }

interface IDispatchToProps {
  showCalendarEventModal: typeof showCalendarEventModal
  showConfirmModal: typeof showConfirmModal
}

type IProps = {
  events: CalendarViewEvent[]
  printContainerRef?: React.RefObject<HTMLDivElement>
  view: View
  date: Moment
  onView?: ((view: View) => void)
  onSelectEvent?: ((event: any, e: React.SyntheticEvent<HTMLElement>) => void)
  onNavigate?: ((date: Date, view: View, action: any) => void)
  onRangeChange?: (range: Date[] | { start: Date; end: Date }, view?: View) => void
  onCalendarEventSubmit?: (event: CalendarEvent) => void;
  onCalendarEventDelete?: (eventId: string) => void;
  hideToolbar?: boolean
} & IStateToProps & IDispatchToProps & RouteComponentProps

const Calendar = (props: IProps) => {
  const {
    events,
    date,
    printContainerRef,
    view,
    hideToolbar,
    onView,
    onNavigate,
    onRangeChange,
    onCalendarEventSubmit,
    onCalendarEventDelete,
  } = props
  const localizer = momentLocalizer(moment)

  const onSelectEvent = (event: CalendarViewEvent) => {
    switch (event.type) {
      case CalendarViewEventType.PROJECT:
        props.history.push(RouteHelper.process(ERoute.PATH_PROJECT, { id: event.resource.id }))
        break
      case CalendarViewEventType.QUOTATION:
      case CalendarViewEventType.INVOICE:
      case CalendarViewEventType.RECURRING_INVOICE:
        props.history.push(RouteHelper.process(ERoute.PATH_INVOICE, { id: event.resource.id }))
        break
      case CalendarViewEventType.TASK:
        props.history.push(RouteHelper.process(ERoute.PATH_TASKS_OVERVIEW, { taskId: event.resource.id }))
        break
      case CalendarViewEventType.CALENDAR_EVENT:
        requestAnimationFrame(() => {
          props.showCalendarEventModal({
            calendarEvent: { id: event.resource.id },
            onSubmit: onCalendarEventSubmit,
            onDelete: onCalendarEventDelete
          })
        })
        break
      case CalendarViewEventType.DEAL:
        props.history.push(RouteHelper.process(ERoute.PATH_DEAL, { id: event.resource.id }))
        break
    }
  }

  const onSelectSlot = (info: SlotInfo) => {
    const { start, end } = info

    props.showCalendarEventModal({
      calendarEvent: {
        start: start,
        end: end,
        all_day: view === 'month' || start.getHours() === 0 && start.getMinutes() === 0 && end.getHours() === 0 && end.getMinutes() === 0
      },
      onSubmit: onCalendarEventSubmit,
      onDelete: onCalendarEventDelete
    })
  }

  return (
    <Container view={view} ref={printContainerRef}>
      <ReactBigCalendar
        localizer={localizer}
        views={['day', 'week', 'month', 'agenda']}
        view={view}
        events={events}
        getNow={() => moment().toDate()}
        startAccessor="start"
        endAccessor="end"
        selectable={true}
        date={date.toDate()}
        onView={onView}
        onSelectEvent={onSelectEvent}
        onSelectSlot={onSelectSlot}
        onNavigate={onNavigate}
        onRangeChange={onRangeChange}
        formats={{
          eventTimeRangeFormat: (range, culture, localizer) => localizer.format(range.start, 'H:mm A', culture),
        }}
        scrollToTime={moment().toDate()}
        timeslots={4}
        step={15}
        components={{
          week: {
            header: CalendarWeekHeader
          },
          toolbar: (props) => <CalendarToolbar {...props} hide={hideToolbar} />,
          eventWrapper: (props) => <CalendarEventWrapper {...props} />,
          event: (props) => <CalendarEventView {...props} view={view} />,
        }}
        allDayAccessor={(event => Boolean(event.all_day))}
      />

    </Container>

  )
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    }
  } = state

  return {}
}

const mapDispatchToProps: IDispatchToProps = {
  showCalendarEventModal,
  showConfirmModal,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Calendar))
