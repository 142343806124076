import * as React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next';
import styled from 'styled-components'
import { Style } from '../../styles'
import FixedSidebarContainer from '../Sidebar/FixedSidebarContainer'
import SidebarDivider from '../Sidebar/SidebarDivider'
import SidebarHeader from '../Sidebar/SidebarHeader'
import SidebarClose from '../Sidebar/SidebarClose'
import SidebarContent from '../Sidebar/SidebarContent'
import SidebarSection from '../Sidebar/SidebarSection'
import { CalendarViewEventType, Task } from '../../types'
import ResourceCreatablePowerSelect from '../Form/ResourceCreatablePowerSelect'
import { TasksController } from '../../controllers';
import PlannerSidebarTask from './PlannerSidebarTask';
import { Droppable } from 'react-beautiful-dnd';
import Loader from '../Loaders/Loader';
import Utils from '../../utilities/Utils';
import CardEmptyInfo from '../Card/CardEmptyInfo';
import ButtonFilter from '../Button/ButtonFilter';
import ButtonPanel from '../Button/ButtonPanel';
import Button from '../Button/Button';

const TaskItems = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	gap: ${Style.spacing.x0_75};
	background: white;
	overflow: hidden;
`

const LoaderContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 40px 0;
`

const EmptyContainer = styled.div`
	.card-empty-info {
		padding: 40px;
	}
`

export interface ICalendarFilter {
	searchValue: string
	eventTypes: CalendarViewEventType[]
}

type IProps = {
	active: boolean
	onCloseClick: () => void
	onTasksChange: (tasks: Task[]) => void
	onCreateTaskClick: () => void
} & WithTranslation

interface IState {
	searchValue: string
	projectIds: string[]
	tasks: Task[]
	currentPage: number
	totalPages: number
	didInitialLoad: boolean
	isFetching: boolean
	reachedEnd: boolean
}

class PlannerSidebar extends React.Component<IProps, IState> {
	private container = React.createRef<HTMLDivElement>()
	private input = React.createRef<HTMLInputElement>()

	constructor(props: IProps) {
		super(props)

		this.state = {
			tasks: [],
			currentPage: 1,
			totalPages: 0,
			searchValue: '',
			projectIds: [],
			didInitialLoad: false,
			isFetching: false,
			reachedEnd: false
		}

		this.onOutsideClick = this.onOutsideClick.bind(this)
		this.onCloseClick = this.onCloseClick.bind(this)
		this.onSearchChange = this.onSearchChange.bind(this)
		this.onProjectsChange = this.onProjectsChange.bind(this)
		this.fetchTasksDebounced = Utils.debounce(this.fetchTasks, 250, false)
		this.onClearFiltersClick = this.onClearFiltersClick.bind(this)
		this.onCreateTaskClick = this.onCreateTaskClick.bind(this)
	}

	fetchTasksDebounced() {
		this.fetchTasks().catch(console.error)
	}

	async fetchTasks(page: number = 1) {
		const { onTasksChange } = this.props
		const { currentPage, tasks: stateTasks, searchValue, projectIds } = this.state
		try {
			this.setState({ isFetching: true })

			let params = {
				page: page,
				order: 'created_at_desc',
				'time_remaining[gt]': 0,
			}

			if (searchValue.length > 0) {
				params['search'] = searchValue
			}

			if (projectIds.length > 0) {
				params['project_id[in]'] = projectIds.join(',')
			}

			const response = await TasksController.getTasks(params)

			const { tasks, current_page, total_pages, total_entries } = response;

			this.setState({
				tasks: current_page === 1 ? [...tasks] : [...stateTasks, ...tasks],
				currentPage: current_page,
				totalPages: total_pages,
				didInitialLoad: true,
				isFetching: false,
				reachedEnd: current_page === total_pages
			}, () => {
				onTasksChange(this.state.tasks)
			});
		} catch (ex) {
			console.error(ex)
		}
	}

	componentDidMount(): void {
		document.addEventListener('mousedown', this.onOutsideClick);
	}

	componentWillUnmount(): void {
		document.removeEventListener('mousedown', this.onOutsideClick);
	}

	componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
		if (!prevProps.active && this.props.active) {
			this.setState({ didInitialLoad: false }, () => {
				this.fetchTasks()
			})
		}
	}

	onOutsideClick(e) {
		const { active, onCloseClick } = this.props

		if (active && this.container && !this.container.current.contains(e.target)) {
			onCloseClick()
		}
	}

	onCloseClick() {
		this.props.onCloseClick()
	}

	onSearchChange(e) {
		this.setState({ searchValue: e.target.value }, this.fetchTasksDebounced)
	}

	onProjectsChange(assigneeIds: string[] | null) {
		this.setState({ projectIds: assigneeIds || [] }, this.fetchTasks)
	}

	onClearFiltersClick() {
		this.setState({ didInitialLoad: false, searchValue: '', projectIds: [] }, () => {
			this.fetchTasks()
		})
	}

	onCreateTaskClick() {
		this.props.onCreateTaskClick()
	}

	render() {
		const { active, t } = this.props
		const { searchValue, projectIds, tasks, didInitialLoad } = this.state

		const filtersActive = searchValue.length > 0 || projectIds.length > 0

		return (
			<FixedSidebarContainer ref={this.container} active={active} style={{ zIndex: 8 }}>
				<SidebarHeader>
					{t('PlannerSidebar::Plan tasks')}
					<SidebarClose onClick={this.onCloseClick} />
				</SidebarHeader>

				<SidebarDivider />

				<SidebarContent>
					<SidebarSection>
						<div className='form-item'>
							<label>{t('PlannerSidebar::Search')}</label>
							<input
								ref={this.input}
								type='text'
								value={searchValue}
								onChange={this.onSearchChange}
								style={{ marginBottom: Style.spacing.x1 }}
								placeholder={t('PlannerSidebar::Search tasks...')}
							/>
						</div>

						<div className='form-item'>
							<label>{t('PlannerSidebar::Projects')}</label>
							<ResourceCreatablePowerSelect
								type='project'
								value={projectIds}
								onChange={this.onProjectsChange}
								isClearable={true}
								isValidNewOption={() => false}
								isMulti={true}
								placeholder={t('PlannerSidebar::Select projects')}
							/>
						</div>
					</SidebarSection>

					<ButtonPanel
						icon='tasks'
						text={t('PlannerSidebar::Create task')}
						onClick={this.onCreateTaskClick}
					/>

					<SidebarDivider />

					{!didInitialLoad && (
						<LoaderContainer>
							<Loader />
						</LoaderContainer>
					)}
					{didInitialLoad && <>
						{tasks.length === 0 && (<EmptyContainer>
							<CardEmptyInfo
								icon={filtersActive ? 'search' : 'tasks'}
								description={filtersActive ? t('PlannerSidebar::No plannable tasks found') : t('PlannerSidebar::No tasks created yet')}
								descriptionActionText={filtersActive ? t('PlannerSidebar::Clear filters') : t('PlannerSidebar::Create a task')}
								onDescriptionActionClick={filtersActive ? this.onClearFiltersClick : this.onCreateTaskClick}
							/>
						</EmptyContainer>)}
						{tasks.length > 0 && <Droppable droppableId='tasks'>
							{(provided, droppableSnapshot) => {
								return (
									<TaskItems ref={provided.innerRef} {...provided.droppableProps}>
										{tasks.map((task, index) => {
											return (
												<PlannerSidebarTask
													key={task.id}
													index={index}
													task={task}
												/>
											)
										})}
										{provided.placeholder}
									</TaskItems>
								)
							}}
						</Droppable>}
					</>}
				</SidebarContent>
			</FixedSidebarContainer >
		)
	}
}

export default withTranslation()(PlannerSidebar)