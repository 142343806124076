import * as React from "react";
import { CurrentUser, Expense, ResourceListFilterType } from "../../types";
import PageLoader from "../Page/PageLoader";
import { WithTranslation, withTranslation } from "react-i18next";
import ResourceTable from "../Resource/ResourceTable";
import ResourceTableRow from "../Resource/ResourceTableRow";
import ResourceTableRowData from "../Resource/ResourceTableRowData";
import moment from "../../utilities/Moment";
import { connect } from "react-redux";
import { AppState } from "../../store";
import NumberFormatter from "../../utilities/NumberFormatter";
import CardEmptyInfo from "../Card/CardEmptyInfo";
import { ExpensesController } from "../../controllers";
import ResourceTableRowDataLink from "../Resource/ResourceTableRowDataLink";
import { withRouter, RouteComponentProps } from 'react-router';
import RouteHelper from "../../helpers/RouteHelper";
import ERoute from "../../ERoute";
import { Dispatch } from "redux";
import LocalStorage, { LocalStorageKey } from "../../LocalStorage";
import { Style } from "../../styles";
import { showExpenseModal } from "../../store/modals/actions";

interface IStateToProps {
    currentUser: CurrentUser
}

type IProps = {
    expenseIds: string[]
    onUnlinkClick: (expenseIds: string[]) => void
} & RouteComponentProps & WithTranslation & IStateToProps & IDispatchToProps

interface IDispatchToProps {
    showExpenseModal: typeof showExpenseModal
}

interface IState {
    expenses: Expense[]
    currentPage: number
    totalPages: number
    filters: any
    didInitialLoad: boolean
    isFetching: boolean
    selectedExpenseIds: string[]
    sortValue: string
    searchValue: string
}

class LinkedExpenses extends React.Component<IProps, IState> {
    constructor(props) {
        super(props)

        this.state = {
            expenses: [],
            currentPage: 0,
            totalPages: 0,
            filters: {},
            didInitialLoad: false,
            searchValue: '',
            sortValue: LocalStorage.get(LocalStorageKey.EXPENSE_SORT_VALUE, 'invoiced_on_desc'),
            isFetching: false,
            selectedExpenseIds: []
        }

        this.fetchExpenses = this.fetchExpenses.bind(this)
        this.onExpenseClick = this.onExpenseClick.bind(this)
        this.onFiltersChange = this.onFiltersChange.bind(this)
        this.onSortValueChange = this.onSortValueChange.bind(this)
        this.onSelectionChange = this.onSelectionChange.bind(this)
        this.onTableRowSelectionChange = this.onTableRowSelectionChange.bind(this)
        this.onSearchChange = this.onSearchChange.bind(this)
        this.onSearchSubmit = this.onSearchSubmit.bind(this)
        this.onClearFilter = this.onClearFilter.bind(this)
        this.onUnlinkClick = this.onUnlinkClick.bind(this)
    }

    componentDidMount(): void {
        this.fetchExpenses(1)
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
        if (prevProps.expenseIds !== this.props.expenseIds) this.fetchExpenses(1)
    }

    async fetchExpenses(page: number = 1) {
        const { expenseIds } = this.props
        const { searchValue, filters, sortValue } = this.state
        try {
            this.setState({ isFetching: true })

            const { current_page, total_pages, expenses } = await ExpensesController.getExpenses({
                page: page,
                search: searchValue,
                order: sortValue,
                'id[in]': expenseIds,
                ...filters
            })

            this.setState({
                ...this.state,
                expenses: expenses,
                currentPage: current_page,
                totalPages: total_pages,
                didInitialLoad: true,
                isFetching: false
            })
        } catch (ex) {
            console.error(ex)
        }
    }

    onExpenseClick(expense) {
        this.props.showExpenseModal({
            expense: { id: expense.id },
            onSubmit: (expense) => {
                const { expenses } = this.state

                const expenseIndex = expenses.findIndex(e => e.id === expense.id)

                expenses[expenseIndex] = expense

                this.setState({ expenses: [...expenses] })
            }
        })
    }

    onFiltersChange(filters: any) {
        this.setState({ ...this.state, filters: filters }, () => {
            this.fetchExpenses(1)
        })
    }

    onSortValueChange(sortValue: string) {
        LocalStorage.set(LocalStorageKey.EXPENSE_SORT_VALUE, sortValue)

        this.setState({ sortValue: sortValue }, () => {
            this.fetchExpenses(1)
        })
    }

    onSelectionChange(selectedExpenseIds: string[]) {
        this.setState({ selectedExpenseIds: selectedExpenseIds })
    }

    onTableRowSelectionChange(selected: boolean, ledgerItemId: string) {
        const { selectedExpenseIds } = this.state

        if (selected) {
            this.setState({ selectedExpenseIds: [...selectedExpenseIds, ledgerItemId] })
        } else {
            this.setState({ selectedExpenseIds: selectedExpenseIds.filter(selectedLedgerItemId => selectedLedgerItemId !== ledgerItemId) })
        }
    }

    onSearchChange(searchValue) {
        this.setState({ ...this.state, searchValue: searchValue })
    }

    onSearchSubmit(searchValue) {
        this.setState({ ...this.state, searchValue: searchValue }, () => {
            this.fetchExpenses(1)
        })
    }

    onClearFilter() {
        this.setState({
            searchValue: '',
            filters: {}
        }, () => this.fetchExpenses(1))
    }

    onUnlinkClick(expenseIds: string[]) {
        const { onUnlinkClick } = this.props

        // Clear the selected ledger item ids
        this.setState({ selectedExpenseIds: [] })

        // Call the parent component's onUnlinkClick function
        onUnlinkClick(expenseIds)
    }

    render() {
        const { expenseIds, t, currentUser } = this.props
        const { didInitialLoad, expenses, searchValue, filters, currentPage, totalPages, isFetching, selectedExpenseIds, sortValue } = this.state
        const { workspace: { setting } } = currentUser

        const filtersActive = searchValue?.length > 0 || Object.keys(filters).length > 0

        return (
            <>
                {!didInitialLoad && <PageLoader />}
                {didInitialLoad && <ResourceTable
                    data={expenses}
                    headers={[
                        { title: t('LinkedExpenses::Name') },
                        { title: t('LinkedExpenses::Category') },
                        { title: t('LinkedExpenses::Date'), align: 'right' },
                        { title: t('LinkedExpenses::Amount'), align: 'right' },
                        { title: '', align: 'right' },
                    ]}
                    renderRow={(expense: Expense) => {
                        return (
                            <ResourceTableRow
                                key={expense.id}
                                selected={selectedExpenseIds.includes(expense.id)}
                                onSelectionChange={(selected) => this.onTableRowSelectionChange(selected, expense.id)}
                            >
                                <ResourceTableRowData maxWidth='150px' ellipse onClick={() => this.onExpenseClick(expense)}>
                                    {expense.name}
                                </ResourceTableRowData>
                                <ResourceTableRowData maxWidth='150px' ellipse onClick={() => this.onExpenseClick(expense)}>
                                    {expense.category?.name || '-'}
                                </ResourceTableRowData>
                                <ResourceTableRowData textAlign='right' onClick={() => this.onExpenseClick(expense)}>
                                    {moment(expense.invoiced_on).format(setting.date_format)}
                                </ResourceTableRowData>
                                <ResourceTableRowData textAlign='right' onClick={() => this.onExpenseClick(expense)} style={{
                                    fontWeight: 'bold',
                                    color: Style.color.brandDanger
                                }}>

                                    {NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, -expense.amount)}
                                </ResourceTableRowData>
                                <ResourceTableRowData textAlign='right' maxWidth='150px' ellipse onClick={() => this.onUnlinkClick([expense.id])}>
                                    <ResourceTableRowDataLink>
                                        {t('LinkedExpenses::Unlink')}
                                    </ResourceTableRowDataLink>
                                </ResourceTableRowData>
                            </ResourceTableRow>
                        )
                    }}
                    renderEmpty={<CardEmptyInfo
                        icon={filtersActive ? 'search' : 'expense'}
                        description={filtersActive ? t('LinkedExpenses::No expenses found') : t('LinkedExpenses::No expenses linked')}
                        descriptionActionText={filtersActive ? t('LinkedExpenses::Clear filters') : null}
                        onDescriptionActionClick={filtersActive ? this.onClearFilter : null}
                    />}
                    filters={[
                        { name: 'name', label: t('LinkedExpenses::Name'), type: ResourceListFilterType.STRING },
                        { name: 'invoiced_on', label: t('LinkedExpenses::Date'), type: ResourceListFilterType.DATE },
                        { name: 'amount', label: t('LinkedExpenses::Amount'), type: ResourceListFilterType.NUMBER },
                        { name: 'currency', label: t('LinkedExpenses::Currency'), type: ResourceListFilterType.STRING },
                        { name: 'contact_id', label: t('LinkedExpenses::Contact'), type: ResourceListFilterType.SINGLE_RESOURCE, resourceType: 'contact', isValidNewOption: () => false },
                        { name: 'project_id', label: t('LinkedExpenses::Project'), type: ResourceListFilterType.SINGLE_RESOURCE, resourceType: 'project', isValidNewOption: () => false },
                        { name: 'supplier_id', label: t('LinkedExpenses::Supplier'), type: ResourceListFilterType.SINGLE_RESOURCE, resourceType: 'company', isValidNewOption: () => false },
                        { name: 'booked_on', label: t('LinkedExpenses::Booked on'), type: ResourceListFilterType.DATE },
                        { name: 'paid_on', label: t('LinkedExpenses::Paid on'), type: ResourceListFilterType.DATE },
                        { name: 'billable', label: t('LinkedExpenses::Billable'), type: ResourceListFilterType.SINGLE_OPTION, options: [{ label: t('LinkedExpenses::Yes'), value: String(true) }, { label: t('LinkedExpenses::No'), value: String(false) }] },
                        { name: 'billed', label: t('LinkedExpenses::Billed'), type: ResourceListFilterType.SINGLE_OPTION, options: [{ label: t('LinkedExpenses::Yes'), value: String(true) }, { label: t('LinkedExpenses::No'), value: String(false) }] },
                        { name: 'created_at', label: t('LinkedExpenses::Created date'), type: ResourceListFilterType.DATE },
                    ]}
                    onFiltersChange={this.onFiltersChange}
                    sortOptions={[
                        { label: '-', value: '-' },
                        { label: t('LinkedExpenses::Name (A-Z)'), value: 'name_asc' },
                        { label: t('LinkedExpenses::Name (Z-A)'), value: 'name_desc' },
                        { label: t('LinkedExpenses::Date ↑'), value: 'invoiced_on_asc' },
                        { label: t('LinkedExpenses::Date ↓'), value: 'invoiced_on_desc' },
                        { label: t('LinkedExpenses::Amount ↑'), value: 'amount_asc' },
                        { label: t('LinkedExpenses::Amount ↓'), value: 'amount_desc' },
                    ]}
                    sortValue={sortValue}
                    onSortChange={this.onSortValueChange}
                    promotedBulkActions={[
                        { icon: 'unlink', content: t('LinkedExpenses::Unlink'), onAction: () => this.onUnlinkClick(selectedExpenseIds) }
                    ]}
                    pagination={{
                        page: currentPage,
                        pageCount: totalPages
                    }}
                    onPageChange={(page) => this.fetchExpenses(page)}
                    isLoading={isFetching}
                    stickyHeader={true}
                    selectedItems={selectedExpenseIds}
                    onSelectionChange={this.onSelectionChange}
                    searchValue={searchValue}
                    onSearchChange={this.onSearchChange}
                    onSearchSubmit={this.onSearchSubmit}
                    maxHeight='25vh'
                />}
            </>
        )
    }
}

const mapStateToProps = (state: AppState): IStateToProps => {
    const {
        authentication: {
            currentUser,
        }
    } = state

    return {
        currentUser: currentUser,
    }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
    return {
        showExpenseModal: (options) => dispatch(showExpenseModal(options)),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LinkedExpenses)))
