import * as React from 'react'
import ReactTooltip from 'react-tooltip'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { useDebouncedCallback } from 'use-debounce';
import styled, { css } from 'styled-components'
import { ActiveStorageController } from '../../../controllers'
import ContentBlockHelper from '../../../helpers/ContentBlockHelper'
import { Style } from '../../../styles'
import NumberFormatter from '../../../utilities/NumberFormatter'
import Editor, { MINIMAL_EDITOR_CONFIG } from '../../Editor/Editor'
import MoneyInput from '../../Form/MoneyInput'
import Icon from '../../Icons/Icon'
import { Draggable } from 'react-beautiful-dnd';
import { ItemBlock as ItemBlockType, MimeTypes, NumberFormat, Workspace, WorkspaceTax } from '../../../types';
import VATHelper from '../../../helpers/VatHelper';

const Item = styled.div`
	display: flex;
	flex-direction: column;
	padding: ${Style.spacing.x0_5};
	
	&:not(:last-child) {
		margin-bottom: ${Style.spacing.x1_5};
	}

	&:last-child {
		margin-bottom: ${Style.spacing.x3};
	}
`

const ItemInfo = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	margin-bottom: ${Style.spacing.x1};

	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
`

const ItemInfoContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	width: 100%;
`

const ItemInfoWrapper = styled.div`
	background: white;
	border: 1px solid ${Style.color.border};
	border-radius: 5px;
	height: 100%;
	width: 100%;
`

const ItemInfoTop = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	border-bottom: 1px solid ${Style.color.border};
	padding: ${Style.spacing.x0_5} ${Style.spacing.x1};
	font-weight: bold;
`

const ItemTitleInput = styled.input`
	border: none !important;
	padding: 0 !important;
	margin-right: ${Style.spacing.x0_5};

	&:active, &:focus {
		box-shadow: none !important;
	}
`

const SelectionButton = styled.div<{ selected?: boolean }>`
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: bold;
	cursor: pointer;
	border-radius: 5px;
	background: #f7f7fa;
	border: 1px solid ${Style.color.border};
	padding: 2px ${Style.spacing.x0_5};
	font-size: 14px;
	cursor: pointer;
	text-align: center;
	white-space: nowrap;

	${props => props.selected && css`
		color: #4ecf01;
		background: #d6eed1;
		border-color: #d6eed1;
	`}
`

const ItemInfoBottom = styled.div`
	padding: ${Style.spacing.x1};

	.fr-toolbar {
		border: none;
	}

	.fr-box.fr-basic .fr-wrapper {
		border: none;
	}

	.fr-second-toolbar {
		border: none;
	}

	.fr-toolbar .fr-btn-grp {
		margin: 0;
	}

	.fr-box.fr-basic .fr-element {
		padding-left: ${Style.spacing.x1};
		padding-right: ${Style.spacing.x1};
	}
`


const ItemCoverImageOverlay = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	background: rgba(0 ,0, 0, 0.5);
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	opacity: 0;
	color: white;
	font-size: 20px;
`

const ItemCoverImage = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 80px;
	height: 80px;
	min-width: 80px;
	min-height: 80px;
	border: 1px solid #c4cdd5;
	border-radius: 5px;
	color: black;
	font-size: 30px;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 50%;
	overflow: hidden;
	margin-right: ${Style.spacing.x1};
	cursor: pointer;

	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
		margin-bottom: ${Style.spacing.x1};
		margin-right: 0;
	}

	&:hover {
		${ItemCoverImageOverlay} {
			opacity: 1;
		}
	}
`

const ItemActions = styled.div`
	display: flex;
	flex-direction: column;
	margin-right: -16px;
`

const ItemAction = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: ${Style.spacing.x1};
	border-radius: 3px;
	min-height: auto;
	width: 35px;
	height: 35px;
	font-size: 14px;
	color: rgba(0, 0, 0, 0.6);
	cursor: pointer;

	svg {
		width: 14px;
		height: 14px;
		fill: rgba(0, 0, 0, 0.6);
	}

	&:hover {
		color: black;

		svg {
			fill: black;
		}
	}
`

const Billing = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	flex-wrap: wrap;
	width: 100%;


	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
		flex-direction: column;
	}
`

const BillingProperty = styled.div<{ preview?: boolean }>`
	display: flex;
	flex-direction: column;
	flex: 1;
	padding: ${Style.spacing.x0_5} ${Style.spacing.x1};
	padding-bottom: ${Style.spacing.x1_5};
	width: 100%;

	&:not(:last-child) {
		margin-right: ${Style.spacing.x0_5};
	}

	input {
		text-align: left;
	}

	${props => props.preview && css`
		background: transparent;
		border-color: transparent;

		${BillingPropertyValue} {
			font-weight: bold;
			padding: 6px 0;
			font-size: 16px;
		}
	`}

	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
		padding-left: 0;
		padding-right: 0;
	}


`

const BillingPropertyLabel = styled.div`
	font-weight: bold;
`

const BillingPropertyValue = styled.div`
`

interface IProps {
	index: number
	blockId: string
	item: ItemBlockType
	currency: string
	numberFormat: NumberFormat
	taxes: WorkspaceTax[]
	contactSelectionEnabled: boolean
	onChange: (item: ItemBlockType) => void
	onSettingsClick: (item: ItemBlockType) => void
	onDeleteClick: (item: ItemBlockType) => void
	onSelectionChange: (item: ItemBlockType) => void
}

const ItemBlock = (props: IProps) => {
	const { t } = useTranslation()
	const { blockId, index, currency, numberFormat, contactSelectionEnabled, item, taxes, onChange } = props
	const editor = React.useRef<Editor>(null)
	const uniqueKey = `${blockId}-${index}`
	const [title, setTitle] = React.useState(item.title)
	const debouncedTitleChange = useDebouncedCallback(
		(newTitle) => onChange({ ...item, title: newTitle }), 500
	);
	const [description, setDescription] = React.useState(item.description)
	const debouncedDescriptionChange = useDebouncedCallback(
		(newDescription) => onChange({ ...item, description: newDescription }), 500);
	const [quantity, setQuantity] = React.useState(item.quantity)
	const debouncedQuantityChange = useDebouncedCallback(
		(newQuantity) => onChange({ ...item, quantity: newQuantity }), 500);
	const [rate, setRate] = React.useState(item.amount)
	const debouncedRateChange = useDebouncedCallback(
		(newRate) => onChange({ ...item, amount: newRate }), 500);
	const [taxRate, setTaxRate] = React.useState(item.tax_rate)
	const [taxCode, setTaxCode] = React.useState(item.tax_code)
	const debouncedTaxChange = useDebouncedCallback(
		(taxRate: WorkspaceTax) => onChange({
			...item,
			tax_rate: Number(taxRate?.rate) || 0,
			tax_code: taxRate?.code || '',
		}), 500);

	React.useEffect(() => {
		setTitle(props.item.title)
		setQuantity(props.item.quantity)
	}, [props.item.title, props.item.quantity])

	const total = ContentBlockHelper.getItemSummary({
		quantity: quantity,
		amount: rate,
		tax_rate: taxRate,
	}).total

	const onDrop = React.useCallback(acceptedFiles => {
		const file = acceptedFiles[0]

		ActiveStorageController.uploadPublic(file, async (error, blob) => {
			if (error) { console.error(error) }
			if (blob) {
				const { url } = await ActiveStorageController.getBlobUrl(blob)

				props.onChange({ ...item, cover: url })
			}
		})
	}, [])

	const { getRootProps, getInputProps } = useDropzone({
		onDrop: onDrop,
		multiple: false,
		accept: [
			MimeTypes.JPG,
			MimeTypes.JPEG,
			MimeTypes.PNG,
			MimeTypes.GIF,
			MimeTypes.SVG
		],
	})

	const onSettingsClick = () => {
		props.onSettingsClick(item)
	}

	const onDeleteClick = () => {
		ReactTooltip.hide()
		props.onDeleteClick(item)
	}

	const onCoverDeleteClick = () => {
		onChange({ ...item, cover: null })
	}

	const onTitleChange = (e) => {
		const newTitle = e.target.value

		setTitle(newTitle)

		debouncedTitleChange(newTitle)
	}

	const onSelectionChange = () => {
		props.onSelectionChange(item)
	}

	const onDescriptionChange = (descriptionModel: string) => {
		setDescription(descriptionModel)

		debouncedDescriptionChange(descriptionModel)
	}

	const onQuantityChange = (e) => {
		const newQuantity = e.target.value

		setQuantity(newQuantity)

		debouncedQuantityChange(newQuantity)
	}

	const onRateChange = (rate) => {
		setRate(rate)

		debouncedRateChange(rate)
	}

	const onTaxChange = (code) => {
		const taxRate = VATHelper.getTaxRateFromCode(taxes, code)

		setTaxRate(Number(taxRate.rate))
		setTaxCode(taxRate.code)
		debouncedTaxChange(taxRate)
	}

	console.log

	return (
		<Draggable
			key={uniqueKey}
			draggableId={uniqueKey}
			index={index}
		>
			{(draggableProvided, draggableSnapshot) => (
				<Item ref={draggableProvided.innerRef} {...draggableProvided.draggableProps}>
					<ItemInfo>
						{!item.cover && <ItemCoverImage {...getRootProps()}>
							<input {...getInputProps()} />
							<Icon icon='image' />
						</ItemCoverImage>}
						{item.cover && <ItemCoverImage style={{ backgroundImage: `url(${item.cover})` }} onClick={onCoverDeleteClick}>
							<ItemCoverImageOverlay>
								<Icon icon='trash' />
							</ItemCoverImageOverlay>
						</ItemCoverImage>}
						<ItemInfoContainer>
							<ItemInfoWrapper>
								<ItemInfoTop>
									<ItemTitleInput
										type='text'
										placeholder={t('ItemsBlock::Title')}
										value={title}
										onChange={onTitleChange}
									/>

									{contactSelectionEnabled && <SelectionButton selected={item.selected} onClick={onSelectionChange}>
										{item.selected ? t('ItemsBlock::Selected') : t('ItemsBlock::Unselected')}
									</SelectionButton>}
								</ItemInfoTop>
								<ItemInfoBottom>
									<Editor
										ref={editor}
										model={description}
										onModelChange={onDescriptionChange}
										config={{
											...MINIMAL_EDITOR_CONFIG,
											toolbarInline: true,
											placeholderText: t('ItemBlock::Add a product description...')
										}}
									/>
								</ItemInfoBottom>
							</ItemInfoWrapper>
							<ItemActions>
								<ItemAction data-tip={t('ItemBlock::Reorder')} {...draggableProvided.dragHandleProps}>
									<Icon icon='grip-vertical' />
								</ItemAction>

								{/* <ItemAction onClick={onSettingsClick} data-tip={t('ItemBlock::Settings')}>
									<Icon icon='setting' />
								</ItemAction> */}

								<ItemAction onClick={onDeleteClick} data-tip={t('ItemBlock::Delete')}>
									<Icon icon='trash' />
								</ItemAction>
							</ItemActions>
						</ItemInfoContainer>
					</ItemInfo>
					<Billing>
						<BillingProperty>
							<BillingPropertyLabel>
								{t('ItemsBlock::Quantity')}
							</BillingPropertyLabel>
							<BillingPropertyValue>
								<input
									type='number'
									min={0}
									step={1}
									value={quantity}
									onChange={onQuantityChange}
								/>
							</BillingPropertyValue>
						</BillingProperty>

						<BillingProperty>
							<BillingPropertyLabel>
								{t('ItemsBlock::Price')}
							</BillingPropertyLabel>
							<BillingPropertyValue>
								<MoneyInput
									name='rate'
									currency={currency}
									numberFormat={numberFormat}
									placeholderValue={0}
									value={rate}
									onBlur={onRateChange}
								/>
							</BillingPropertyValue>
						</BillingProperty>

						<BillingProperty style={{ maxWidth: 90 }}>
							<BillingPropertyLabel>
								{t('ItemsBlock::VAT')}
							</BillingPropertyLabel>
							<BillingPropertyValue>
								<select
									name='vat'
									onChange={(e) => onTaxChange(e.currentTarget.value)}
									value={taxCode}
								>
									<option value="">-</option>
									{taxes.map(tax => (<option key={tax.name} value={tax.code}>{tax.name}</option>))}
								</select>
							</BillingPropertyValue>
						</BillingProperty>

						<BillingProperty preview>
							<BillingPropertyLabel>
								{t('ItemsBlock::Total')}
							</BillingPropertyLabel>
							<BillingPropertyValue>
								{NumberFormatter.formatCurrency(currency, numberFormat, total)}
							</BillingPropertyValue>
						</BillingProperty>
					</Billing>
				</Item>
			)}
		</Draggable>

	)
}

export default ItemBlock