import * as React from 'react'
import PowerSelect from '../../Form/PowerSelect'
import styled from 'styled-components'
import { Style } from '../../../styles'
import { WithTranslation, withTranslation } from 'react-i18next'
import ReactSelectTheme from '../../Form/ReactSelectTheme'
import { PontoFormData, PontoIntegrationConfig } from '../../../types'

type IProps = {
  config: PontoIntegrationConfig
  formData: PontoFormData
  onConfigChange: (config: PontoIntegrationConfig) => void
} & WithTranslation

interface IState { }

const Container = styled.div`
	display: flex;
	flex-direction: column;

	h1 {
		color: white;
		margin-bottom: ${Style.spacing.x2};
    font-weight: bold;
	}
	
	label {
		color: white;
	}
`

class PontoForm extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.onAccountIdChange = this.onAccountIdChange.bind(this)
  }

  onAccountIdChange(option) {
    const { config, onConfigChange } = this.props

    onConfigChange({
      ...config,
      account_id: option.value,
    })
  }

  render() {
    const { config, formData, t } = this.props
    const { accounts } = formData

    const profileOptions = accounts
    const selectedProfileOption = accounts.find(p => p.value === config.account_id)

    return (
      <Container>
        <h1 style={{ color: 'black' }}>{t('PontoForm::Settings')}</h1>
        <div className='form-item'>
          <label style={{ color: 'black' }}>
            {t('MollieForm::Choose the account where you receive payments on.')}
          </label>
          <PowerSelect
            options={profileOptions}
            value={selectedProfileOption}
            onChange={this.onAccountIdChange}
            theme={ReactSelectTheme}
          />
        </div>
      </Container>
    )
  }
}

export default withTranslation()(PontoForm)