import * as React from 'react'
import styled from 'styled-components'
import Icon, { IconTypes } from '../Icons/Icon'
import { Activity, ActivityType, CalendarEventType } from '../../types'

const Container = styled.div<{ backgroundColor: string, color: string }>`
  position: relative;
  top: 2px;
  margin-right: 20px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
  border-radius: 50%;
  background: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};

  svg {
    width: 12px;
    fill: ${(props) => props.color};
  }

  i {
    font-size: 16px;
  }

  // Invoice
  /* background: #C6F7E9;
  color: rgb(23, 184, 151); */

  // Note:


  // Add:
  // background: #FFD3BA;
  // color: #C65D21;

  /* backgroundColor = '#FFD3BA'
  color = '#C65D21' */
`

const Badge = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  font-size: 10px;
  line-height: 20px;
  border-radius: 50%;
  color: white;
  background: rgb(41, 128, 185);
  border: 1px solid white;
  position: absolute;
  bottom: -6px;
  right: -8px;
`

interface IProps {
  activity: Activity
  badge?: string
}

const ActivityIcon = (props: IProps) => {
  const { activity, badge } = props

  let icon: IconTypes = 'plus-circle'
  let backgroundColor = '#E3E3E3'
  let color = '#6b7280'

  switch (activity.type) {
    case ActivityType.CREATION:
      icon = 'plus-circle'
      backgroundColor = '#FFD3BA'
      color = '#C65D21'
      break
    case ActivityType.EMAIL:
      icon = 'email'
      backgroundColor = '#CFBCF2'
      color = '#653CAD'
      break
    case ActivityType.CALENDAR_EVENT:
      switch (activity.model?.type) {
        case CalendarEventType.CALL:
          icon = 'phone'
          break
        default:
          icon = 'calendar-day'
          break
      }
      backgroundColor = '#FACDCD'
      color = '#BA2525'
      break
    case ActivityType.TASK:
      icon = 'tasks'
      break
    case ActivityType.NOTE:
      backgroundColor = '#FCEFC7'
      color = '#E9B949'
      icon = 'file-alt'
      break
    case ActivityType.INVOICE_PAID:
      backgroundColor = '#C6F7E9'
      color = 'rgb(23, 184, 151)'
      icon = 'banknote'
      break
    case ActivityType.LEDGER_ITEM_SEEN:
      icon = 'eye'
      backgroundColor = '#FFD3BA'
      color = '#C65D21'
      break
    case ActivityType.OFFER_SIGNED:
      backgroundColor = '#C6F7E9'
      color = 'rgb(23, 184, 151)'
      icon = 'file-contract'
      break
    case ActivityType.CALL:
      icon = 'phone-plus'
      break
  }

  return (
    <Container backgroundColor={backgroundColor} color={color}>
      <Icon icon={icon} />
      {badge && <Badge>{badge}</Badge>}
    </Container>
  )
}

export default ActivityIcon