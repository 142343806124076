import * as React from "react";
import { CurrentUser, LedgerItem, LedgerItemType, PreviewAttachment, ResourceListFilterType } from "../../types";
import PageLoader from "../Page/PageLoader";
import { WithTranslation, useTranslation, withTranslation } from "react-i18next";
import ResourceTable from "../Resource/ResourceTable";
import ResourceTableRow from "../Resource/ResourceTableRow";
import ResourceTableRowData from "../Resource/ResourceTableRowData";
import moment from "../../utilities/Moment";
import { connect } from "react-redux";
import { AppState } from "../../store";
import NumberFormatter from "../../utilities/NumberFormatter";
import CardEmptyInfo from "../Card/CardEmptyInfo";
import { InvoicesController } from "../../controllers";
import ResourceTableRowDataLink from "../Resource/ResourceTableRowDataLink";
import { withRouter, RouteComponentProps } from 'react-router';
import RouteHelper from "../../helpers/RouteHelper";
import ERoute from "../../ERoute";
import { Dispatch } from "redux";
import LocalStorage, { LocalStorageKey } from "../../LocalStorage";
import { Style } from "../../styles";
import { showAttachmentsViewerModal } from "../../store/modals/actions";

interface IStateToProps {
    currentUser: CurrentUser
}

type IProps = {
    type: LedgerItemType
    ledgerItemIds: string[]
    onLinkClick: (ledgerItemIds: string[]) => void
} & RouteComponentProps & WithTranslation & IStateToProps & IDispatchToProps

interface IDispatchToProps {
    showAttachmentsViewerModal: typeof showAttachmentsViewerModal
}

interface IState {
    ledgerItems: LedgerItem[]
    currentPage: number
    totalPages: number
    filters: any
    didInitialLoad: boolean
    sortValue: string
    isFetching: boolean
    selectedLedgerItemIds: string[]
    searchValue: string
}

class LinkLedgerItems extends React.Component<IProps, IState> {
    constructor(props) {
        super(props)

        this.state = {
            ledgerItems: [],
            currentPage: 0,
            totalPages: 0,
            filters: {},
            didInitialLoad: false,
            searchValue: '',
            isFetching: false,
            selectedLedgerItemIds: [],
            sortValue: LocalStorage.get(LocalStorageKey.LEDGER_ITEM_SORT_VALUE, 'number_desc'),
        }

        this.fetchLedgerItems = this.fetchLedgerItems.bind(this)
        this.onLedgerItemClick = this.onLedgerItemClick.bind(this)
        this.onFiltersChange = this.onFiltersChange.bind(this)
        this.onSortValueChange = this.onSortValueChange.bind(this)
        this.onSelectionChange = this.onSelectionChange.bind(this)
        this.onTableRowSelectionChange = this.onTableRowSelectionChange.bind(this)
        this.onSearchChange = this.onSearchChange.bind(this)
        this.onSearchSubmit = this.onSearchSubmit.bind(this)
        this.onClearFilter = this.onClearFilter.bind(this)
        this.onLinkClick = this.onLinkClick.bind(this)
    }

    componentDidMount(): void {
        this.fetchLedgerItems(1)
    }

    async fetchLedgerItems(page: number = 1) {
        const { type } = this.props
        const { searchValue, filters, sortValue } = this.state
        try {
            let parameters = {
                type: type,
                search: searchValue,
                order: sortValue,
                ...filters
            }

            this.setState({ isFetching: true })

            const { current_page, total_pages, invoices: newLedgerItems } = await InvoicesController.getInvoices(page, parameters)

            this.setState({
                ...this.state,
                ledgerItems: newLedgerItems,
                currentPage: current_page,
                totalPages: total_pages,
                didInitialLoad: true,
                isFetching: false
            })
        } catch (ex) {
            console.error(ex)
        }
    }

    onLedgerItemClick(ledgerItem: LedgerItem) {
        const { t } = this.props
        const attachments: PreviewAttachment[] = [
            {
                id: ledgerItem.id,
                name: ledgerItem.pdf_attachment_file_name || `${ledgerItem.number}.pdf`,
                url: ledgerItem.pdf_attachment_url || 'unkown',
                file_size: ledgerItem.pdf_attachment_file_size || 0,
                content_type: ledgerItem.pdf_attachment_content_type || 'unk',
                created_at: ledgerItem.created_at
            }
        ]

        this.props.showAttachmentsViewerModal({
            attachments: attachments,
            actions: [{
                icon: 'external-link', text: t('ExpenseModal::Open in new tab'), onClick: (selectedIndex: number) => {
                    const selectedAttachment = attachments[selectedIndex]

                    if (selectedAttachment) window.open(selectedAttachment.url, "_blank");
                }
            }]
        })
    }

    onFiltersChange(filters: any) {
        this.setState({ ...this.state, filters: filters }, () => {
            this.fetchLedgerItems(1)
        })
    }

    onSortValueChange(value: string) {
        LocalStorage.set(LocalStorageKey.LEDGER_ITEM_SORT_VALUE, value)

        this.setState({ sortValue: value }, () => {
            this.fetchLedgerItems(1)
        })
    }

    onSelectionChange(selectedLedgerItemIds: string[]) {
        this.setState({ selectedLedgerItemIds: selectedLedgerItemIds })
    }

    onTableRowSelectionChange(selected: boolean, ledgerItemId: string) {
        const { selectedLedgerItemIds } = this.state

        if (selected) {
            this.setState({ selectedLedgerItemIds: [...selectedLedgerItemIds, ledgerItemId] })
        } else {
            this.setState({ selectedLedgerItemIds: selectedLedgerItemIds.filter(selectedLedgerItemId => selectedLedgerItemId !== ledgerItemId) })
        }
    }

    onSearchChange(searchValue) {
        this.setState({ ...this.state, searchValue: searchValue })
    }

    onSearchSubmit(searchValue) {
        this.setState({ ...this.state, searchValue: searchValue }, () => {
            this.fetchLedgerItems(1)
        })
    }

    onClearFilter() {
        this.setState({
            searchValue: '',
            filters: {}
        }, () => this.fetchLedgerItems(1))
    }

    onLinkClick(ledgerItemIds: string[]) {
        const { onLinkClick } = this.props

        // Clear the selected ledger item ids
        this.setState({ selectedLedgerItemIds: [] })

        // Call the parent component's onLinkClick function
        onLinkClick(ledgerItemIds)
    }

    render() {
        const { ledgerItemIds, t, currentUser, type } = this.props
        const { didInitialLoad, ledgerItems, searchValue, filters, currentPage, totalPages, isFetching, selectedLedgerItemIds, sortValue } = this.state
        const { workspace: { setting } } = currentUser

        const filtersActive = searchValue?.length > 0 || Object.keys(filters).length > 0

        return (
            <>
                {!didInitialLoad && <PageLoader />}
                {didInitialLoad && <ResourceTable
                    data={ledgerItems.filter(ledgerItem => !ledgerItemIds.includes(ledgerItem.id))}
                    headers={[
                        { title: t('LinkLedgerItems::Number') },
                        { title: t('LinkLedgerItems::Reference') },
                        { title: t('LinkLedgerItems::Issued on'), align: 'right' },
                        { title: t('LinkLedgerItems::Amount'), align: 'right' },
                        { title: '', align: 'right' },
                    ]}
                    renderRow={(ledgerItem: LedgerItem) => {
                        return (
                            <ResourceTableRow
                                key={ledgerItem.id}
                                selected={selectedLedgerItemIds.includes(ledgerItem.id)}
                                onSelectionChange={(selected) => this.onTableRowSelectionChange(selected, ledgerItem.id)}
                            >
                                <ResourceTableRowData maxWidth='150px' ellipse onClick={() => this.onLedgerItemClick(ledgerItem)}>
                                    {ledgerItem.number}
                                </ResourceTableRowData>
                                <ResourceTableRowData maxWidth='150px' ellipse onClick={() => this.onLedgerItemClick(ledgerItem)}>
                                    {ledgerItem?.identifier}
                                </ResourceTableRowData>
                                <ResourceTableRowData textAlign='right' onClick={() => this.onLedgerItemClick(ledgerItem)}>
                                    {ledgerItem?.issued_on ? moment(ledgerItem.issued_on).format(setting.date_format) : '-'}
                                </ResourceTableRowData>
                                <ResourceTableRowData
                                    textAlign='right'
                                    onClick={() => this.onLedgerItemClick(ledgerItem)}
                                    style={{
                                        fontWeight: 'bold',
                                        color: type === LedgerItemType.INVOICE ? Style.color.brandSuccess : Style.color.brandDanger
                                    }}
                                >
                                    {type === LedgerItemType.INVOICE && NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, ledgerItem.amount)}
                                    {type === LedgerItemType.CREDIT_NOTE && NumberFormatter.formatCurrency(setting.default_currency, setting.number_format, -ledgerItem.amount)}
                                </ResourceTableRowData>
                                <ResourceTableRowData textAlign='right' maxWidth='150px' ellipse onClick={() => this.onLinkClick([ledgerItem.id])}>
                                    {!ledgerItemIds.includes(ledgerItem.id) && <ResourceTableRowDataLink>
                                        {t('LinkLedgerItems::Link')}
                                    </ResourceTableRowDataLink>}
                                </ResourceTableRowData>
                            </ResourceTableRow>
                        )
                    }}
                    renderEmpty={<CardEmptyInfo
                        icon={filtersActive ? 'search' : 'invoice'}
                        description={t('LinkLedgerItems::No invoices found')}
                        descriptionActionText={filtersActive ? t('LinkLedgerItems::Clear filters') : null}
                        onDescriptionActionClick={filtersActive ? this.onClearFilter : null}
                    />}
                    filters={[
                        { name: 'contact_id', label: t('LinkLedgerItems::Contact'), type: ResourceListFilterType.SINGLE_RESOURCE, resourceType: 'contact', isValidNewOption: () => false },
                        { name: 'project_id', label: t('LinkLedgerItems::Project'), type: ResourceListFilterType.SINGLE_RESOURCE, resourceType: 'project', isValidNewOption: () => false },
                        { name: 'number', label: t('LinkLedgerItems::Number'), type: ResourceListFilterType.STRING },
                        { name: 'identifier', label: t('LinkLedgerItems::Identifier'), type: ResourceListFilterType.STRING },
                        { name: 'amount', label: t('LinkLedgerItems::Amount'), type: ResourceListFilterType.NUMBER },
                        { name: 'issued_on', label: t('LinkLedgerItems::Issued on'), type: ResourceListFilterType.DATE },
                        { name: 'due_on', label: t('LinkLedgerItems::Due on'), type: ResourceListFilterType.DATE },
                        { name: 'created_at', label: t('LinkLedgerItems::Created date'), type: ResourceListFilterType.DATE },
                    ]}
                    onFiltersChange={this.onFiltersChange}
                    sortOptions={[
                        { label: t('LedgerItemsTable::Number ↑'), value: 'number_asc' },
                        { label: t('LedgerItemsTable::Number ↓'), value: 'number_desc' },
                        { label: t('LedgerItemsTable::Issued on ↑'), value: 'issued_on_asc' },
                        { label: t('LedgerItemsTable::Issued on ↓'), value: 'issued_on_desc' },
                        { label: t('LedgerItemsTable::Amount ↑'), value: 'amount_asc' },
                        { label: t('LedgerItemsTable::Amount ↓'), value: 'amount_desc' },
                    ]}
                    sortValue={sortValue}
                    onSortChange={this.onSortValueChange}
                    promotedBulkActions={[
                        { icon: 'link', content: t('LinkLedgerItems::Link to transaction'), onAction: () => this.onLinkClick(selectedLedgerItemIds) }
                    ]}
                    pagination={{
                        page: currentPage,
                        pageCount: totalPages
                    }}
                    onPageChange={(page) => this.fetchLedgerItems(page)}
                    isLoading={isFetching}
                    stickyHeader={true}
                    selectedItems={selectedLedgerItemIds}
                    onSelectionChange={this.onSelectionChange}
                    searchValue={searchValue}
                    onSearchChange={this.onSearchChange}
                    onSearchSubmit={this.onSearchSubmit}
                    maxHeight='25vh'
                />}
            </>
        )
    }
}

const mapStateToProps = (state: AppState): IStateToProps => {
    const {
        authentication: {
            currentUser,
        }
    } = state

    return {
        currentUser: currentUser,
    }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
    return {
        showAttachmentsViewerModal: (options) => dispatch(showAttachmentsViewerModal(options)),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LinkLedgerItems)))
