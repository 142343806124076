import * as React from 'react'
import { connect, useSelector } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import { useTranslation } from 'react-i18next';
import { showConfirmModal } from '../../store/modals/actions';
import { AppState } from '../../store';
import LocalStorage, { LocalStorageKey } from '../../LocalStorage';
import { FormsController } from '../../controllers';
import RouteHelper from '../../helpers/RouteHelper';
import ERoute from '../../ERoute';
import Notification from '../../utilities/Notification';
import { IActionListItem } from '../ActionList/ActionList';
import PageLoader from '../Page/PageLoader';
import ResourceTable from '../Resource/ResourceTable';
import ResourceTableRow from '../Resource/ResourceTableRow';
import ResourceTableRowData from '../Resource/ResourceTableRowData';
import ResourceTableRowActions from '../Resource/ResourceTableRowActions';
import CardEmptyInfo from '../Card/CardEmptyInfo';
import { CurrentUser, Form, FormStatus, ResourceListFilterType } from '../../types';
import { useDebouncedCallback } from 'use-debounce';
import Switch from '../Form/Switch';

interface IState {
	forms: Form[],
	currentPage: number,
	totalPages: number
	didInitialLoad: boolean
	isFetching: boolean
	sortValue: string
	filters: any
	searchValue: string
}


interface IStateToProps {
	currentUser: CurrentUser
}

interface IDispatchToProps {
	showConfirmModal: typeof showConfirmModal
}

type IProps = { contactId?: string, projectId?: string } & IStateToProps & IDispatchToProps & RouteComponentProps<any>

const FormsTable = (props: IProps) => {
	const { showConfirmModal } = props
	const { t } = useTranslation()
	const currentUser = useSelector((state: AppState) => state.authentication.currentUser)
	const { workspace } = currentUser

	const [state, setState] = React.useState<IState>({
		forms: [],
		currentPage: 0,
		totalPages: 0,
		didInitialLoad: false,
		isFetching: false,
		sortValue: LocalStorage.get(LocalStorageKey.PROPOSAL_SORT_VALUE, 'created_at_desc'),
		filters: {},
		searchValue: '',
	})

	const {
		forms,
		currentPage,
		totalPages,
		didInitialLoad,
		filters,
		isFetching,
		sortValue,
		searchValue,
	} = state

	const filtersActive = searchValue?.length > 0 || Object.keys(filters).length > 0

	React.useEffect(() => {
		debouncedFetchForms(1)
	}, [filters, sortValue, searchValue])

	const fetchForms = async (page: number) => {
		try {
			let params: any = {
				page: page,
				search: searchValue,
				order: `${sortValue}`,
				...filters
			}

			if (props.contactId) params = { ...params, contact_id: props.contactId }
			if (props.projectId) params = { ...params, project_id: props.projectId }

			setState({ ...state, isFetching: true })
			const response = await FormsController.getForms(params)

			const { forms: responseForms, current_page, total_pages, total_entries } = response;

			setState({
				...state,
				forms: [...responseForms],
				currentPage: current_page,
				totalPages: total_pages,
				didInitialLoad: true,
				isFetching: false
			})
		} catch (ex) {
			console.error(ex)
		}
	}

	const debouncedFetchForms = useDebouncedCallback((page) => fetchForms(page), 250);

	const onNewFormClick = async () => {
		props.history.push(RouteHelper.process(ERoute.PATH_FORMS_CREATE))
	}

	const onFiltersChange = (newFilters: any) => {
		setState({ ...state, filters: newFilters })
	}

	const onSortChange = (newSortValue: string) => {
		LocalStorage.set(LocalStorageKey.CONTRACT_SORT_VALUE, newSortValue)
		setState({ ...state, sortValue: newSortValue })
	}

	const onRowClick = (form: Form) => {
		props.history.push(RouteHelper.process(ERoute.PATH_FORM, { id: form.id }))
	}

	const onRowActionClick = (key: string, form: Form) => {
		switch (key) {
			case 'edit':
				props.history.push(RouteHelper.process(ERoute.PATH_FORM, { id: form.id }))
				break
			case 'duplicate':
				onDuplicateClick(form)
				break
			case 'delete':
				showConfirmModal({
					title: t('Form::Delete form'),
					description: t('Form::You are about to delete form <b>{{name}}</b>. Are you sure?', { name: form.name }),
					action: { label: t('Form::Delete'), isDestructive: true },
					onConfirm: async () => {
						try {
							const response = await FormsController.delete(form.id)

							if (response.errors) { }
							else {
								const formIndex = forms.findIndex(c => c.id === form.id);

								forms.splice(formIndex, 1);

								setState({ ...state, forms: [...forms] })

								Notification.notifySuccess(t('Form::Form successfully deleted'))
							}
						} catch (ex) {
							console.error(ex)
						}
					}
				})
		}
	}

	const onDuplicateClick = async (form: Form) => {
		try {
			const response = await FormsController.duplicate(form.id)
			const duplicatedForm = response

			props.history.push(RouteHelper.process(ERoute.PATH_FORM, { id: duplicatedForm.id }))
		} catch (ex) {
			console.error(ex)
		}
	}

	const onSearchChange = (searchValue) => {
		setState({ ...state, searchValue: searchValue })
	}

	const onSearchSubmit = (searchValue) => {
		setState({ ...state, searchValue: searchValue })
	}

	const onClearFilters = () => {
		setState({ ...state, searchValue: '', filters: {} })
	}


	return (
		<>
			{!didInitialLoad && <PageLoader />}
			{didInitialLoad && <ResourceTable
				style={{ marginBottom: 75 }}
				data={forms}
				headers={[
					{ title: t('Forms::Name'), colSpan: 2 },
					{ title: t('Forms::Enabled') },
					{ title: t('Forms::Activation date') },
					{ title: t('Forms::Expiration date'), align: 'right' },
					{ title: t('Forms::Submission limit'), align: 'right' },
					{ title: t('Forms::Responses count'), align: 'right' },
					{ title: '', stickyRight: '0px' },
				]}
				renderRow={(form: Form) => {
					let actions: IActionListItem[] = [
						{ key: 'duplicate', icon: 'duplicate', content: t('Forms::Duplicate') },
						{ key: 'edit', icon: 'edit-solid', content: t('Forms::Edit') },
						{ key: 'delete', icon: 'trash-alt-solid', content: t('Forms::Delete'), destructive: true },
					]

					return (
						<ResourceTableRow key={form.id}>
							<ResourceTableRowData onClick={(): void => onRowClick(form)} colSpan={2} maxWidth='150px' ellipse>
								<b>{form.name ? form.name : '-'}</b>
							</ResourceTableRowData>
							<ResourceTableRowData onClick={(): void => onRowClick(form)}>
								<Switch
									name='enabled'
									onClick={() => { }}
									checked={form.enabled}
								/>
							</ResourceTableRowData>
							<ResourceTableRowData onClick={(): void => onRowClick(form)}>
								{form.activation_date ? form.activation_date : '-'}
							</ResourceTableRowData>
							<ResourceTableRowData onClick={(): void => onRowClick(form)} textAlign='right'>
								{form.expiration_date ? form.expiration_date : '-'}
							</ResourceTableRowData>
							<ResourceTableRowData onClick={(): void => onRowClick(form)} textAlign='right'>
								{form.response_limit ? form.response_limit : '-'}
							</ResourceTableRowData>
							<ResourceTableRowData onClick={(): void => onRowClick(form)} textAlign='right'>
								{form.responses_count}
							</ResourceTableRowData>
							<ResourceTableRowActions
								actions={actions}
								onActionClick={(key) => onRowActionClick(key, form)}
								sticky={true}
								stickyRight='0px'
							/>
						</ResourceTableRow>
					)
				}}
				renderEmpty={<CardEmptyInfo
					icon={filtersActive ? 'search' : 'input-pipe'}
					description={filtersActive ? t('Forms::No forms found') : t('Forms::No forms have been created yet')}
					descriptionActionText={filtersActive ? t('Forms::Clear filters') : t('Forms::Add form')}
					onDescriptionActionClick={filtersActive ? onClearFilters : onNewFormClick}
				/>}
				filters={[
					{ name: 'name', label: t('Forms::Name'), type: ResourceListFilterType.STRING },
					{
						name: 'status[in]',
						label: t('Forms::Status'), type: ResourceListFilterType.MULTIPLE_OPTION,
						options: [
							{ label: t(`FormStatus::${FormStatus.DRAFT}`), value: FormStatus.DRAFT },
							{ label: t(`FormStatus::${FormStatus.PUBLISHED}`), value: FormStatus.PUBLISHED },
							{ label: t(`FormStatus::${FormStatus.ARCHIVED}`), value: FormStatus.ARCHIVED },
						]
					},
					{ name: 'activation_date', label: t('Forms::Activation date'), type: ResourceListFilterType.DATE },
					{ name: 'expiration_date', label: t('Forms::Expiration date'), type: ResourceListFilterType.DATE },
					{ name: 'response_limit', label: t('Forms::Submission limit'), type: ResourceListFilterType.NUMBER },
					{ name: 'form_response_count', label: t('Forms::Responses'), type: ResourceListFilterType.NUMBER },
					{ name: 'created_at', label: t('Forms::Created date'), type: ResourceListFilterType.DATE },
				]}
				onFiltersChange={onFiltersChange}
				sortOptions={[
					{ label: '-', value: '-' },
					{ label: t('Forms::Name (A-Z)'), value: 'name_asc' },
					{ label: t('Forms::Name (Z-A)'), value: 'name_desc' },
					{ label: t('Forms::Activation date ↑'), value: 'activation_date_asc' },
					{ label: t('Forms::Activation date ↓'), value: 'activation_date_desc' },
					{ label: t('Forms::Expiration date ↑'), value: 'expiration_date_asc' },
					{ label: t('Forms::Expiration date ↓'), value: 'expiration_date_desc' },
					{ label: t('Forms::Created at ↑'), value: 'created_at_asc' },
					{ label: t('Forms::Created at ↓'), value: 'created_at_desc' },
				]}
				sortValue={sortValue}
				onSortChange={onSortChange}
				pagination={{ page: currentPage, pageCount: totalPages }}
				onPageChange={(page) => fetchForms(page)}
				isLoading={isFetching}
				stickyHeader={true}
				searchValue={searchValue}
				onSearchChange={onSearchChange}
				onSearchSubmit={onSearchSubmit}
				maxHeight='65vh'
			/>}
		</>
	)
}

const mapStateToProps = (state: AppState): IStateToProps => {
	const {
		authentication: {
			currentUser,
		}
	} = state

	return {
		currentUser: currentUser,
	}
}

const mapDispatchToProps: IDispatchToProps = {
	showConfirmModal,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FormsTable))