import React, { forwardRef } from 'react'
import styled, { css } from 'styled-components'
import { RouteComponentProps } from 'react-router'
import ActivityItem, { ActivityAction } from './ActivityItem';
import { Activity, ActivityTrackableType, ActivityType, CalendarEventType, CurrentUser } from '../../types';
import { ActivitiesController } from '../../controllers';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { AppState } from '../../store';
import { showCalendarEventModal, showCallModal, showConfirmModal, showEmailViewerModal, showTaskModal } from '../../store/modals/actions';
import Notification from '../../utilities/Notification';
import ActivityHelper from '../../helpers/ActivityHelper';
import moment from '../../utilities/Moment';
import Button from '../Button/Button';
import { Style } from '../../styles';
import Utils from '../../utilities/Utils';
import LedgerItemHelper from '../../helpers/LedgerItemHelper';
import RouteHelper from '../../helpers/RouteHelper';
import ERoute from '../../ERoute';
import CallsController from '../../controllers/CallsController';
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';

const Container = styled.div``

const NoteContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-color: ${Style.color.border};
  padding-bottom: 20px;
  background-color: white;
  z-index: 1;

  &:last-child {
    border-bottom: none;
  }
`

const NoteActions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${Style.spacing.x1};
`

const ActivityItems = styled.div<{ maxHeight?: string }>`
  position: relative;
	display: flex;
	flex-direction: column;
	overflow-y: auto;
	overflow-x: hidden;
  padding-top: ${Style.spacing.x2_5};
  flex: 1;

  ${props => props.maxHeight && css`
    max-height: ${props.maxHeight};
  `}
`

interface IStateToProps {
  currentUser: CurrentUser
}

interface IDispatchToProps {
  showEmailViewerModal: typeof showEmailViewerModal
  showConfirmModal: typeof showConfirmModal
  showCalendarEventModal: typeof showCalendarEventModal
  showTaskModal: typeof showTaskModal
  showCallModal: typeof showCallModal
}

type IProps = {
  trackableId: string
  trackableType: ActivityTrackableType;
  maxHeight?: string
  history: RouteComponentProps['history']
} & IStateToProps & IDispatchToProps & WithTranslation

interface IState {
  note: string
  activities: Activity[];
  currentPage: number,
  totalPages: number
  didInitialLoad: boolean
  isFetching: boolean
  endReached: boolean
}

class ActivityFeed extends React.Component<IProps, IState> {
  private scrollContainer = React.createRef<HTMLDivElement>()

  constructor(props: IProps) {
    super(props)

    this.state = {
      note: '',
      activities: [],
      currentPage: 0,
      totalPages: 0,
      didInitialLoad: false,
      isFetching: false,
      endReached: false
    }

    this.fetchActivities = this.fetchActivities.bind(this)
    this.refetch = this.refetch.bind(this)
    this.onScrollChange = Utils.debounce(this.onScrollChange.bind(this), 500, false)
    this.onNoteChange = this.onNoteChange.bind(this)
    this.onNoteSubmit = this.onNoteSubmit.bind(this)
    this.getBadge = this.getBadge.bind(this)
    this.getTimestamp = this.getTimestamp.bind(this)
    this.getDescription = this.getDescription.bind(this)
    this.getPreview = this.getPreview.bind(this)
    this.getMainAction = this.getMainAction.bind(this)
    this.getActions = this.getActions.bind(this)
    this.onViewEmailClick = this.onViewEmailClick.bind(this)
    this.onDeleteEmailClick = this.onDeleteEmailClick.bind(this)
    this.onDeleteNoteClick = this.onDeleteNoteClick.bind(this)
    this.onDeleteCallClick = this.onDeleteCallClick.bind(this)
    this.onViewEventClick = this.onViewEventClick.bind(this)
    this.onViewTaskClick = this.onViewTaskClick.bind(this)
    this.onViewCallClick = this.onViewCallClick.bind(this)
  }

  componentDidMount(): void {
    this.fetchActivities(1)

    if (this.scrollContainer?.current) {
      this.scrollContainer.current.addEventListener('scroll', this.onScrollChange)
    }
  }

  componentWillUnmount(): void {
    if (this.scrollContainer?.current) {
      this.scrollContainer.current.removeEventListener('scroll', this.onScrollChange)
    }
  }

  refetch() {
    this.fetchActivities(1)
  }

  onScrollChange(event) {
    const { currentPage, endReached } = this.state
    const node = event.target;
    const scrollListEndReached = node.scrollHeight - node.scrollTop <= node.clientHeight;

    if (scrollListEndReached && !endReached) {
      this.fetchActivities(currentPage + 1)
    }
  }

  async fetchActivities(page: number): Promise<void> {
    const { trackableId, trackableType } = this.props
    const { activities: stateActivities } = this.state
    try {
      const { activities, current_page, total_pages } = await ActivitiesController.getActivities({
        page: page,
        trackable_id: trackableId,
        trackable_type: trackableType
      })

      this.setState({
        activities: page === 1 ? [...activities] : [...stateActivities, ...activities],
        currentPage: current_page,
        totalPages: total_pages,
        endReached: current_page === total_pages,
        didInitialLoad: true,
        isFetching: false,
      })
    } catch (ex) {
      console.error(ex)
    }
  }

  onNoteChange(e) {
    e.preventDefault()
    const { note } = this.state
    this.setState({ note: e.currentTarget.value })
  }

  async onNoteSubmit() {
    const { trackableId, trackableType, t } = this.props
    const { activities, note } = this.state

    try {
      const response = await ActivitiesController.create({
        type: ActivityType.NOTE,
        trackable_type: trackableType,
        trackable_id: trackableId,
        owner_type: 'User',
        owner_id: this.props.currentUser.id,
        data: { note: note }
      })

      if (response.errors) {

      } else {
        this.setState({ note: '', activities: [response, ...activities] })

        Notification.notifySuccess(t('ActivityFeed::Note successfully added'))
      }
    } catch (ex) {
      console.error(ex)
    }
  }

  getBadge(activity: Activity) {
    switch (activity.type) {
      case ActivityType.EMAIL:
      case ActivityType.NOTE:
        return ActivityHelper.getName('owner', activity)[0].toUpperCase()
      default: null
    }
  }


  getTimestamp(activity: Activity) {
    switch (activity.type) {
      case ActivityType.CALENDAR_EVENT:
        const calendarEvent = activity?.model

        if (calendarEvent) {
          if (calendarEvent.all_day) {
            const startMoment = moment(calendarEvent.start)
            const endMoment = moment(calendarEvent.end)

            const differenceInDays = endMoment.diff(startMoment, 'days')

            if (differenceInDays > 0) return `${moment(calendarEvent.start).format('MMM D')} - ${moment(calendarEvent.end).format('MMM D')}`
            return `${moment(calendarEvent.start).format('MMM D')}`
          } else {
            return `${moment(calendarEvent.start).format('h:mm a')} - ${moment(calendarEvent.end).format('h:mm a')}`
          }
        }
        return null
      default: return null
    }
  }

  getDescription(activity: Activity) {
    const { t, currentUser } = this.props

    switch (activity.type) {
      case ActivityType.CREATION:
        return t('ActivityFeed::{{modelName}} was added to the CRM', { modelName: activity?.model?.name })
      case ActivityType.EMAIL:
        const senderName = ActivityHelper.getName('owner', activity)
        const recipientName = ActivityHelper.getName('recipient', activity)

        // A user send an email to a contact
        if (activity?.owner_type === 'User') {
          if (activity?.owner?.id === currentUser.id) {
            return t('ActivityFeed::You sent {{recipient}} an email', { recipient: recipientName })
          } else {
            return t('ActivityFeed::{{sender}} sent {{recipient}} an email', { sender: senderName, recipient: recipientName })
          }
        } else { // The contact send an email to us
          if (activity?.recipient?.id === currentUser.id) {
            return t('ActivityFeed::{{sender}} sent an email to you', { sender: senderName })
          } else {
            return t('ActivityFeed::{{sender}} sent an email to {{recipient}}', { sender: senderName, recipient: recipientName })
          }
        }
      case ActivityType.CALENDAR_EVENT:
        if (activity?.model?.type === CalendarEventType.CALL) {
          return t('ActivityFeed::Call')
        }
        return t('ActivityFeed::Event')
      case ActivityType.TASK:
        return t('ActivityFeed::Task')
      case ActivityType.NOTE:
        if (activity?.owner?.id === currentUser.id) {
          return t('ActivityFeed::You added a note')
        } else {
          return t('ActivityFeed::{{owner}} added a note', { owner: ActivityHelper.getName('owner', activity) })
        }
      case ActivityType.INVOICE_PAID:
        return t('ActivityFeed::{{owner}} paid invoice {{number}}', {
          owner: ActivityHelper.getName('owner', activity),
          number: activity?.model?.number,
        })
      case ActivityType.LEDGER_ITEM_SEEN:
        return t('ActivityFeed::{{owner}} opened {{ledgerItemType}} {{number}}', {
          owner: ActivityHelper.getName('owner', activity),
          ledgerItemType: LedgerItemHelper.getTypeLabel(activity?.model?.type).toLowerCase(),
          number: activity?.model?.number
        })
      case ActivityType.OFFER_SIGNED:
        return t('ActivityFeed::{{owner}} signed offer {{number}}', {
          owner: ActivityHelper.getName('owner', activity),
          ledgerItemType: LedgerItemHelper.getTypeLabel(activity?.model?.type).toLowerCase(),
          number: activity?.model?.number
        })
      case ActivityType.CALL:
        return t('ActivityFeed::{{owner}} logged a call to {{recipient}}', {
          owner: ActivityHelper.getName('owner', activity),
          recipient: ActivityHelper.getName('recipient', activity)
        })

      default:
        throw Error(`No description for activity type`)
    }
  }

  getPreview(activity: Activity) {
    const { t } = this.props

    switch (activity.type) {
      case ActivityType.EMAIL:
        return activity?.model?.subject || t('ActivityFeed::No subject')
      case ActivityType.CALENDAR_EVENT:
        return activity?.model?.title || t('ActivityFeed::Untitled event')
      case ActivityType.TASK:
        return activity?.model?.name || t('ActivityFeed::Untitled task')
      case ActivityType.NOTE:
        return activity?.data?.note || t('ActivityFeed::Empty note')
      case ActivityType.INVOICE_PAID:
        return activity?.model.number
      case ActivityType.LEDGER_ITEM_SEEN:
        return activity?.model.number
      case ActivityType.OFFER_SIGNED:
        return activity?.model.number
      case ActivityType.CALL:
        return activity?.model?.notes ? <FroalaEditorView model={activity?.model?.notes} /> : t('ActivityFeed::Empty notes')
      default:
        return null
    }
  }

  getMainAction(activity: Activity): ActivityAction {
    const { t } = this.props

    switch (activity.type) {
      case ActivityType.CREATION: return null
      case ActivityType.EMAIL:
        return { label: t('ActivityFeed::View email'), onClick: () => this.onViewEmailClick(activity) }
      case ActivityType.CALENDAR_EVENT:
        return { label: t('ActivityFeed::View event'), onClick: () => this.onViewEventClick(activity) }
      case ActivityType.TASK:
        return { label: t('ActivityFeed::View task'), onClick: () => this.onViewTaskClick(activity) }
      case ActivityType.NOTE: return null
      case ActivityType.INVOICE_PAID:
        return { label: t('ActivityFeed::View invoice'), onClick: () => this.props.history.push(RouteHelper.process(ERoute.PATH_INVOICE, { id: activity?.model?.id })) }
      case ActivityType.LEDGER_ITEM_SEEN:
        return { label: t('ActivityFeed::View {{ledgerItemType}}', { ledgerItemType: activity?.model?.type.toLowerCase() }), onClick: () => this.props.history.push(RouteHelper.process(ERoute.PATH_INVOICE, { id: activity?.model?.id })) }
      case ActivityType.OFFER_SIGNED:
        return { label: t('ActivityFeed::View offer'), onClick: () => this.props.history.push(RouteHelper.process(ERoute.PATH_INVOICE, { id: activity?.model?.id })) }
      case ActivityType.CALL:
        return { label: t('ActivityFeed::View call'), onClick: () => this.onViewCallClick(activity) }
      default:
        throw Error(`No main action for activity type`)
    }
  }

  getActions(activity: Activity): ActivityAction[] {
    const { t } = this.props
    switch (activity.type) {
      case ActivityType.CREATION: return []
      case ActivityType.EMAIL:
        return [
          { label: t('ActivityFeed::Delete email'), onClick: () => this.onDeleteEmailClick(activity) }
        ]
      case ActivityType.CALENDAR_EVENT: return []
      case ActivityType.TASK: return []
      case ActivityType.NOTE: return [
        { label: t('ActivityFeed::Delete note'), onClick: () => this.onDeleteNoteClick(activity) }
      ]
      case ActivityType.INVOICE_PAID: return []
      case ActivityType.LEDGER_ITEM_SEEN: return []
      case ActivityType.OFFER_SIGNED: return []
      case ActivityType.CALL: return [
        { label: t('ActivityFeed::Delete call'), onClick: () => this.onDeleteCallClick(activity) }
      ]
      default:
        throw Error(`No additional actions for activity type`)
    }
  }

  onViewEmailClick(activity: Activity) {
    if (activity.type === ActivityType.EMAIL) {
      this.props.showEmailViewerModal({
        email: { id: activity.model?.id }
      })
    }
  }

  onDeleteEmailClick(activity: Activity) {
    const { t, showConfirmModal } = this.props

    showConfirmModal({
      title: t('ActivityFeed::Delete email'),
      description: t('ActivityFeed::You are about to delete this email. Are you sure?'),
      action: { label: t('ActivityFeed::Delete'), isDestructive: true },
      onConfirm: async () => {
        try {
          const response = await ActivitiesController.delete(activity.id)

          if (response.success) {
            Notification.notifySuccess(t('ActivityFeed::Email successfully deleted'))
            this.setState({ activities: this.state.activities.filter(a => a.id !== activity.id) })
          }
        } catch (ex) {
          console.error(ex)
        }
      }
    })
  }

  onDeleteNoteClick(activity: Activity) {
    const { t, showConfirmModal } = this.props

    showConfirmModal({
      title: t('ActivityFeed::Delete note'),
      description: t('ActivityFeed::You are about to delete this note. Are you sure?'),
      action: { label: t('ActivityFeed::Delete'), isDestructive: true },
      onConfirm: async () => {
        try {
          const response = await ActivitiesController.delete(activity.id)

          if (response.success) {
            Notification.notifySuccess(t('ActivityFeed::Note successfully deleted'))
            this.setState({ activities: this.state.activities.filter(a => a.id !== activity.id) })
          }
        } catch (ex) {
          console.error(ex)
        }
      }
    })
  }

  onDeleteCallClick(activity: Activity) {
    const { t, showConfirmModal } = this.props

    showConfirmModal({
      title: t('ActivityFeed::Delete call'),
      description: t('ActivityFeed::You are about to delete this call. Are you sure?'),
      action: { label: t('ActivityFeed::Delete'), isDestructive: true },
      onConfirm: async () => {
        try {
          const response = await CallsController.delete(activity.model_id)

          if (response.success) {
            Notification.notifySuccess(t('ActivityFeed::Call successfully deleted'))
            this.setState({ activities: this.state.activities.filter(a => a.id !== activity.id) })
          }
        } catch (ex) {
          console.error(ex)
        }
      }
    })
  }



  onViewEventClick(activity: Activity) {
    if (activity.type === ActivityType.CALENDAR_EVENT) {
      this.props.showCalendarEventModal({
        calendarEvent: {
          id: activity.model?.id
        },
        onSubmit: () => {
          this.fetchActivities(1)
        },
        onDelete: () => {
          this.setState({ activities: this.state.activities.filter(a => a.id !== activity.id) })
        }
      })
    }
  }

  onViewTaskClick(activity: Activity) {
    if (activity.type === ActivityType.TASK) {
      this.props.showTaskModal({
        task: { id: activity?.model?.id },
        onSubmit: () => {
          this.fetchActivities(1)
        },
        onDelete: () => {
          this.setState({ activities: this.state.activities.filter(a => a.id !== activity.id) })
        }
      })
    }
  }

  onViewCallClick(activity: Activity) {
    if (activity.type === ActivityType.CALL) {
      this.props.showCallModal({
        call: { id: activity?.model?.id },
        onSubmit: () => {
          this.fetchActivities(1)
        },
      })
    }
  }

  render() {
    const { t, maxHeight } = this.props
    const { note, activities, endReached } = this.state
    return (
      <Container>
        <NoteContainer>
          <textarea
            placeholder={t('ActivityFeed::Add a note')}
            onChange={this.onNoteChange}
            value={note}
          />
          <NoteActions>
            <Button
              type='success'
              text={t('ActivityFeed::Save note')}
              onClick={this.onNoteSubmit}
            />
          </NoteActions>
        </NoteContainer>

        <ActivityItems ref={this.scrollContainer} maxHeight={maxHeight || '350px'}>
          {activities.map((activity) => {
            return (
              <ActivityItem
                key={activity.id}
                badge={this.getBadge(activity)}
                timestamp={this.getTimestamp(activity)}
                description={this.getDescription(activity)}
                preview={this.getPreview(activity)}
                mainAction={this.getMainAction(activity)}
                actions={this.getActions(activity)}
                activity={activity}
              />
            )
          })}
        </ActivityItems>
      </Container>

    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    }
  } = state

  return {
    currentUser: currentUser,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    showEmailViewerModal: (options) => dispatch(showEmailViewerModal(options)),
    showConfirmModal: (options) => dispatch(showConfirmModal(options)),
    showCalendarEventModal: (options) => dispatch(showCalendarEventModal(options)),
    showTaskModal: (options) => dispatch(showTaskModal(options)),
    showCallModal: (options) => dispatch(showCallModal(options)),
  }
}


export { ActivityFeed }

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(withTranslation(null, { withRef: true })(ActivityFeed))
