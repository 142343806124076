import * as React from 'react'
import { withRouter, RouteComponentProps } from 'react-router';
import styled, { css } from 'styled-components'
import { Style } from '../../styles'
import Calendar from '../Calendar/Calendar'
import moment from '../../utilities/Moment'
import { NavigateButton } from '../Calendar/CalendarToolbar'
import FlyoutHeader from '../Flyout/FlyoutHeader'
import { useTranslation } from 'react-i18next'
import ERoute from '../../ERoute';
import { CalendarEvent, CalendarViewEvent, CalendarViewEventType } from '../../types';
import { Moment } from 'moment';
import { CalendarController } from '../../controllers';
import SidebarFlyoutContainer from './SidebarFlyoutContainer';

const Wrapper = styled.div`
  position: relative;
  width: 300px;
  min-width: 300px;
  min-height: 620px;
  height: 620px;
  background-color: white;
  border-radius: 10px;
  border: 1px solid ${Style.color.border};
  box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.25);
  overflow-x: hidden;
  overflow-y: auto;
`

const HeaderContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 8;
  background-color: rgba(238,238,238,.8);
  border-bottom: 1px solid ${Style.color.border};
`

const CalendarHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 20px;
`

const NavigateButtonLeft = styled(NavigateButton)`
  left: 10px;
  transform: translateY(-50%) rotate(180deg);
`

const CalendarDay = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  span {
    &:first-child {
      font-size: 14px;
      font-weight: 400;
      text-transform: uppercase
    }

    &:last-child {
      font-size: 17px;
      font-weight: 800;
    }
  }
`

const NavigateButtonRight = styled(NavigateButton)`
  right: 10px;
`

type IProps = {
  active: boolean
  onClose: () => void
} & RouteComponentProps

interface IState {
  events: CalendarViewEvent[]
  date: Moment

}

const SidebarCalendar = (props: IProps) => {
  const { t } = useTranslation()
  const { active, history, onClose } = props

  const [state, setState] = React.useState<IState>({
    events: [],
    date: moment()
  })
  const { events, date } = state

  React.useEffect(() => {
    if (active) {
      fetchEvents()
    }
  }, [date, active])

  const fetchEvents = async () => {
    const start = moment(date).startOf('day')
    const end = moment(date).endOf('day')

    try {
      const { events } = await CalendarController.getEvents({
        start: start.toISOString(),
        end: end.toISOString()
      })

      setState({
        ...state,
        events: events.map(calendarEvent => ({
          ...calendarEvent,
          start: new Date(calendarEvent.start),
          end: new Date(calendarEvent.end),
        }))
      })
    } catch (ex) {
      console.error(ex)
    }
  }

  const onNavigateToCalendarClick = () => {
    history.push(ERoute.PATH_CALENDAR)
    onClose()
  }

  const onNavigatePrevious = () => {
    setState({ ...state, date: moment(date).subtract(1, 'day') })
  }

  const onNavigateNext = () => {
    setState({ ...state, date: moment(date).add(1, 'day') })
  }

  const onCalendarEventSubmit = (calendarEvent: CalendarEvent) => {
    const calendarEventIndex = events.findIndex(event => event.type === CalendarViewEventType.CALENDAR_EVENT && event.resource.id === calendarEvent.id)

    if (calendarEventIndex !== -1) {
      const currentCalendarEvent = events[calendarEventIndex]

      events[calendarEventIndex] = {
        ...currentCalendarEvent,
        title: calendarEvent.title,
        start: moment(calendarEvent.start).toDate(),
        end: moment(calendarEvent.end).toDate(),
        all_day: calendarEvent.all_day,
        type: CalendarViewEventType.CALENDAR_EVENT,
        resource: calendarEvent,
      }

      setState({ ...state, events: [...events] })
    } else {
      setState({
        ...state,
        events: [
          ...events, {
            title: calendarEvent.title,
            start: moment(calendarEvent.start).toDate(),
            end: moment(calendarEvent.end).toDate(),
            all_day: calendarEvent.all_day,
            type: CalendarViewEventType.CALENDAR_EVENT,
            resource: calendarEvent,
          }]
      })
    }

    fetchEvents()
  }

  const onCalendarEventDelete = (calendarEventId: string) => {
    const filteredEvents = events.filter(event => event.resource.id !== calendarEventId)
    setState({ ...state, events: filteredEvents })

    fetchEvents()
  }

  return (
    <SidebarFlyoutContainer active={active}>
      <Wrapper>
        <HeaderContainer>
          <FlyoutHeader
            icon='calendar-day'
            title={t('SidebarCalendar::Calendar')}
            action={{
              label: t('SidebarCalendar::View calendar'),
              onClick: onNavigateToCalendarClick
            }}
          />

          <CalendarHeader>
            <NavigateButtonLeft onClick={onNavigatePrevious} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" height="40" width="40"><path d="m10.75 8.75 3.5 3.25-3.5 3.25" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></NavigateButtonLeft>
            <CalendarDay>
              <span>{date.format('ddd')}</span>
              <span>{date.format('MMM D')}</span>
            </CalendarDay>
            <NavigateButtonRight onClick={onNavigateNext} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" height="40" width="40" aria-hidden="true" ><path d="m10.75 8.75 3.5 3.25-3.5 3.25" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></NavigateButtonRight>
          </CalendarHeader>
        </HeaderContainer>

        <Calendar
          view='day'
          date={date}
          events={events}
          hideToolbar={true}
          onCalendarEventSubmit={onCalendarEventSubmit}
          onCalendarEventDelete={onCalendarEventDelete}
        />
      </Wrapper>
    </SidebarFlyoutContainer>
  )
}

export default withRouter(SidebarCalendar)