import * as React from 'react'
import { AppState } from '../../../store'
import { connect } from 'react-redux'
import TooltipError from '../../../components/Tooltips/ErrorTooltip';
import Button from '../../../components/Button/Button'
import { UserController } from '../../../controllers'
import Notification from '../../../utilities/Notification'
import { setCurrentUser } from '../../../store/authentication/actions'
import { Dispatch } from 'redux';
import Panel from '../../../components/Panel/Panel';
import ScrollToTopOnMount from '../../../components/Effects/ScrollToTopOnMount';
import { WithTranslation, withTranslation } from 'react-i18next';
import PowerSelect from '../../../components/Form/PowerSelect';
import { setLocale } from '../../../translations';
import ReactSelectTheme from '../../../components/Form/ReactSelectTheme';
import LocaleHelper from '../../../helpers/LocaleHelper';
import { CurrentUser, DisplayableError, Me } from '../../../types';
import Title from '../../../components/Settings/Title';
import Subtitle from '../../../components/Settings/Subtitle';
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';

interface IStateToProps {
  currentUser: CurrentUser
}

interface IDispatchToProps {
  setCurrentUser: typeof setCurrentUser
}

type IProps = IStateToProps & IDispatchToProps & WithTranslation

interface IState {
  me: Me
  errors: DisplayableError[]
}

class ProfileDetails extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      me: {
        email: props.currentUser.email,
        name: props.currentUser.name,
        locale: props.currentUser.locale,
      },
      errors: []
    }

    this.onFullNameChange = this.onFullNameChange.bind(this);
    this.onLocaleChange = this.onLocaleChange.bind(this);
    this.onChangePasswordClick = this.onChangePasswordClick.bind(this);
    this.onDeleteAccountClick = this.onDeleteAccountClick.bind(this)
    this.onFormSubmit = this.onFormSubmit.bind(this)
  }

  onFullNameChange(e) {
    const { me } = this.state;
    const name = e.currentTarget.value;

    this.setState({
      me: {
        ...me,
        name: name
      }
    });
  }

  onLocaleChange(option) {
    const { me } = this.state;

    this.setState({
      me: {
        ...me,
        locale: option.value,
      }
    });
  }

  onChangePasswordClick(e) {
    e.preventDefault();

    const { currentUser } = this.props

    window.location.href = `/passwords/new?email=${currentUser.email}`
  }

  onDeleteAccountClick(e) {
    e.preventDefault();

    const { currentUser: { email }, t } = this.props

    UserController
      .deleteUser()
      .then((response) => {
        if (response.success) {
          Notification.notifySuccess(t('ProfileData::A confirmation mail with instructions to delete your account has been sent to {{email}}', { email: email }));
        }
      })
  }

  onFormSubmit(e) {
    e.preventDefault();

    const { setCurrentUser, t } = this.props
    const { me } = this.state;

    UserController
      .updateUser(me)
      .then(response => {
        const { errors } = response;

        if (errors && errors.length > 0) {
          this.setState({
            errors: errors
          });

          Notification.notifyError(t('ProfileDetails::Oops something went wrong'))
        }
        else {
          Notification.notifySuccess(t('ProfileDetails::Updated successfully!'));

          setLocale(me.locale)
          setCurrentUser(response)
        }

      })
      .catch(console.error)
  }

  render() {
    const { t, currentUser } = this.props
    const { me, errors } = this.state

    const localeOptions = LocaleHelper.getLocaleOptions().filter(option => option.value !== 'de')
    const selectedLocaleOption = localeOptions.find(option => option.value === me.locale)

    return (
      <>
        <Title>{t('ProfileDetails::Profile')}</Title>
        <ScrollToTopOnMount />
        <form onSubmit={this.onFormSubmit}>
          <Panel>
            <div className='grid'>
              <div className='grid-cell with-12col'>
                <div className='form-item'>
                  <label>{t('ProfileDetails::Name')}</label>
                  <input type='text' name='name' placeholder='John' value={me.name ? me.name : ''} onChange={this.onFullNameChange} />
                </div>
              </div>

              <div className='grid-cell with-12col'>
                <div className='form-item'>
                  <label>{t('ProfileDetails::Language')}</label>
                  <PowerSelect
                    options={localeOptions}
                    value={selectedLocaleOption}
                    onChange={this.onLocaleChange}
                    noOptionsMessage={() => t('ProfileDetails::No options found')}
                    isClearable={false}
                    theme={ReactSelectTheme}
                  />
                </div>
              </div>
            </div>


            <div className='field-actions'>
              <input type='submit' style={{ display: 'none' }} />
              <div className='popover-wrapper'>
                <TooltipError
                  errors={errors}
                  onDismiss={() => this.setState({ errors: [] })}
                />
                <Button type='success' text='Opslaan' onClick={this.onFormSubmit} />
              </div>
            </div>
          </Panel >

          <Subtitle>{t('ProfileDetails::Account')}</Subtitle>
          <Panel>
            <div className='grid'>
              <div className='grid-cell with-12col'>
                <Subtitle>{t('ProfileSecurity::Security')}</Subtitle>
                <div className='form-item'>
                  <Button type='default' text={t('ProfileSecurity::Edit password')} onClick={this.onChangePasswordClick} />
                </div>
              </div>
            </div>

            <div className='grid'>
              <div className='grid-cell with-12col'>
                <Subtitle type='danger'>{t('ProfileData::Account deletion')}</Subtitle>
                <div className='alert alert-error' style={{ marginBottom: 20 }}>
                  {t('ProfileData::Attention! By deleting your account you will also delete all associated data from the account. Deleting your account is irreversible and all data will be lost.')}
                </div>
                <div className='form-item'>
                  <Button type='danger' icon='close' text={t('ProfileData::Delete account')} onClick={this.onDeleteAccountClick} />
                </div>
              </div>
            </div>
          </Panel>
        </form >
      </>
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    }
  } = state

  return {
    currentUser: currentUser,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    setCurrentUser: (user: CurrentUser) => dispatch(setCurrentUser(user)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ProfileDetails))