import * as React from 'react'
import Datetime from 'react-datetime'
import { connect } from 'react-redux'
import { Manager, Reference, Popper } from 'react-popper'
import Icon from '../Icons/Icon'
import { TimeEntriesController } from '../../controllers'
import TimeHelper from '../../helpers/TimeHelper'
import { AppState } from '../../store'
import MaskedInput from 'react-text-mask';
import moment from '../../utilities/Moment'
import { Moment } from 'moment';
import { Dispatch } from 'redux';
import { startTimer, stopTimer, pauseTimer, resetTimer } from '../../store/timer/actions'
import WorkspaceChannelHelper from '../../helpers/WorkspaceChannelHelper'
import { showContactModal, showDirectionsModal, showProjectModal } from '../../store/modals/actions'
import styled, { css } from 'styled-components'
import { Style } from '../../styles'
import { withTranslation, WithTranslation } from 'react-i18next'
import Popover from '../Popover/Popover'
import * as momentType from 'moment'
import TimeEntryHelper from '../../helpers/TimeEntryHelper'
import ResourceCreatablePowerSelect from '../Form/ResourceCreatablePowerSelect'
import InputGroup from '../Forms/InputGroup'
import WorkspaceHelper from '../../helpers/WorkspaceHelper'
import Link from '../Forms/Link'
import { Contact, CurrentUser, CustomField, Project, ProjectBillableType, ProjectStatus, TimeEntry, WorkspaceCableEventType, WorkType } from '../../types'
import ProjectHelper from '../../helpers/ProjectHelper'
import Utils from '../../utilities/Utils'
import ContactHelper from '../../helpers/ContactHelper'

const Tooltip = styled.div<{ backgroundColor?: string }>`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: absolute;
	left: 50%;
	top: -50px;
	transform: translateX(-50%);
	padding: 8px 16px;
	background: black; 
	color: white;
	border-radius: 3px;
	font-weight: bold;
	transition: opacity 150ms cubic-bezier(0.215, 0.61, 0.355, 1);
	z-index: 5;
	width: max-content;

	${(props) => props.backgroundColor && css`
		background: ${props.backgroundColor};
	`}

	span {
		text-align: center;

		&:first-child {
			font-weight: bold;
		}

		&:last-child {
			font-weight: normal;
		}
	}

	&:after {
		top: 100%;
		left: 50%;
		transform: translateX(-50%);
		border: solid transparent;
		content: " ";
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
		border-color: rgba(136, 183, 213, 0);
		border-width: 10px;

		${(props) => css`
			border-top-color: ${props.backgroundColor || 'black'};
		`}
	}
`

const IncrementTimeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;

  a {
    color: ${Style.color.brandPrimary};
    font-size: 14px;

    &:hover {
      text-decoration: underline;
    }

    &:not(:first-child):not(:last-child) {
      margin: 4px 4px;
    }
  }
`

const SubActionsContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;

	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
		flex-direction: column;
	}
`

const SubAction = styled.a`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 42px;
	height: 42px;

	i {
		font-size: 16px;
		transition: all ${Style.animations.baseTransitionSpeed} ease-in-out;
		color: #CECECE;

		&:hover {
			color: ${Style.color.brandDanger};
		}
	}

	&.is-pause {
		i {
			&:hover {
				color: ${Style.color.brandWarning};
			}
		}
	}

	&.is-trash {
		i {
			&:hover {
				color: ${Style.color.brandDanger};
			}
		}
	}
`

interface IStateToProps {
  currentUser: CurrentUser
  activeTimeEntry?: TimeEntry
}

interface IDispatchToProps {
  startTimer: typeof startTimer
  pauseTimer: typeof pauseTimer
  stopTimer: typeof stopTimer
  resetTimer: typeof resetTimer
  showContactModal: typeof showContactModal
  showProjectModal: typeof showProjectModal
  showDirectionsModal: typeof showDirectionsModal
}

type IProps = IStateToProps & IDispatchToProps & {
  onSubmit: (timeEntry: TimeEntry) => void
} & WithTranslation


type TimeSubmitActions = 'timer' | 'submit' | 'stop'
type TimeMode = 'timer' | 'manual'

interface IState {
  timeEntry: TimeEntry | null
  hasWorkTypes: boolean
  customFields: CustomField[]
  workTypeDropdownActive: boolean
  workTypeTooltipActive: boolean
  contactDropdownActive: boolean
  contactTooltipActive: boolean
  projectDropDownActive: boolean
  projectTooltipActive: boolean
  billableTooltipActive: boolean
  fixedRateTooltipActive: boolean
  mileageDropDownActive: boolean
  mileageTooltipActive: boolean
  mode: 'timer' | 'manual'
  showDurationStartedAtCalendar: boolean
  showManualStartedAtCalendar: boolean
  hasLeapDay: boolean
}

class TrackForm extends React.Component<IProps, IState> {
  private workTypeDropdownContainer = React.createRef<HTMLDivElement>()
  private contactDropdownContainer = React.createRef<HTMLDivElement>()
  private projectDropdownContainer = React.createRef<HTMLDivElement>()
  private mileageDropdownContainer = React.createRef<HTMLDivElement>()
  private durationStartedAtContainer = React.createRef<HTMLDivElement>()
  private durationStartedAtDatePickerContainer = React.createRef<HTMLDivElement>()
  private manualStartedAtContainer = React.createRef<HTMLDivElement>()
  private manualStartedAtDatePickerContainer = React.createRef<HTMLDivElement>()
  private durationMaskedInput = React.createRef<MaskedInput>()
  private startedAtMaskedInput = React.createRef<MaskedInput>()
  private endedAtMaskedInput = React.createRef<MaskedInput>()

  constructor(props: IProps) {
    super(props)


    this.state = {
      timeEntry: props.activeTimeEntry ? props.activeTimeEntry : TimeHelper.getNewTimeEntry(props.currentUser.workspace.setting.default_hourly_rate),
      hasWorkTypes: false,
      customFields: [],
      workTypeDropdownActive: false,
      workTypeTooltipActive: false,
      contactDropdownActive: false,
      contactTooltipActive: false,
      projectDropDownActive: false,
      projectTooltipActive: false,
      billableTooltipActive: false,
      fixedRateTooltipActive: false,
      mileageDropDownActive: false,
      mileageTooltipActive: false,
      showDurationStartedAtCalendar: false,
      showManualStartedAtCalendar: false,
      mode: 'timer',
      hasLeapDay: false
    }


    this.onDescriptionChange = this.onDescriptionChange.bind(this)
    this.onWorkTypeMouseOver = this.onWorkTypeMouseOver.bind(this)
    this.onWorkTypeMouseOut = this.onWorkTypeMouseOut.bind(this)
    this.onToggleWorkTypeDropdown = this.onToggleWorkTypeDropdown.bind(this)
    this.onWorkTypeChange = this.onWorkTypeChange.bind(this)
    this.onContactMouseOver = this.onContactMouseOver.bind(this)
    this.onContactMouseOut = this.onContactMouseOut.bind(this)
    this.onToggleContactDropdown = this.onToggleContactDropdown.bind(this)
    this.onContactChange = this.onContactChange.bind(this)
    this.onProjectMouseOver = this.onProjectMouseOver.bind(this)
    this.onProjectMouseOut = this.onProjectMouseOut.bind(this)
    this.onToggleProjectDropdown = this.onToggleProjectDropdown.bind(this)
    this.onProjectChange = this.onProjectChange.bind(this)
    this.onToggleBillable = this.onToggleBillable.bind(this)
    this.onBillableMouseOver = this.onBillableMouseOver.bind(this)
    this.onBillableMouseOut = this.onBillableMouseOut.bind(this)
    this.onToggleFixedRate = this.onToggleFixedRate.bind(this)
    this.onFixedRateMouseOver = this.onFixedRateMouseOver.bind(this)
    this.onFixedRateMouseOut = this.onFixedRateMouseOut.bind(this)
    this.onMileageReimbursementMouseOver = this.onMileageReimbursementMouseOver.bind(this)
    this.onMileageReimbursementMouseOut = this.onMileageReimbursementMouseOut.bind(this)
    this.onToggleMileageReimbursementClick = this.onToggleMileageReimbursementClick.bind(this)
    this.onTravelDistanceChange = this.onTravelDistanceChange.bind(this)
    this.onCalculateDistanceClick = this.onCalculateDistanceClick.bind(this)
    this.onDurationPickerClose = this.onDurationPickerClose.bind(this)
    this.onDurationFocus = this.onDurationFocus.bind(this)
    this.onDurationBlur = this.onDurationBlur.bind(this)
    this.onDurationChange = this.onDurationChange.bind(this)
    this.onDurationStartedAtDateChange = this.onDurationStartedAtDateChange.bind(this)
    this.onManualStartedAtFocus = this.onManualStartedAtFocus.bind(this)
    this.onManualStartedAtBlur = this.onManualStartedAtBlur.bind(this)
    this.onManualStartedAtChange = this.onManualStartedAtChange.bind(this)
    this.onManualEndedAtFocus = this.onManualEndedAtFocus.bind(this)
    this.onManualEndedAtBlur = this.onManualEndedAtBlur.bind(this)
    this.onManualEndedAtChange = this.onManualEndedAtChange.bind(this)
    this.onManualEndedAtIncrement = this.onManualEndedAtIncrement.bind(this)
    this.onManualStartedAtDateChange = this.onManualStartedAtDateChange.bind(this)
    this.onModeChange = this.onModeChange.bind(this)
    this.onPauseActiveTimer = this.onPauseActiveTimer.bind(this)
    this.onDeleteActiveTimer = this.onDeleteActiveTimer.bind(this)
    this.onTimerUpdateEvent = this.onTimerUpdateEvent.bind(this)
    this.onTimerPauseEvent = this.onTimerPauseEvent.bind(this)
    this.onFormSubmit = this.onFormSubmit.bind(this)
    this.onDurationPopoverClose = this.onDurationPopoverClose.bind(this)
    this.onManualPopoverClose = this.onManualPopoverClose.bind(this)
    this.onOutsideDropdownClick = this.onOutsideDropdownClick.bind(this)
  }

  componentWillReceiveProps(nextProps: IProps) {
    if (nextProps.activeTimeEntry === null) {
      this.resetForm()
    } else if (nextProps.activeTimeEntry && nextProps.activeTimeEntry !== this.props.activeTimeEntry) {
      this.setState({ timeEntry: nextProps.activeTimeEntry }, this.syncInputsWithState)
    }
  }

  componentDidMount() {
    this.fetchForm()

    document.addEventListener('mousedown', this.onOutsideDropdownClick);
    document.addEventListener(WorkspaceCableEventType.UPDATE_TIMER, this.onTimerUpdateEvent)
    document.addEventListener(WorkspaceCableEventType.PAUSE_TIMER, this.onTimerPauseEvent)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.onOutsideDropdownClick);
    document.removeEventListener(WorkspaceCableEventType.UPDATE_TIMER, this.onTimerUpdateEvent);
    document.removeEventListener(WorkspaceCableEventType.PAUSE_TIMER, this.onTimerPauseEvent);
  }

  resetForm() {
    const { currentUser } = this.props

    this.setState({
      timeEntry: TimeHelper.getNewTimeEntry(currentUser.workspace.setting.default_hourly_rate),
      showDurationStartedAtCalendar: false,
      showManualStartedAtCalendar: false,
      contactDropdownActive: false,
      projectDropDownActive: false,
      hasLeapDay: false
    }, () => {
      this.syncInputsWithState()
    })
  }

  syncInputsWithState() {
    const { timeEntry } = this.state

    if (this.durationMaskedInput.current) {
      let timeDifferenceInSeconds = 0
      if (timeEntry.active) {
        timeDifferenceInSeconds = moment().diff(moment(timeEntry.started_at), 'seconds')
      } else {
        timeDifferenceInSeconds = moment(timeEntry.ended_at).diff(moment(timeEntry.started_at), 'seconds')
      }

      const duration = moment.duration(timeDifferenceInSeconds, 'seconds').format('hh:mm:ss', { trim: false })

      if (timeDifferenceInSeconds === 0) {
        // @ts-ignore
        this.durationMaskedInput.current.inputElement.value = ''

      } else {
        // @ts-ignore
        this.durationMaskedInput.current.inputElement.value = duration
      }
    }

    if (this.startedAtMaskedInput.current) {
      (this.startedAtMaskedInput.current.inputElement as HTMLInputElement).value = moment(timeEntry.started_at).format('HH:mm')
    }

    if (this.endedAtMaskedInput.current) {
      (this.endedAtMaskedInput.current.inputElement as HTMLInputElement).value = moment(timeEntry.ended_at).format('HH:mm')
    }
  }

  async fetchForm() {
    try {
      const { work_type, custom_fields } = await TimeEntriesController.getForm()

      this.setState({ customFields: custom_fields, hasWorkTypes: Boolean(work_type) })
    } catch (ex) {
      console.error(ex)
    }
  }

  onDescriptionChange(e) {
    const { timeEntry } = this.state

    this.setState({
      timeEntry: {
        ...timeEntry,
        description: e.currentTarget.value,
      }
    })
  }

  onWorkTypeMouseOver() {
    const { workTypeTooltipActive } = this.state

    if (!workTypeTooltipActive) {
      this.setState({
        workTypeTooltipActive: true
      })
    }
  }

  onWorkTypeMouseOut() {
    const { workTypeTooltipActive } = this.state

    if (workTypeTooltipActive) {
      this.setState({
        workTypeTooltipActive: false
      })
    }
  }

  onToggleWorkTypeDropdown(e) {
    e.preventDefault()

    const { activeTimeEntry } = this.props
    const { workTypeDropdownActive, timeEntry } = this.state

    if (Boolean(timeEntry.active || activeTimeEntry)) {
      this.setState({ workTypeDropdownActive: false })
    } else {
      this.setState({
        workTypeDropdownActive: !workTypeDropdownActive
      })
    }
  }

  async onWorkTypeChange(workTypeId?: string, workType?: WorkType) {
    const { currentUser: { workspace: { setting } } } = this.props
    const { timeEntry } = this.state

    try {
      const updatedTimeEntry: TimeEntry = {
        ...timeEntry,
        work_type_id: workTypeId,
        work_type: workType,
      }

      // Calculate new rate based on time entry values
      updatedTimeEntry.rate = await TimeEntryHelper.getRate(updatedTimeEntry)

      this.setState({
        workTypeDropdownActive: false,
        timeEntry: updatedTimeEntry
      })

    } catch (ex) {
      console.error(ex)
    }
  }

  onContactMouseOver() {
    const { contactTooltipActive } = this.state

    if (!contactTooltipActive) {
      this.setState({
        contactTooltipActive: true
      })
    }
  }

  onContactMouseOut() {
    const { contactTooltipActive } = this.state

    if (contactTooltipActive) {
      this.setState({
        contactTooltipActive: false
      })
    }
  }

  onToggleContactDropdown(e) {
    e.preventDefault()

    const { activeTimeEntry } = this.props
    const { contactDropdownActive, timeEntry } = this.state

    if (Boolean(timeEntry.active || activeTimeEntry)) {
      this.setState({ contactDropdownActive: false })
    } else {

      this.setState({
        contactDropdownActive: !contactDropdownActive
      })
    }
  }

  async onContactChange(contactId?: string, contact?: Contact) {
    const { currentUser: { workspace: { setting } } } = this.props
    const { timeEntry } = this.state

    try {
      const updatedTimeEntry: TimeEntry = {
        ...timeEntry,
        contact: contact,
        contact_id: contactId,
        project_id: null,
      }

      // Calculate new rate based on time entry values
      updatedTimeEntry.rate = await TimeEntryHelper.getRate(updatedTimeEntry)

      this.setState({
        contactDropdownActive: false,
        timeEntry: updatedTimeEntry
      })

    } catch (ex) {
      console.error(ex)
    }
  }

  onProjectMouseOver() {
    const { projectTooltipActive } = this.state

    if (!projectTooltipActive) {
      this.setState({
        projectTooltipActive: true
      })
    }
  }

  onProjectMouseOut() {
    const { projectTooltipActive } = this.state

    if (projectTooltipActive) {
      this.setState({
        projectTooltipActive: false
      })
    }
  }

  onToggleProjectDropdown(e) {
    e.preventDefault()

    const { activeTimeEntry } = this.props
    const { projectDropDownActive, timeEntry } = this.state

    if (Boolean(timeEntry.active || activeTimeEntry)) {
      this.setState({ projectDropDownActive: false })
    } else {
      this.setState({
        projectDropDownActive: !projectDropDownActive
      })
    }
  }

  async onProjectChange(projectId?: string, project?: Project) {
    const { currentUser: { workspace: { setting } } } = this.props
    const { timeEntry } = this.state

    try {
      const updatedTimeEntry: TimeEntry = {
        ...timeEntry,
        project_id: projectId,
        project: project,
        billable: ProjectHelper.isBillable(project)
      }

      // Calculate new rate based on time entry values
      updatedTimeEntry.rate = await TimeEntryHelper.getRate(updatedTimeEntry)

      this.setState({ projectDropDownActive: false, timeEntry: updatedTimeEntry })

    } catch (ex) {
      console.error(ex)
    }
  }

  onToggleBillable(e) {
    e.preventDefault()

    const { activeTimeEntry } = this.props
    const { timeEntry } = this.state

    if (!timeEntry.active && !activeTimeEntry) {
      this.setState({
        timeEntry: {
          ...timeEntry,
          billable: !timeEntry.billable
        }
      })
    }
  }

  onBillableMouseOver() {
    const { billableTooltipActive } = this.state

    if (!billableTooltipActive) {
      this.setState({
        billableTooltipActive: true
      })
    }
  }

  onBillableMouseOut() {
    const { billableTooltipActive } = this.state

    if (billableTooltipActive) {
      this.setState({
        billableTooltipActive: false
      })
    }
  }

  async onToggleFixedRate() {
    const { activeTimeEntry } = this.props
    const { timeEntry } = this.state

    try {
      if (!timeEntry.active && !activeTimeEntry) {
        const updatedTimeEntry: TimeEntry = {
          ...timeEntry,
          fixed_rate: !timeEntry.fixed_rate
        }

        // Get rate based on time entry values
        updatedTimeEntry.rate = await TimeEntryHelper.getRate(updatedTimeEntry)

        this.setState({
          timeEntry: updatedTimeEntry,
        })
      }

    } catch (ex) {
      console.error(ex)
    }
  }

  onFixedRateMouseOver() {
    const { fixedRateTooltipActive } = this.state

    if (!fixedRateTooltipActive) {
      this.setState({
        fixedRateTooltipActive: true
      })
    }
  }

  onFixedRateMouseOut() {
    const { fixedRateTooltipActive } = this.state

    if (fixedRateTooltipActive) {
      this.setState({
        fixedRateTooltipActive: false
      })
    }
  }

  onMileageReimbursementMouseOver() {
    const { mileageTooltipActive } = this.state

    if (!mileageTooltipActive) {
      this.setState({
        mileageTooltipActive: true
      })
    }
  }

  onMileageReimbursementMouseOut() {
    const { mileageTooltipActive } = this.state

    if (mileageTooltipActive) {
      this.setState({
        mileageTooltipActive: false
      })
    }
  }

  onToggleMileageReimbursementClick(e) {
    e.preventDefault()

    const { activeTimeEntry } = this.props
    const { mileageDropDownActive } = this.state

    if (!activeTimeEntry) {
      this.setState({ mileageDropDownActive: !mileageDropDownActive })
    }
  }

  onTravelDistanceChange(e) {
    const { timeEntry } = this.state

    this.setState({
      timeEntry: {
        ...timeEntry,
        travel_distance: e.currentTarget.value
      }
    })
  }

  onCalculateDistanceClick(e) {
    e.preventDefault()
    const { currentUser: { workspace } } = this.props
    const { timeEntry } = this.state

    this.props.showDirectionsModal({
      origin: WorkspaceHelper.getAddress(workspace),
      destination: timeEntry.contact ? ContactHelper.getFullAddress(timeEntry.contact.billing_address) : '',
      onSubmit: (result) => {
        const { distance } = result

        this.setState({
          timeEntry: {
            ...this.state.timeEntry,
            travel_distance: distance
          }
        })
      }
    })
  }

  getDurationMask(value: string) {
    return [/[0-9]/, /[0-9]/, ':', /[0-5]/, /[0-9]/, ':', /[0-5]/, /[0-9]/]
  }

  onDurationPickerClose() {
    this.setState({ showDurationStartedAtCalendar: false })
  }

  onDurationFocus() {
    const { timeEntry } = this.state

    const timeDifferenceInSeconds = moment(timeEntry.ended_at).diff(moment(timeEntry.started_at), 'seconds')
    const duration = moment.duration(timeDifferenceInSeconds, 'seconds').format('hh:mm:ss', { trim: false })

    // No value has been set by the user yet, calculate from now
    if (timeDifferenceInSeconds === 0) {
      const endedAt = moment(timeEntry.ended_at)

      const selectedYear = endedAt.year()
      const selectedMonth = endedAt.month()
      const selectedDay = endedAt.date()

      const nowWithDate = moment()
        .set('year', selectedYear)
        .set('month', selectedMonth)
        .set('date', selectedDay)
        .utc()

      this.setState({
        timeEntry: {
          ...timeEntry,
          started_at: nowWithDate.toString(),
          ended_at: nowWithDate.toString()
        },
      })
    }

    // @ts-ignore
    this.durationMaskedInput.current.inputElement.value = duration
    // @ts-ignore
    this.durationMaskedInput.current.inputElement.select()

    this.setState({
      showDurationStartedAtCalendar: timeEntry.active ? false : true,
    })
  }

  isValidDuration(durationValue) {
    const valueParts = durationValue.split(':').filter(v => v !== '')
    return valueParts.length === 3 && valueParts[0].length == 2 && valueParts[1].length === 2 && valueParts[2].length === 2
  }

  onDurationBlur() {
    const { timeEntry: stateEntry } = this.state

    if (this.durationMaskedInput.current) {
      // @ts-ignore
      const value = this.durationMaskedInput.current.inputElement.value
      const valueParts = value.split(':').filter(v => v !== '')

      // If valid format but 0 or empty
      if (value === '00:00:00' || value === '') {
        const endedAt = moment(stateEntry.ended_at)

        const selectedYear = endedAt.year()
        const selectedMonth = endedAt.month()
        const selectedDay = endedAt.date()

        const nowWithDate = moment()
          .set('year', selectedYear)
          .set('month', selectedMonth)
          .set('date', selectedDay)
          .utc()

        // @ts-ignore
        this.durationMaskedInput.current.inputElement.value = ''
        // Reset calendar values
        this.setState({
          timeEntry: {
            ...stateEntry,
            started_at: nowWithDate.toString(),
            ended_at: nowWithDate.toString(),
          }
        }, () => {
          this.syncInputsWithState()
        })
        // If valid format and not empty
      } else if (valueParts.length === 3 && valueParts[0].length == 2 && valueParts[1].length === 2 && valueParts[2].length === 2) {
        const hours = valueParts[0]
        const minutes = valueParts[1]
        const seconds = valueParts[2]

        const newStartedAt = moment(stateEntry.ended_at)
          .subtract(hours, 'hours')
          .subtract(minutes, 'minutes')
          .subtract(seconds, 'seconds')
          .utc()

        this.setState({
          timeEntry: {
            ...stateEntry,
            started_at: newStartedAt.toString()
          }
        }, () => {
          this.syncInputsWithState()
        })
      } else {
        // Not valid on blur, reset value
        const timeDifferenceInSeconds = moment(stateEntry.ended_at).diff(moment(stateEntry.started_at), 'seconds')

        // If time difference re-input value
        if (timeDifferenceInSeconds > 0) {
          const duration = moment.duration(timeDifferenceInSeconds, 'seconds').format('hh:mm:ss', { trim: false })
          // @ts-ignore
          this.durationMaskedInput.current.inputElement.value = duration
        } else {
          // If no time difference, empty input
          // @ts-ignore
          this.durationMaskedInput.current.inputElement.value = ''
        }
      }
    }
  }

  onDurationChange(e) {
    const value = e.currentTarget.value

    // Valid
    if (value === '' || this.isValidDuration(value)) {
      this.onDurationBlur()
    }
  }

  onDurationStartedAtDateChange(newDate: Moment) {
    const { timeEntry } = this.state

    const differenceInSeconds = moment(timeEntry.ended_at).diff(moment(timeEntry.started_at), 'seconds')

    const selectedYear = newDate.year()
    const selectedMonth = newDate.month()
    const selectedDay = newDate.date()

    const newStartedAt = moment(timeEntry.started_at)
      .set('year', selectedYear)
      .set('month', selectedMonth)
      .set('date', selectedDay)

    const newEndedAt = moment(newStartedAt)
      .add(differenceInSeconds, 'seconds')

    this.setState({
      timeEntry: {
        ...timeEntry,
        started_at: newStartedAt.utc().toString(),
        ended_at: newEndedAt.utc().toString()
      },
    }, () => {
      this.syncInputsWithState()
    })
  }

  onManualStartedAtFocus(e) {
    if (this.startedAtMaskedInput.current) {
      this.syncInputsWithState()
      // @ts-ignore
      this.startedAtMaskedInput.current.inputElement.select()
    }

    this.setState({
      showManualStartedAtCalendar: true
    })
  }

  onManualStartedAtBlur() {
    const { timeEntry: stateEntry, hasLeapDay } = this.state

    if (this.startedAtMaskedInput.current) {
      // @ts-ignore
      const value = this.startedAtMaskedInput.current.inputElement.value

      const valueParts = value.split(':').filter(v => v !== '')

      // If valid format
      if (valueParts.length === 2 && valueParts[0].length == 2 && valueParts[1].length === 2) {
        const hour = valueParts[0]
        const minutes = valueParts[1]

        const endedAt = moment(stateEntry.ended_at).startOf('minute')
        const newStartedAt = moment(stateEntry.started_at)
          .set('hour', hour)
          .set('minutes', minutes)
          .startOf('minute')

        if (TimeHelper.minutesOfDay(newStartedAt) > TimeHelper.minutesOfDay(endedAt) && !hasLeapDay) {
          this.setState({ hasLeapDay: true })
          endedAt.add(1, 'day')
        } else if (TimeHelper.minutesOfDay(newStartedAt) < TimeHelper.minutesOfDay(endedAt) && hasLeapDay) {
          this.setState({ hasLeapDay: false })
          endedAt.subtract(1, 'day')
        }

        this.setState({
          timeEntry: {
            ...stateEntry,
            started_at: newStartedAt.utc().toString(),
            ended_at: endedAt.utc().toString()
          }
        }, () => {
          this.syncInputsWithState()
        })
      } else {
        // Not valid on blur, reset value
        this.syncInputsWithState()
      }
    }
  }

  onManualStartedAtChange(e) {
    const value = e.currentTarget.value

    // Valid
    if (value === '' || Utils.isValidTime(value)) {
      this.onManualStartedAtBlur()
    }
  }

  onManualEndedAtFocus(e) {
    if (this.endedAtMaskedInput.current) {
      this.syncInputsWithState()
      // @ts-ignore
      this.endedAtMaskedInput.current.inputElement.select()
    }
  }

  onManualEndedAtBlur() {
    const { timeEntry: stateEntry, hasLeapDay } = this.state

    if (this.endedAtMaskedInput.current) {
      // @ts-ignore
      const value = this.endedAtMaskedInput.current.inputElement.value

      const valueParts = value.split(':').filter(v => v !== '')

      // If valid format
      if (valueParts.length === 2 && valueParts[0].length == 2 && valueParts[1].length === 2) {
        const hour = valueParts[0]
        const minutes = valueParts[1]

        const startedAt = moment(stateEntry.started_at).startOf('minute')
        const newEndedAt = moment(stateEntry.ended_at)
          .set('hour', hour)
          .set('minutes', minutes)
          .startOf('minute')

        if (TimeHelper.minutesOfDay(startedAt) > TimeHelper.minutesOfDay(newEndedAt) && !hasLeapDay) {
          this.setState({ hasLeapDay: true })
          newEndedAt.add(1, 'day')
        } else if (TimeHelper.minutesOfDay(startedAt) < TimeHelper.minutesOfDay(newEndedAt) && hasLeapDay) {
          this.setState({ hasLeapDay: false })
          newEndedAt.subtract(1, 'day')
        }

        this.setState({
          timeEntry: {
            ...stateEntry,
            ended_at: newEndedAt.utc().toString()
          }
        }, () => {
          this.syncInputsWithState()
        })
      } else {
        // Not valid on blur, reset value
        this.syncInputsWithState()
      }
    }
  }

  onManualEndedAtChange(e) {
    const value = e.currentTarget.value

    // Valid
    if (value === '' || Utils.isValidTime(value)) {
      this.onManualEndedAtBlur()
    }
  }

  onManualEndedAtIncrement(amount: momentType.DurationInputArg1, unit: momentType.DurationInputArg2) {
    const { timeEntry } = this.state

    const currentEndedAt = timeEntry.ended_at
    const currentEndedAtMoment = moment(currentEndedAt)

    if (currentEndedAtMoment.isValid()) {
      const newEndedAtMoment = currentEndedAtMoment.utc().add(amount, unit)

      this.setState({
        timeEntry: {
          ...timeEntry,
          ended_at: newEndedAtMoment.toString()
        }
      }, () => {
        if (this.endedAtMaskedInput.current) {
          const timeDifferenceInSeconds = moment(this.state.timeEntry.ended_at).diff(moment(this.state.timeEntry.started_at), 'seconds')

          // Set manual ended at input
          // @ts-ignore
          this.endedAtMaskedInput.current.inputElement.value = moment(this.state.timeEntry.ended_at).format('HH:mm')

          const duration = moment.duration(timeDifferenceInSeconds, 'seconds').format('hh:mm:ss', { trim: false })

          if (timeDifferenceInSeconds > 0) {
            // Set duration input
            // @ts-ignore
            this.durationMaskedInput.current.inputElement.value = duration
          }
        }
      })
    }
  }

  onManualStartedAtDateChange(newDate: Moment) {
    const { timeEntry } = this.state

    const differenceInSeconds = moment(timeEntry.ended_at).diff(moment(timeEntry.started_at), 'seconds')

    const selectedYear = newDate.year()
    const selectedMonth = newDate.month()
    const selectedDay = newDate.date()

    const newStartedAt = moment(timeEntry.started_at)
      .set('year', selectedYear)
      .set('month', selectedMonth)
      .set('date', selectedDay)

    const newEndedAt = moment(newStartedAt)
      .add(differenceInSeconds, 'seconds')

    this.setState({
      timeEntry: {
        ...timeEntry,
        started_at: newStartedAt.utc().toString(),
        ended_at: newEndedAt.utc().toString()
      },
    }, () => {
      this.syncInputsWithState()
    })
  }

  onModeChange(e, mode: TimeMode) {
    e.preventDefault()
    const { timeEntry: stateTimeEntry } = this.state

    const timeEntry: TimeEntry = {
      ...stateTimeEntry,
      started_at: moment().utc().toString(),
      ended_at: moment().utc().toString(),
    }

    this.setState({
      mode: mode,
      hasLeapDay: false,
      timeEntry,
    }, () => {
      this.syncInputsWithState()
    })
  }

  onPauseActiveTimer() {
    const { pauseTimer } = this.props
    const { timeEntry } = this.state

    timeEntry.active = false

    TimeEntriesController
      .update(timeEntry)
      .then(response => {
        if (response.errors) { }
        else {
          // Pause timer workspace event
          WorkspaceChannelHelper.send({ type: WorkspaceCableEventType.PAUSE_TIMER, data: { entry: response } })
          pauseTimer(response)
        }
      })
      .catch(console.error)
  }

  onDeleteActiveTimer() {
    const { timeEntry } = this.state
    const { resetTimer, activeTimeEntry } = this.props

    if (activeTimeEntry && !activeTimeEntry.active) {
      WorkspaceChannelHelper.send({ type: WorkspaceCableEventType.RESET_TIMER, data: { entry: timeEntry } })

      // Remove timer from local cache
      resetTimer()
    } else {
      TimeEntriesController
        .delete(timeEntry.id)
        .then((response) => {
          this.resetForm()

          // Workspace stop event
          WorkspaceChannelHelper.send({
            type: WorkspaceCableEventType.STOP_TIMER,
            data: {
              entry: timeEntry,
            }
          })

          stopTimer()
        })
        .catch(console.error)
    }
  }

  onTimerUpdateEvent() {
    this.syncInputsWithState()
  }

  onTimerPauseEvent(event) {
    const { currentUser, activeTimeEntry } = this.props
    if (
      event &&
      event.detail &&
      event.detail.data &&
      event.detail.data.entry &&
      event.detail.data.entry.user_id === currentUser.id
    ) {
      this.setState({
        timeEntry: event.detail.data.entry,
      }, this.syncInputsWithState)
    }
  }

  onFormSubmit(e, action: TimeSubmitActions) {
    e.preventDefault()

    const { timeEntry } = this.state
    const { onSubmit, startTimer, stopTimer } = this.props

    // Mode === timer && active time entryj
    if (action === 'timer') {
      // Timer should start at 0 so resetting since user may have waited a minute or two before pressing
      delete timeEntry['id'];
      timeEntry.started_at = moment().utc().toString()
      // Active timer has no ended_at

      timeEntry.ended_at = null
      timeEntry.active = true

      TimeEntriesController
        .create(timeEntry)
        .then(response => {
          if (response.errors) { }
          else {
            // Send submit
            onSubmit(response)

            WorkspaceChannelHelper.send({
              type: WorkspaceCableEventType.START_TIMER,
              data: {
                entry: response
              }
            })

            // Local event
            startTimer(response)

            this.setState({
              timeEntry: response
            })
          }
        })
        .catch(console.error)
    } else if (action === 'submit') {
      TimeEntriesController
        .create(timeEntry)
        .then(response => {
          if (response.errors) { }
          else {
            // Send submit
            onSubmit(response)
            this.resetForm()
          }
        })
        .catch(console.error)
    } else if (action === 'stop') {
      // Stop timer
      timeEntry.active = false

      TimeEntriesController
        .update(timeEntry)
        .then(response => {
          if (response.errors) { }
          else {
            onSubmit(response)

            WorkspaceChannelHelper.send({
              type: WorkspaceCableEventType.STOP_TIMER,
              data: {
                entry: response
              }
            })


            stopTimer()

            this.resetForm()
          }
        })
        .catch(console.error)
    }
  }

  onOutsideDropdownClick(e) {
    const {
      workTypeDropdownActive,
      contactDropdownActive,
      projectDropDownActive,
      mileageDropDownActive,
      showDurationStartedAtCalendar,
      showManualStartedAtCalendar,
    } = this.state

    if (workTypeDropdownActive && this.workTypeDropdownContainer && !this.workTypeDropdownContainer.current.contains(e.target)) {
      this.setState({ workTypeDropdownActive: false })
    }

    if (contactDropdownActive && this.contactDropdownContainer && !this.contactDropdownContainer.current.contains(e.target)) {
      this.setState({ contactDropdownActive: false })
    }

    if (projectDropDownActive && this.projectDropdownContainer && !this.projectDropdownContainer.current.contains(e.target)) {
      this.setState({ projectDropDownActive: false })
    }

    if (mileageDropDownActive && this.mileageDropdownContainer && !this.mileageDropdownContainer.current.contains(e.target)) {
      this.setState({ mileageDropDownActive: false })
    }

    if (
      showDurationStartedAtCalendar &&
      this.durationStartedAtContainer && this.durationStartedAtContainer.current && !this.durationStartedAtContainer.current.contains(e.target) &&
      this.durationStartedAtDatePickerContainer && !this.durationStartedAtDatePickerContainer.current.contains(e.target)
    ) {
      this.setState({ showDurationStartedAtCalendar: false })
    }

    if (
      showManualStartedAtCalendar &&
      this.manualStartedAtContainer && this.manualStartedAtContainer.current && !this.manualStartedAtContainer.current.contains(e.target) &&
      this.manualStartedAtDatePickerContainer && !this.manualStartedAtDatePickerContainer.current.contains(e.target)
    ) {
      this.setState({ showManualStartedAtCalendar: false })
    }
  }

  onDurationPopoverClose() {
    this.setState({ showDurationStartedAtCalendar: false })
  }

  onManualPopoverClose() {
    this.setState({ showManualStartedAtCalendar: false })
  }

  getFormSubmitAction(): TimeSubmitActions {
    const { activeTimeEntry } = this.props
    const { timeEntry, mode } = this.state

    const timeDifferenceInSeconds = moment(timeEntry.ended_at).diff(moment(timeEntry.started_at), 'seconds')

    let action: TimeSubmitActions = 'timer'

    if (
      // If timer mode
      (!timeEntry.active && timeDifferenceInSeconds === 0 && mode === 'timer') ||
      // If paused timer still in cache
      (activeTimeEntry && !activeTimeEntry.active)) {
      action = 'timer'
    } else if (((!timeEntry.active && timeDifferenceInSeconds > 0) || (!timeEntry.active && mode === 'manual'))) {
      action = 'submit'
    } else if (timeEntry.active) {
      action = 'stop'
    }

    // Add case for inactive timer

    return action
  }

  getFormSubmitIcon() {
    const { activeTimeEntry } = this.props
    const { timeEntry, mode } = this.state
    const timeDifferenceInSeconds = moment(timeEntry.ended_at).diff(moment(timeEntry.started_at), 'seconds')

    let icon = <Icon icon='start' />

    if (activeTimeEntry && !activeTimeEntry.active) {
      icon = <Icon icon='start' />
    } else if (!timeEntry.active && timeDifferenceInSeconds === 0 && mode === 'timer') {
      icon = <Icon icon='start' />
    } else if (((!timeEntry.active && timeDifferenceInSeconds > 0) || (!timeEntry.active && mode === 'manual'))) {
      icon = <Icon icon='check' />
    } else if (timeEntry.active) {
      icon = <Icon icon='stop' />
    }

    return icon
  }

  renderAction() {
    const { timeEntry } = this.state

    const action: TimeSubmitActions = this.getFormSubmitAction()
    const icon = this.getFormSubmitIcon()

    return (
      <a href='javascript://' className={`track-form-action ${timeEntry.active ? 'is-active' : ''}`} onClick={(e) => this.onFormSubmit(e, action)}>
        {icon}
      </a>
    )
  }

  render() {
    const {
      activeTimeEntry,
      t,
      currentUser
    } = this.props
    const { setting } = currentUser.workspace
    const {
      timeEntry,
      hasWorkTypes,
      mode,
      workTypeTooltipActive,
      contactTooltipActive,
      projectTooltipActive,
      billableTooltipActive,
      fixedRateTooltipActive,
      mileageDropDownActive,
      mileageTooltipActive,
    } = this.state

    const startedAtMoment = moment(timeEntry.started_at)
    const endedAtMoment = moment(timeEntry.ended_at)

    return (
      <form className='track-form' onSubmit={(e) => this.onFormSubmit(e, this.getFormSubmitAction())}>
        <div className='track-form-description'>
          <input
            type='text'
            name='description'
            placeholder={t('TrackForm::What are you working on?')}
            onChange={this.onDescriptionChange}
            value={timeEntry.description}
            disabled={Boolean(timeEntry.active || activeTimeEntry)}
            autoComplete='off'
          />
        </div>

        {hasWorkTypes && <div className='track-form-container'>
          <Manager>
            <Reference>
              {({ ref }) => (<div ref={ref} style={{ width: '100%' }}>
                {timeEntry.work_type_id && <a href='javascript://' className='track-form-selected-item' onClick={this.onToggleWorkTypeDropdown}>
                  <span style={{ width: 8, height: 8, borderRadius: 4, marginRight: 8, background: timeEntry?.work_type?.color }} />
                  <span style={{ color: 'rgb(28, 75, 0)' }}>{timeEntry?.work_type?.name}</span>
                </a>}
                {!timeEntry.work_type_id && <a
                  href='javascript://'
                  onMouseOver={this.onWorkTypeMouseOver}
                  onMouseOut={this.onWorkTypeMouseOut}
                  onClick={this.onToggleWorkTypeDropdown}
                >
                  {workTypeTooltipActive && <Tooltip>
                    {t('TrackForm::Work type')}
                  </Tooltip>}
                  <Icon icon='tag' />
                </a>}
              </div>)}
            </Reference>

            <Popper placement='bottom'>
              {({ ref, style, placement, arrowProps }) => {
                const { workTypeDropdownActive } = this.state

                if (workTypeDropdownActive) {
                  return (
                    <div
                      ref={ref}
                      style={{ ...style, zIndex: 1 }}
                      data-placement={placement}
                    >
                      <div className='track-form-container-selector' ref={this.workTypeDropdownContainer}>
                        <div className='form-item'>
                          <label>{t('TrackForm::Work type')}</label>
                          <ResourceCreatablePowerSelect
                            type='work_type'
                            value={timeEntry.work_type_id}
                            onChange={this.onWorkTypeChange}
                            placeholder={t('TrackForm::Select a work type...')}
                            isClearable={true}
                          />
                        </div>
                      </div>
                    </div>)
                }

                return null
              }}
            </Popper>
          </Manager>
        </div>}

        <div className='track-form-container'>
          <Manager>
            <Reference>
              {({ ref }) => (<div ref={ref} style={{ width: '100%' }}>
                {timeEntry.contact_id && <a href='javascript://' className='track-form-selected-item' onClick={this.onToggleContactDropdown}>
                  <span style={{ width: 8, height: 8, borderRadius: 4, marginRight: 8, background: timeEntry.contact.color }} />
                  <span style={{ color: 'rgb(28, 75, 0)' }}>{timeEntry.contact.name}</span>
                </a>}
                {!timeEntry.contact_id && <a
                  href='javascript://'
                  onMouseOver={this.onContactMouseOver}
                  onMouseOut={this.onContactMouseOut}
                  onClick={this.onToggleContactDropdown}
                >
                  {contactTooltipActive && <Tooltip>
                    {t('TrackForm::Client')}
                  </Tooltip>}
                  <Icon icon='user' />
                </a>}
              </div>)}
            </Reference>

            <Popper placement='bottom'>
              {({ ref, style, placement, arrowProps }) => {
                const { contactDropdownActive } = this.state

                if (contactDropdownActive) {
                  return (
                    <div
                      ref={ref}
                      style={{ ...style, zIndex: 2 }}
                      data-placement={placement}
                    >
                      <div className='track-form-container-selector' ref={this.contactDropdownContainer}>
                        <div className='form-item'>
                          <label>{t('TrackForm::Client')}</label>
                          <ResourceCreatablePowerSelect
                            type='contact'
                            params={{ archived: false }}
                            value={timeEntry.contact_id}
                            onChange={this.onContactChange}
                            isClearable={true}
                            placeholder={t('TrackForm::Select a client...')}
                          />
                        </div>
                      </div>
                    </div>)
                }

                return null
              }}
            </Popper>
          </Manager>
        </div>

        <div className='track-form-container'>
          <Manager>
            <Reference>
              {({ ref }) => (<div ref={ref} style={{ width: '100%' }}>
                {timeEntry.project_id && <a href='javascript://' className='track-form-selected-item' onClick={this.onToggleProjectDropdown}>
                  <span style={{ width: 8, height: 8, borderRadius: 4, marginRight: 8, background: `${timeEntry.project.color}` }} />
                  <span style={{ color: 'rgb(28, 75, 0)' }}>{timeEntry.project.name}</span>
                </a>}
                {!timeEntry.project_id && <a href='javascript://'
                  onMouseOver={this.onProjectMouseOver}
                  onMouseOut={this.onProjectMouseOut}
                  onClick={this.onToggleProjectDropdown}>
                  {projectTooltipActive && <Tooltip>
                    {t('TrackForm::Project')}
                  </Tooltip>}
                  <Icon icon='briefcase' />
                </a>}
              </div>)}
            </Reference>

            <Popper placement='bottom'>
              {({ ref, style, placement, arrowProps }) => {
                const { projectDropDownActive } = this.state

                if (projectDropDownActive) {
                  return (
                    <div
                      ref={ref}
                      style={{ ...style, zIndex: 2 }}
                      data-placement={placement}
                    >
                      <div className='track-form-container-selector' ref={this.projectDropdownContainer}>
                        <div className='form-item'>
                          <label>{t('TrackForm::Project')}</label>
                          <ResourceCreatablePowerSelect
                            type='project'
                            value={timeEntry.project_id}
                            onChange={this.onProjectChange}
                            isDisabled={!timeEntry.contact_id}
                            params={{ 'contact_id': timeEntry.contact_id, 'status[in]': [ProjectStatus.ACTIVE, ProjectStatus.PROPOSAL] }}
                            createParams={{ contact_id: timeEntry.contact_id }}
                            isClearable={true}
                            placeholder={t('TrackForm::Select a project...')}
                          />
                        </div>
                      </div>
                    </div>)
                }

                return null
              }}
            </Popper>
          </Manager>
        </div>

        {timeEntry?.project?.billable_type !== ProjectBillableType.NON_BILLABLE && <>
          <div className='track-form-container'>
            <a
              href='javascript://'
              onMouseOver={this.onBillableMouseOver}
              onMouseOut={this.onBillableMouseOut}
              onClick={this.onToggleBillable}
              className={timeEntry.billable ? 'is-active' : ''}
            >
              {billableTooltipActive && <Tooltip>
                {t('TrackForm::Billable')}
              </Tooltip>}
              <Icon icon='euro' />
            </a>
          </div>


          <div className='track-form-container'>
            <a
              href='javascript://'
              onMouseOver={this.onFixedRateMouseOver}
              onMouseOut={this.onFixedRateMouseOut}
              onClick={this.onToggleFixedRate}
              className={timeEntry.fixed_rate ? 'is-active' : ''}
            >
              {fixedRateTooltipActive && <Tooltip>
                {t('TrackForm::Day rate')}
              </Tooltip>}
              <Icon icon='24hour' />
            </a>
          </div>

          <div className='track-form-container'>
            <Manager>
              <Reference>
                {({ ref }) => (<div ref={ref} style={{ width: '100%' }}>
                  <a
                    href='javascript://'
                    onMouseOver={this.onMileageReimbursementMouseOver}
                    onMouseOut={this.onMileageReimbursementMouseOut}
                    onClick={this.onToggleMileageReimbursementClick}
                    className={(mileageDropDownActive || timeEntry.travel_distance > 0) ? 'is-active' : ''}
                  >
                    {mileageTooltipActive && <Tooltip>
                      {t('TrackForm::Travel distance')}
                    </Tooltip>}
                    <Icon icon='car-side' />
                  </a>
                </div>)}
              </Reference>

              <Popper placement='bottom'>
                {({ ref, style, placement, arrowProps }) => {
                  const { mileageDropDownActive } = this.state

                  if (mileageDropDownActive) {
                    return (
                      <div
                        ref={ref}
                        style={{ ...style, zIndex: 1 }}
                        data-placement={placement}
                      >
                        <div className='track-form-container-selector' ref={this.mileageDropdownContainer}>
                          <div className='form-item' style={{ marginBottom: 0 }}>
                            <label>{t('TrackForm::Travel distance')}</label>
                            <InputGroup>
                              <input
                                type='number'
                                value={timeEntry.travel_distance}
                                placeholder={t('TrackForm::Mileage')}
                                onChange={this.onTravelDistanceChange}
                                min={0}
                                style={{ minHeight: 38 }}
                              />
                              <span>{t('TrackForm::km')}</span>
                            </InputGroup>
                            <Link onClick={this.onCalculateDistanceClick} style={{ marginTop: 16 }}>
                              {t('TrackForm::Calculate')}
                            </Link>
                          </div>
                        </div>
                      </div>)
                  }

                  return null
                }}
              </Popper>
            </Manager>

          </div>
        </>}

        {mode === 'timer' && <div className='track-form-duration'>
          <Popover
            activator={
              <div ref={this.durationStartedAtContainer}>
                <MaskedInput
                  ref={this.durationMaskedInput}
                  type='text'
                  defaultValue=''
                  placeholder='00:00:00'
                  guide={false}
                  showMask={false}
                  mask={this.getDurationMask}
                  onFocus={this.onDurationFocus}
                  onBlur={this.onDurationBlur}
                  onChange={this.onDurationChange}
                />
              </div>
            }
            active={this.state.showDurationStartedAtCalendar}
            onClose={this.onDurationPopoverClose}
          >
            <div ref={this.durationStartedAtDatePickerContainer} className='track-form-duration-datepicker-container' style={{ minWidth: 320 }}>
              <a href='javascript://' onClick={this.onDurationPickerClose} className='track-form-duration-datepicker-close'>
                <Icon icon='close' />
              </a>

              <div className='track-form-duration-datepicker-range'>
                <div className='form-item'>
                  <label>{t('TrackForm::START')}</label>
                  <div className='track-form-manual-input'>
                    <MaskedInput
                      ref={this.startedAtMaskedInput}
                      type='text'
                      defaultValue={startedAtMoment.format('HH:mm')}
                      placeholder='00:00'
                      guide={false}
                      showMask={false}
                      mask={[/[0-2]/, /[0-9]/, ':', /[0-5]/, /[0-9]/]}
                      onFocus={this.onManualStartedAtFocus}
                      onBlur={this.onManualStartedAtBlur}
                      onChange={this.onManualStartedAtChange}
                    />

                    <span className='date-indicator'>
                      {moment(timeEntry.started_at).format('DD/MM')}
                    </span>
                  </div>
                </div>
                <div className='form-item'>
                  <label>{t('TrackForm::STOP')}</label>
                  <div className='track-form-manual-input'>
                    <MaskedInput
                      ref={this.endedAtMaskedInput}
                      type='text'
                      defaultValue={endedAtMoment.format('HH:mm')}
                      placeholder='00:00'
                      guide={false}
                      showMask={false}
                      mask={[/[0-2]/, /[0-9]/, ':', /[0-5]/, /[0-9]/]}
                      onFocus={this.onManualEndedAtFocus}
                      onBlur={this.onManualEndedAtBlur}
                      onChange={this.onManualEndedAtChange}
                    />
                  </div>
                </div>
              </div>

              <IncrementTimeContainer>
                <a href='javascript://' onClick={() => this.onManualEndedAtIncrement(15, 'minutes')}>{t('TrackForm::+15m')}</a>
                <a href='javascript://' onClick={() => this.onManualEndedAtIncrement(30, 'minutes')}>{t('TrackForm::+30m')}</a>
                <a href='javascript://' onClick={() => this.onManualEndedAtIncrement(45, 'minutes')}>{t('TrackForm::+45m')}</a>
                <a href='javascript://' onClick={() => this.onManualEndedAtIncrement(1, 'hour')}>{t('TrackForm::+1h')}</a>
                <a href='javascript://' onClick={() => this.onManualEndedAtIncrement(2, 'hours')}>{t('TrackForm::+2h')}</a>
                <a href='javascript://' onClick={() => this.onManualEndedAtIncrement(8, 'hours')}>{t('TrackForm::+8h')}</a>
              </IncrementTimeContainer>

              {startedAtMoment.isValid() && endedAtMoment.isValid() && endedAtMoment.diff(startedAtMoment, 'seconds') > 0 && <IncrementTimeContainer>
                <a href='javascript://' onClick={() => this.onManualEndedAtIncrement(-15, 'minutes')}>{t('TrackForm::-15m')}</a>
                <a href='javascript://' onClick={() => this.onManualEndedAtIncrement(-30, 'minutes')}>{t('TrackForm::-30m')}</a>
                <a href='javascript://' onClick={() => this.onManualEndedAtIncrement(-45, 'minutes')}>{t('TrackForm::-45m')}</a>
                <a href='javascript://' onClick={() => this.onManualEndedAtIncrement(-1, 'hour')}>{t('TrackForm::-1h')}</a>
                <a href='javascript://' onClick={() => this.onManualEndedAtIncrement(-2, 'hours')}>{t('TrackForm::-2h')}</a>
                <a href='javascript://' onClick={() => this.onManualEndedAtIncrement(-8, 'hours')}>{t('TrackForm::-8h')}</a>
              </IncrementTimeContainer>}

              <Datetime
                initialValue={startedAtMoment}
                input={false}
                timeFormat={false}
                open={this.state.showDurationStartedAtCalendar}
                onChange={this.onDurationStartedAtDateChange}
              />
            </div>
          </Popover>
        </div>}

        {mode === 'manual' && <div className='track-form-manual'>
          <Popover
            activator={
              <div className='track-form-manual-input' ref={this.manualStartedAtContainer}>
                <MaskedInput
                  ref={this.startedAtMaskedInput}
                  type='text'
                  defaultValue={startedAtMoment.format('HH:mm')}
                  placeholder='00:00'
                  guide={false}
                  showMask={false}
                  mask={[/[0-2]/, /[0-9]/, ':', /[0-5]/, /[0-9]/]}
                  onFocus={this.onManualStartedAtFocus}
                  onBlur={this.onManualStartedAtBlur}
                  onChange={this.onManualStartedAtChange}
                  style={{ minWidth: 110 }}
                />

                <span className='date-indicator'>
                  {moment(timeEntry.started_at).format('DD/MM')}
                </span>
              </div>
            }
            active={this.state.showManualStartedAtCalendar}
            onClose={this.onManualPopoverClose}
          >
            <div ref={this.manualStartedAtDatePickerContainer} style={{ borderWidth: 1, borderColor: Style.color.border }}>
              <div className='track-form-manual-datepicker-container'>
                <Datetime
                  initialValue={startedAtMoment}
                  input={false}
                  timeFormat={false}
                  open={true}
                  onChange={this.onManualStartedAtDateChange}
                />
              </div>
            </div>
          </Popover>

          <div className='track-form-manual-arrow'>
            <Icon icon='arrow-right' />
          </div>

          <div className='track-form-manual-input is-end'>
            <MaskedInput
              ref={this.endedAtMaskedInput}
              type='text'
              defaultValue={endedAtMoment.format('HH:mm')}
              placeholder='00:00'
              guide={false}
              showMask={false}
              mask={[/[0-2]/, /[0-9]/, ':', /[0-5]/, /[0-9]/]}
              onFocus={this.onManualEndedAtFocus}
              onBlur={this.onManualEndedAtBlur}
              onChange={this.onManualEndedAtChange}
            />

            {endedAtMoment.isAfter(startedAtMoment, 'day') && <div className='track-form-day-indicator'>
              1
            </div>}
          </div>
        </div>}

        {this.renderAction()}

        {(!timeEntry.active && !activeTimeEntry) && <div className='track-form-mode'>
          <a href='javascript://' className={mode === 'timer' ? 'is-active' : ''} onClick={(e) => this.onModeChange(e, 'timer')}>
            <Icon icon='stopwatch' />
          </a>

          <a href='javascript://' className={mode === 'manual' ? 'is-active' : ''} onClick={(e) => this.onModeChange(e, 'manual')}>
            <Icon icon='bars' />
          </a>
        </div>}

        {(timeEntry.active || activeTimeEntry) && <SubActionsContainer>
          {timeEntry.active && <SubAction href='javascript://' className='is-pause' onClick={this.onPauseActiveTimer}>
            <Icon icon='pause' />
          </SubAction>}

          <SubAction href='javascript://' className='is-trash' onClick={this.onDeleteActiveTimer}>
            <Icon icon='trash' />
          </SubAction>
        </SubActionsContainer>}
        <input type='submit' style={{ display: 'none' }} />
      </form>
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    },
    timer: {
      entry
    },
  } = state

  return {
    currentUser: currentUser,
    activeTimeEntry: entry,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    startTimer: (entry) => dispatch(startTimer(entry)),
    pauseTimer: (entry) => dispatch(pauseTimer(entry)),
    stopTimer: () => dispatch(stopTimer()),
    resetTimer: () => dispatch(resetTimer()),
    showContactModal: (options) => dispatch(showContactModal(options)),
    showProjectModal: (options) => dispatch(showProjectModal(options)),
    showDirectionsModal: (options) => dispatch(showDirectionsModal(options))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TrackForm))