import * as React from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router-dom'
import Button from '../components/Button/Button'
import ScrollToTopOnMount from '../components/Effects/ScrollToTopOnMount'
import TopNavigation from '../components/Navigation/TopNavigation'
import PageContent from '../components/Page/PageContent'
import PageHeader from '../components/Page/PageHeader'
import FormsTable from '../components/Forms/FormsTable'
import ERoute from '../ERoute'
import RouteHelper from '../helpers/RouteHelper'
import CurrentUserHelper from '../helpers/CurrentUserHelper'
import FullPageContent from '../components/Page/FullPageContent'
import Images from '../images'

type IProps = & RouteComponentProps<any>

const Forms = (props: IProps) => {
	const { t } = useTranslation()
	const { history } = props

	const onNewFormClick = async () => {
		history.push(RouteHelper.process(ERoute.PATH_FORMS_CREATE))
	}

	return (
		<>
			<Helmet>
				<title>{t('Forms::{{__appName}} | Forms')}</title>
			</Helmet>
			<ScrollToTopOnMount />

			<TopNavigation
				icon='input-pipe'
				title={t('Contacts::Forms')}
				action={
					<Button
						type='primary'
						icon='plus'
						text={t('Forms::New Form')}
						onClick={onNewFormClick}
						extraClasses='page-action'
					/>
				}
			/>

			<PageContent>
				<PageHeader title={t('Forms::Forms')} />
				<FormsTable />
			</PageContent>
		</>
	)
}

export default Forms