import styled from 'styled-components'

export default styled.div`
	display: block;
	max-width: 160px;
	overflow: hidden;
	text-overflow: ellipsis;
	margin: 0;
	font-size: 14px;
	color: #687882;
	padding: 0 0 4px 0;
`