import * as React from 'react'
import ReactTooltip from 'react-tooltip';
import { PureComponent } from 'react'
import { HashRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { AppState } from '../store'
import moment from '../utilities/Moment'
import { Api, SettingsController } from '../controllers'
import ERoute from '../ERoute'
import AppLayout from '../layouts/AppLayout'
import Onboarding from './Onboarding'
import LedgerItemEditor from '../layouts/LedgerItemEditor'
import { toast } from 'react-toastify'
import ConfirmEmail from './ConfirmEmail'
import Integration from './Integration'
import PushNotification from '../PushNotification'
import DeviceController from '../controllers/DevicesController'
import i18n, { setLocale } from '../translations'
import ActionCableConsumer from '../consumers/ActionCableConsumer'
import { Dispatch } from 'redux'
import { pauseTimer, resetTimer, startTimer, stopTimer } from '../store/timer/actions'
import { updateSettings, updateSubscription, updateWorkspace } from '../store/authentication/actions'
import { setChannelState } from '../store/channels/actions'
import Workspaces from './Workspaces'
import UserWorkspaceSettingHelper from '../helpers/UserWorkspaceSettingHelper'
import { Helmet } from 'react-helmet';
import { Body } from '../components/Body/Body';
import Modals from '../components/Modals/Modals';
import { CurrentUser, PushNotificationData, PushNotificationDataType, Settings, UserWorkspaceSettingScope, WorkspaceCableEventType, WorkspaceChannelEvent, WorkspaceChannelEventType } from '../types';
import { migrateLocaleStorageValues } from '../LocalStorage';
import PlaybooksCreate from './PlaybooksCreate';
import ContractsCreate from './ContractsCreate';
import FormsCreate from './FormsCreate';
import CurrentUserHelper from '../helpers/CurrentUserHelper';
import Subscription from './Account/Subscription';
import { WithTranslation, withTranslation } from 'react-i18next';
import { MessagePayload } from 'firebase/messaging';
import { showConfirmModal } from '../store/modals/actions';

interface IStateToProps {
  currentUser: CurrentUser
  accessToken: string
  navigationActive: boolean
  appVersion: string
}

interface IDispatchToProps {
  updateWorkspace: typeof updateWorkspace
  updateSubscription: typeof updateSubscription
  setChannelState: typeof setChannelState
  startTimer: typeof startTimer
  pauseTimer: typeof pauseTimer
  stopTimer: typeof stopTimer
  resetTimer: typeof resetTimer
  updateSettings: typeof updateSettings
  showConfirmModal: typeof showConfirmModal
}

type IProps = IStateToProps & IDispatchToProps & WithTranslation

class App extends PureComponent<IProps> {
  private versionPing: NodeJS.Timeout

  constructor(props: IProps) {
    super(props)

    this.onActionCableConnected = this.onActionCableConnected.bind(this)
    this.onActionCableDisconnected = this.onActionCableDisconnected.bind(this)
    this.onActionCableReceived = this.onActionCableReceived.bind(this)
    this.actionCableSubscriptionHandler = this.actionCableSubscriptionHandler.bind(this)
    this.onPushNotificationMessage = this.onPushNotificationMessage.bind(this)
    this.onI18nInitialized = this.onI18nInitialized.bind(this)
    this.onAppVersionPing = this.onAppVersionPing.bind(this)
  }

  componentWillMount() {
    const {
      accessToken,
      currentUser: {
        locale,
        workspace,
      }
    } = this.props

    // Migrate old locale storage values if needed
    migrateLocaleStorageValues()

    // Set API token
    Api.setToken(accessToken)

    // Set moment defaults
    moment.locale(locale)

    // Set day of the week in momentjs
    if (workspace?.setting?.start_week_on === 'monday') {
      moment.updateLocale(locale, { week: { dow: 1 } });
    }

    // Set i18next locale
    setLocale(locale)

    // Set default timezone
    if (workspace) moment.tz.setDefault(workspace.timezone)

    // Toast configuration
    toast.configure({
      position: 'bottom-center',
      closeButton: false,
    })

    // Firebase messaging setup
    this.pushNotificationSetup().catch(console.error)

    // Add an interval to check for new app versions (every 5 minutes)
    this.versionPing = setInterval(this.onAppVersionPing, 5 * 60 * 1000)
  }

  componentWillUnmount() {
    // Remove i18next handle
    i18n.off('initialized', this.onI18nInitialized)

    // Remove version ping interval
    if (this.versionPing) clearInterval(this.versionPing)
  }

  onActionCableConnected() {
    console.log('[WorkspaceChannel] connected')
  }

  onActionCableDisconnected() {
    console.log('[WorkspaceChannel] disconnected')
  }

  onActionCableReceived(event) {
    console.log('[WorkspaceChannel] event received', event)

    const {
      updateWorkspace,
      updateSubscription,
      startTimer,
      pauseTimer,
      stopTimer,
      resetTimer,
      currentUser,
    } = this.props

    switch (event.type) {
      case WorkspaceChannelEventType.WORKSPACE_CREATE:
      case WorkspaceChannelEventType.WORKSPACE_UPDATE:
        updateWorkspace(event.data.workspace)
        break
      case WorkspaceChannelEventType.SUBSCRIPTION_CREATE:
      case WorkspaceChannelEventType.SUBSCRIPTION_UPDATE:
        updateSubscription(event.data.subscription)
        break
      case WorkspaceCableEventType.START_TIMER:
        // Only start timer if entry is owned by the user
        if (event.data.entry.user_id === currentUser.id) {
          startTimer(event.data.entry)
        }
        break
      case WorkspaceCableEventType.PAUSE_TIMER:
        // Only pause timer if entry is owned by the user
        if (event.data.entry.user_id === currentUser.id) {
          pauseTimer(event.data.entry)
          document.dispatchEvent(new CustomEvent(event.type, { detail: event }))
        }
        break
      case WorkspaceCableEventType.RESET_TIMER:
        // Only pause timer if entry is owned by the user
        if (event.data.entry.user_id === currentUser.id) {
          resetTimer()
          document.dispatchEvent(new CustomEvent(event.type, { detail: event }))
        }
        break
      case WorkspaceCableEventType.STOP_TIMER:
        // Only stop timer of entry is owned by the user who is active
        if (event.data.entry.user_id === currentUser.id) {
          // Send document event so everything refreshes
          const documentStopTimerEvent = new Event(WorkspaceCableEventType.STOP_TIMER)
          document.dispatchEvent(documentStopTimerEvent)

          stopTimer()

          document.title = 'Bizzey'
        }
        break
      case WorkspaceCableEventType.CREATE_TIME_ENTRY:
      case WorkspaceCableEventType.UPDATE_TIME_ENTRY:
      case WorkspaceCableEventType.DELETE_TIME_ENTRY:
        const customEvent = new CustomEvent(event.type, { detail: event })
        document.dispatchEvent(customEvent)
        break
      case WorkspaceChannelEventType.SETTING_UPDATE:
        this.fetchSettings().catch(console.error)
        break
    }
  }

  /**
   * 
   * @param channel Eventually this should be necessary
   */
  actionCableSubscriptionHandler(channel: ActionCable.Channel) {
    this.props.setChannelState({ workspaceChannel: channel })
  }

  async fetchSettings() {
    const { updateSettings } = this.props

    try {
      // Fetch remote settings
      const settings = await SettingsController.get()

      // Update local settings
      updateSettings(settings)
    } catch (ex) {
      console.error(ex)
    }
  }

  async pushNotificationSetup() {
    const permissionRequest = await PushNotification.requestPermission()

    if (permissionRequest === 'granted') {
      try {
        const token = await PushNotification.getToken()

        if (token) await DeviceController.syncDevice({ token: token })

        await PushNotification.onMessage(this.onPushNotificationMessage)
      } catch (ex) {
        console.error(ex)
      }
    }
  }

  async onPushNotificationMessage(payload: MessagePayload) {
    console.log('[onPushNotificationMessage] message', payload)
    const { t } = this.props
    const { data } = payload

    // @ts-ignore
    const pushNotificationData = (data as PushNotificationData)

    switch (pushNotificationData?.type) {
      case PushNotificationDataType.CALL:
        const { telephone_number } = pushNotificationData

        this.props.showConfirmModal({
          title: t('App::Call initiation'),
          description: t('App::Are you sure you want to call {{telephoneNumber}}?', { telephoneNumber: telephone_number }),
          action: {
            label: t('App::Start call'),
          },
          onConfirm: () => {
            const { telephone_number } = pushNotificationData
            window.location.href = `tel:${telephone_number}`
          }
        })
      default:
        break
    }
  }

  onI18nInitialized() {
    const { currentUser } = this.props
    setLocale(currentUser.locale)
  }

  async onAppVersionPing() {
    const { appVersion, t } = this.props
    const response = await fetch('/version')

    if (response.status === 200) {
      const serverVersion = await response.text()

      if (appVersion !== serverVersion) {
        const confirmed = confirm(t('App::A new version of Bizzey is available. Would you like to reload the app to update?'))

        if (confirmed) {
          window.location.reload()
        }
      }
    }
  }

  render(): JSX.Element {
    const { currentUser, navigationActive } = this.props

    return (
      <>
        <Helmet>
          <meta name="theme-color" content={navigationActive ? '#000000' : '#ffffff'} />
          <meta name="apple-mobile-web-app-status-bar-style" content={navigationActive ? 'black' : 'default'} />
        </Helmet>
        {navigationActive && <Body className='no-overflow' />}
        <ActionCableConsumer
          channel={{ channel: 'WorkspaceChannel', id: currentUser?.workspace?.id }}
          onConnected={this.onActionCableConnected}
          onDisconnected={this.onActionCableDisconnected}
          onReceived={this.onActionCableReceived}
          subscriptionHandler={this.actionCableSubscriptionHandler}
        >
          <Router>
            <Switch>
              <Route path={ERoute.PATH_CONFIRM_EMAIL} component={ConfirmEmail} />
              <Route path={ERoute.PATH_WELCOME_LAYOUT} component={Onboarding} />
              <Route path={ERoute.PATH_WORKSPACES} component={Workspaces} />
              {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.INVOICE) && <Route path={ERoute.PATH_INVOICES_NEW} component={LedgerItemEditor} />}
              {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.INVOICE) && <Route path={ERoute.PATH_INVOICE_EDIT} component={LedgerItemEditor} />}
              <Route path={ERoute.PATH_INTEGRATION} component={Integration} />
              <Route path={ERoute.PATH_PLAYBOOKS_CREATE} component={PlaybooksCreate} />
              <Route path={ERoute.PATH_CONTRACTS_CREATE} component={ContractsCreate} />
              <Route path={ERoute.PATH_FORMS_CREATE} component={FormsCreate} />
              {/* @ts-ignore */}
              <Route path={ERoute.PATH_SUBSCRIPTION_PAYWALL} component={() => <Subscription paywall />} />
              <Route path={ERoute.PATH_APP_LAYOUT} render={(navigation) => {
                // Email confirmation
                const shouldVerify = moment().diff(moment(currentUser.created_at), 'hours') >= 1

                // Email confirmation is required after 1 hour
                if (!currentUser.email_confirmed_at && shouldVerify) {
                  return <Redirect to={ERoute.PATH_CONFIRM_EMAIL} />
                }

                // User has no workspace assigned, redirect to workspaces screen
                if (!currentUser.workspace) {
                  return <Redirect to={ERoute.PATH_WORKSPACES} />
                }

                // Onboarding
                if (!currentUser.workspace.did_onboarding) {
                  return <Redirect to={ERoute.PATH_WELCOME_LAYOUT} />
                }

                // Lock the user out of the workspace if subscription has been suspended or unpaid
                if (!currentUser.workspace.vip && (CurrentUserHelper.hasFreeSubscription() || !CurrentUserHelper.isSubscriptionActive())) {
                  return <Redirect to={ERoute.PATH_SUBSCRIPTION_PAYWALL} />
                }

                // Redirect if onboarding not complete
                return <AppLayout {...navigation} />
              }} />
            </Switch>
            <Modals />
            <ReactTooltip effect='solid' />
          </Router>
        </ActionCableConsumer>
      </>
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
      accessToken,
    },
    navigation: {
      active,
    },
    config: {
      version
    }
  } = state

  return {
    currentUser: currentUser,
    accessToken: accessToken,
    navigationActive: active,
    appVersion: version,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchToProps => {
  return {
    updateWorkspace: (workspace) => dispatch(updateWorkspace(workspace)),
    updateSubscription: (subscription) => dispatch(updateSubscription(subscription)),
    setChannelState: (channelState) => dispatch(setChannelState(channelState)),
    startTimer: (entry) => dispatch(startTimer(entry)),
    pauseTimer: (entry) => dispatch(pauseTimer(entry)),
    stopTimer: () => dispatch(stopTimer()),
    resetTimer: () => dispatch(resetTimer()),
    updateSettings: (settings: Settings) => dispatch(updateSettings(settings)),
    showConfirmModal: (options) => dispatch(showConfirmModal(options)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(App))