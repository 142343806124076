import * as React from 'react'
import TopNavigation from '../../components/Navigation/TopNavigation';
import { Switch, Redirect, Route, RouteComponentProps } from 'react-router'
import ERoute from '../../ERoute'
import ProfileDetails from './Profile/ProfileDetails'
import ScrollToTopOnMount from '../../components/Effects/ScrollToTopOnMount';
import { Helmet } from 'react-helmet';
import { withTranslation, WithTranslation } from 'react-i18next';
import SettingsPage from '../../components/Settings/SettingsPage';
import SettingsMenu from '../../components/Settings/SettingsMenu';
import SettingsContent from '../../components/Settings/SettingsContent';
import FullPageContent from '../../components/Page/FullPageContent';
import MenuSection from '../../components/Settings/MenuSection';
import MenuSectionHeader from '../../components/Settings/MenuSectionHeader';
import MenuItem from '../../components/Settings/MenuItem';
import Notifications from './Profile/Notifications';

type IProps = RouteComponentProps<{}> & WithTranslation
class Profile extends React.Component<IProps> {
  render() {
    const { location, t } = this.props

    return (
      <>
        <Helmet>
          <title>{t('Profile::{{__appName}} | Profile')}</title>
        </Helmet>
        <TopNavigation
          icon='user'
          title={t('Profile::Profile')}
        />

        <ScrollToTopOnMount />

        <FullPageContent background='transparent'>
          <SettingsPage>
            <SettingsMenu>
              <MenuSection>
                <MenuSectionHeader>
                  {t('Settings::Personal settings')}
                </MenuSectionHeader>
                <MenuItem to={ERoute.PATH_ACCOUNT_PROFILE_DETAILS} text={t('Profile::Profile')} />
                <MenuItem to={ERoute.PATH_ACCOUNT_PROFILE_NOTIFICATIONS} text={t('Profile::Notifications')} />
              </MenuSection>
            </SettingsMenu>

            <SettingsContent>
              <Switch location={location}>
                <Route path={ERoute.PATH_ACCOUNT_PROFILE_DETAILS} component={ProfileDetails} />
                <Route path={ERoute.PATH_ACCOUNT_PROFILE_NOTIFICATIONS} component={Notifications} />
                <Redirect from='/' to={ERoute.PATH_ACCOUNT_PROFILE_DETAILS} />
              </Switch>
            </SettingsContent>
          </SettingsPage>
        </FullPageContent>
      </>
    )
  }
}

export default withTranslation()(Profile)