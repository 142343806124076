import { PlannerDate, Task } from "../types";

class PlannerHelper {
  static getAvailableTime(plannerDate: PlannerDate) {
    return plannerDate.availability;
  }

  static getScheduledTime(plannerDate: PlannerDate) {
    return (
      plannerDate?.items?.reduce((total, item) => total + item.duration, 0) || 0
    );
  }

  static getRemainingTime(plannerDate: PlannerDate) {
    return (
      this.getAvailableTime(plannerDate) - this.getScheduledTime(plannerDate)
    );
  }

  static isOverbooked(plannerDate: PlannerDate) {
    return this.getRemainingTime(plannerDate) <= 0;
  }

  static getTaskSchedulingTime(plannerDate: PlannerDate, task: Task) {
    return Math.min(this.getRemainingTime(plannerDate), task.time_remaining);
  }

  static getTaskOverschedulingTime(task: Task) {
    return Math.min(3600, task.time_remaining);
  }
}

export default PlannerHelper;
