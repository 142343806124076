import * as React from 'react'
import { connect } from 'react-redux'
import { AppState } from '../store'
import { showConfirmModal, showDealModal, showCalendarEventModal, showTaskModal, showSendEmailModal } from '../store/modals/actions'
import ScrollToTopOnMount from '../components/Effects/ScrollToTopOnMount'
import PageLoader from '../components/Page/PageLoader'
import { Helmet } from 'react-helmet';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { BizzeyFileResourceType, CalendarEvent, CalendarEventType, CurrentUser, Deal as DealType, DealStage, DealStatus, DealViewActionType, LedgerItemType, Task, UserWorkspaceSettingScope, ActivityTrackableType, ActivityType } from '../types';
import TopNavigation from '../components/Navigation/TopNavigation';
import PageHeader from '../components/Page/PageHeader';
import PageContent from '../components/Page/PageContent';
import { ActivitiesController, DealsController } from '../controllers'
import Timeline, { TimelineStep } from '../components/Timeline/Timeline'
import PageHeaderNavigation from '../components/Page/PageHeaderNavigation'
import PageHeaderNavigationLeft from '../components/Page/PageHeaderNavigationLeft'
import PageHeaderNavigationRight from '../components/Page/PageHeaderNavigationRight'
import ButtonGroup from '../components/Button/ButtonGroup'
import DealHelper from '../helpers/DealHelper'
import { Style } from '../styles'
import Icon, { IconTypes } from '../components/Icons/Icon'
import Sound, { Sounds } from '../sounds'
import Notification from '../utilities/Notification'
import Panel from '../components/Panel/Panel'
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView'
import DetailsList from '../components/Profile/DetailsList'
import ProfileDetailItem from '../components/Profile/ProfileDetailItem'
import NumberFormatter from '../utilities/NumberFormatter'
import PanelAction from '../components/Panel/PanelAction'
import TabItem from '../components/Navigation/TabItem'
import TabItems from '../components/Navigation/TabItems'
import LedgerItemsTable from '../components/Invoices/LedgerItemsTable'
import Link from '../components/Forms/Link'
import UserWorkspaceSettingHelper from '../helpers/UserWorkspaceSettingHelper'
import ProposalsTable from '../components/Proposals/ProposalsTable'
import ActivityFeedContainer from '../components/Activity/ActivityFeedContainer'
import ActivityFeed, { ActivityFeed as ActivityFeedClass } from '../components/Activity/ActivityFeed'
import RouteHelper from '../helpers/RouteHelper'
import ERoute from '../ERoute'
import ResourceFiles from '../components/ResourceFiles/ResourceFiles'
import TimeEntriesTable from '../components/TimeEntry/TimeEntriesTable'
import Popover from '../components/Popover/Popover'
import LostDealsPopover from '../components/Deals/LostDealsPopover'
import AddActivityButton from '../components/Activity/AddActivityButton'

interface IStateToProps {
  currentUser: CurrentUser
}

interface IDispatchToProps {
  showDealModal: typeof showDealModal
  showConfirmModal: typeof showConfirmModal
  showCalendarEventModal: typeof showCalendarEventModal
  showTaskModal: typeof showTaskModal
  showSendEmailModal: typeof showSendEmailModal
}

type IProps = IStateToProps & IDispatchToProps & WithTranslation & RouteComponentProps<{ id: string }>

enum LedgerItemTab {
  PROPOSALS = 'proposals',
  QUOTATIONS = 'quotations',
  INVOICES = 'invoices',
  TIMESHEET = 'timesheet'
}

type DealTab = LedgerItemTab

interface IState {
  deal: DealType,
  dealStages: DealStage[]
  didInitialLoad: boolean,
  activeDealTab: DealTab
  lostDealsPopoverActive: boolean
}

class Deal extends React.Component<IProps, IState> {
  private activityFeed = React.createRef<ActivityFeedClass>()

  constructor(props) {
    super(props);

    this.state = {
      deal: null,
      dealStages: [],
      didInitialLoad: false,
      activeDealTab: LedgerItemTab.QUOTATIONS,
      lostDealsPopoverActive: false,
    }

    this.onBackClick = this.onBackClick.bind(this)
    this.onContactClick = this.onContactClick.bind(this)
    this.onEditDealClick = this.onEditDealClick.bind(this)
    this.onEditNotesClick = this.onEditNotesClick.bind(this)
    this.onDeleteDealClick = this.onDeleteDealClick.bind(this)
    this.onUpdateDealStatusClick = this.onUpdateDealStatusClick.bind(this)
    this.onDealStageClick = this.onDealStageClick.bind(this)
    this.onEmailClick = this.onEmailClick.bind(this)
    this.onSendEmailSubmit = this.onSendEmailSubmit.bind(this)
    this.onScheduleAppointmentClick = this.onScheduleAppointmentClick.bind(this)
    this.onScheduleCallClick = this.onScheduleCallClick.bind(this)
    this.onScheduleTaskClick = this.onScheduleTaskClick.bind(this)
    this.onActiveLedgerItemTabChange = this.onActiveLedgerItemTabChange.bind(this)
    this.onCalendarEventClick = this.onCalendarEventClick.bind(this)
    this.onCalendarEventSubmit = this.onCalendarEventSubmit.bind(this)
    this.onCalendarEventDelete = this.onCalendarEventDelete.bind(this)
    this.onTaskClick = this.onTaskClick.bind(this)
    this.onTaskSubmit = this.onTaskSubmit.bind(this)
    this.onTaskDelete = this.onTaskDelete.bind(this)
    this.onDealOpenClick = this.onDealOpenClick.bind(this)
    this.onDealWonClick = this.onDealWonClick.bind(this)
    this.onDealCloseClick = this.onDealCloseClick.bind(this)
    this.onDealCloseSubmit = this.onDealCloseSubmit.bind(this)
  }

  componentDidMount(): void {
    this.fetchDealView().catch(console.error)
  }

  async fetchDealView() {
    const { match: { params: { id } } } = this.props

    try {
      const { deal, deal_stages } = await DealsController.getView(id)

      this.setState({
        deal: deal,
        dealStages: deal_stages,
        didInitialLoad: true
      })
    } catch (ex) {
      console.error(ex)
    }
  }

  getTimelineSteps(): TimelineStep[] {
    const { deal, dealStages } = this.state

    const activeDealStageIndex = dealStages.findIndex(dealStage => dealStage.id === deal.deal_stage_id)
    const isLost = deal.status === DealStatus.LOST

    return dealStages
      .filter(dealStage => dealStage.status !== DealStatus.LOST)
      .map((dealStage, index) => {
        const complete = activeDealStageIndex >= index
        const isAcceptedDealStage = dealStage.status === DealStatus.WON
        let icon: IconTypes = 'check'
        let backgroundColor = complete ? Timeline.COLOR_SUCCESS : Timeline.COLOR_PROGRESS
        let color = 'white'
        let borderColor = complete ? 'initial' : Style.color.brandPrimary

        if (isAcceptedDealStage) {
          if (isLost) {
            icon = 'close'
            backgroundColor = Style.color.brandDanger
            color = 'white'
          } else if (complete) {
            icon = 'check'
            backgroundColor = Style.color.brandSuccess
          }
        } else {
          if (complete) {
            icon = 'check'
            backgroundColor = Style.color.brandSuccess
            color = undefined
          }
        }

        return {
          key: dealStage.id,
          icon: icon,
          style: {
            backgroundColor: backgroundColor,
            pointerEvents: 'auto',
            color: color,
            borderColor: borderColor,
          },
          tickTopLabel: dealStage.name,
          tickBottomLabel: '',
          onClick: () => this.onDealStageClick(dealStage).catch(console.error),
          complete: complete,
        }
      })

    return []
  }

  onBackClick() {
    this.props.history.goBack()
  }

  onEditDealClick() {
    const { deal } = this.state

    this.props.showDealModal({
      deal: { id: deal.id },
      onSubmit: (deal) => {
        this.setState({ deal: deal })
      }
    })
  }

  onContactClick() {
    this.props.history.push(RouteHelper.process(ERoute.PATH_CONTACT, { id: this.state.deal.contact_id }))
  }

  onEditNotesClick() {
    const { deal } = this.state

    this.props.showDealModal({
      deal: { id: deal.id },
      activeTab: 'notes',
      onSubmit: (deal) => {
        this.setState({ deal: deal })
      }
    })
  }

  async onDeleteDealClick() {
    const { t } = this.props
    const { deal } = this.state
    try {
      await DealsController.delete(deal.id)
      Notification.notifySuccess(t('Deal::Deal successfully deleted'))
      this.props.history.goBack()
    } catch (ex) {
      console.error(ex)
    }
  }

  async onUpdateDealStatusClick(status: DealStatus) {
    const { deal, dealStages } = this.state

    try {
      const response = await DealsController.update({ ...deal, status: status })

      this.setState({ deal: response })
    } catch (ex) {
      console.error(ex)
    }
  }

  async onDealStageClick(dealStage: DealStage) {
    const { deal: stateDeal } = this.state
    try {
      this.setState({
        deal: {
          ...stateDeal,
          deal_stage: dealStage,
          deal_stage_id: dealStage.id
        }
      })

      const deal = await DealsController.update({
        id: stateDeal.id,
        deal_stage_id: dealStage.id
      })

      this.setState({ deal: deal })

      DealHelper.triggerDealStageActionForDeal(deal, dealStage, {
        onCalendarEventSubmit: this.onCalendarEventSubmit,
        onCalendarEventDelete: this.onCalendarEventDelete,
        onTaskSubmit: this.onTaskSubmit,
        onTaskDelete: this.onTaskDelete
      })

      if (dealStage.status === DealStatus.WON) {
        Sound.play(Sounds.COMPLETE)
      } else {
        Sound.play(Sounds.STEP_COMPLETE)
      }

    } catch (ex) {
      console.error(ex)
    }
  }

  onEmailClick() {
    const { deal } = this.state

    this.props.showSendEmailModal({
      email: {
        to: [deal?.contact?.id],
      },
      onSubmit: this.onSendEmailSubmit,
    })
  }

  onScheduleAppointmentClick() {
    const { deal } = this.state

    this.props.showCalendarEventModal({
      calendarEvent: {
        type: CalendarEventType.APPOINTMENT,
        deal_id: deal.id,
        contact_id: deal.contact_id,
        project_id: deal.project_id
      },
      contactDisabled: Boolean(deal.contact_id),
      projectDisabled: Boolean(deal.project_id),
      onSubmit: this.onCalendarEventSubmit,
      onDelete: this.onCalendarEventDelete
    })
  }

  onScheduleCallClick() {
    const { deal } = this.state

    this.props.showCalendarEventModal({
      calendarEvent: {
        type: CalendarEventType.CALL,
        deal_id: deal.id,
        contact_id: deal.contact_id,
        project_id: deal.project_id,
      },
      contactDisabled: Boolean(deal.contact_id),
      projectDisabled: Boolean(deal.project_id),
      onSubmit: this.onCalendarEventSubmit,
      onDelete: this.onCalendarEventDelete
    })

  }

  onScheduleTaskClick() {
    const { deal } = this.state

    this.props.showTaskModal({
      task: {
        deal_id: deal.id,
        contact_id: deal.contact_id,
        contact: deal.contact,
        project_id: deal.project_id,
        project: deal.project
      },
      contactDisabled: Boolean(deal.contact_id),
      projectDisabled: Boolean(deal.project_id),
      onSubmit: this.onTaskSubmit,
      onDelete: this.onTaskDelete
    })
  }

  onActiveLedgerItemTabChange(activeDealTab: DealTab) {
    this.setState({ activeDealTab: activeDealTab })
  }

  onCalendarEventClick(calendarEvent: CalendarEvent) {
    const { deal } = this.state
    this.props.showCalendarEventModal({
      calendarEvent: {
        id: calendarEvent.id
      },
      contactDisabled: Boolean(deal.contact_id),
      projectDisabled: Boolean(deal.project_id),
      onSubmit: this.onCalendarEventSubmit,
      onDelete: this.onCalendarEventDelete
    })
  }

  onSendEmailSubmit() {
    if (this.activityFeed?.current) this.activityFeed.current.refetch()
  }

  onCalendarEventSubmit(calendarEvent: CalendarEvent) {
    if (this.activityFeed?.current) this.activityFeed.current.refetch()
  }

  onCalendarEventDelete(calendarEventId: string) {
    if (this.activityFeed?.current) this.activityFeed.current.refetch()
  }

  onTaskClick(task: Task) {
    const { deal } = this.state

    this.props.showTaskModal({
      task: {
        id: task.id,
        contact_id: deal.contact_id,
        contact: deal.contact,
        project_id: deal.project_id,
        project: deal.project
      },
      contactDisabled: Boolean(deal.contact_id),
      projectDisabled: Boolean(deal.project_id),
      onSubmit: this.onTaskSubmit,
      onDelete: this.onTaskDelete
    })
  }

  onTaskSubmit(task: Task) {
    if (this.activityFeed?.current) this.activityFeed.current.refetch()
  }

  onTaskDelete(task: Task) {
    if (this.activityFeed?.current) this.activityFeed.current.refetch()
  }

  async onDealOpenClick() {
    const { dealStages } = this.state
    try {
      const dealStage = DealHelper.getDealStageForStatus(dealStages, DealStatus.OPEN)

      if (dealStage) {
        await this.onDealStageClick(dealStage)
      } else {
        await this.onUpdateDealStatusClick(DealStatus.OPEN)
      }
    } catch (ex) {
      console.error(ex)
    }
  }

  async onDealWonClick() {
    const { dealStages } = this.state
    try {
      const dealStage = DealHelper.getDealStageForStatus(dealStages, DealStatus.WON)

      if (dealStage) {
        await this.onDealStageClick(dealStage)
      } else {
        await this.onUpdateDealStatusClick(DealStatus.WON)
      }
    } catch (ex) {
      console.error(ex)
    }
  }

  async onDealCloseClick() {
    const { deal } = this.state

    if (deal.status !== DealStatus.LOST) this.setState({ lostDealsPopoverActive: true })
  }


  async onDealCloseSubmit(lostReason: string, notes?: string) {
    const { dealStages, deal } = this.state
    try {
      const dealStage = DealHelper.getDealStageForStatus(dealStages, DealStatus.LOST)

      if (dealStage) {
        const updatedDeal = await DealsController.update({
          ...deal,
          deal_stage_id: dealStage.id,
          lost_reason: lostReason
        })
        this.setState({ deal: updatedDeal, lostDealsPopoverActive: false })
      } else {
        const updatedDeal = await DealsController.update({
          id: deal.id,
          status: DealStatus.LOST,
          lost_reason: lostReason
        })
        this.setState({ deal: updatedDeal, lostDealsPopoverActive: false })
      }

      if (notes) {
        await ActivitiesController.create({
          type: ActivityType.NOTE,
          trackable_type: ActivityTrackableType.DEAL,
          trackable_id: deal.id,
          owner_type: 'User',
          owner_id: this.props.currentUser.id,
          data: { note: notes }
        })
        if (this.activityFeed?.current) {
          this.activityFeed.current.refetch()
        }
      }
    } catch (ex) {
      console.error(ex)
    }
  }

  render() {
    const { t, currentUser: { workspace } } = this.props
    const { setting } = workspace
    const { deal, didInitialLoad, activeDealTab, lostDealsPopoverActive } = this.state

    return (
      <>
        <Helmet>
          <title>{t('Deal::{{__appName}} | Deal')}</title>
        </Helmet>


        <TopNavigation
          icon='check-circle'
          title={t('Deal::Deal')}
        />

        <ScrollToTopOnMount />

        <PageContent>
          <PageHeader
            title={<a href='javascript://' onClick={this.onEditDealClick}>{deal?.name || t('Deal::Deal')}</a>}
            breadcrumbs={[
              { content: t('Deal::Back'), url: 'javascript://', onClick: this.onBackClick }
            ]}
            navigation={
              <PageHeaderNavigation>
                <PageHeaderNavigationLeft />
                <PageHeaderNavigationRight>
                  <ButtonGroup
                    items={[
                      { element: <span>{t('Deal::Open')}</span>, active: deal?.status === DealStatus.OPEN, onClick: this.onDealOpenClick },
                      { element: <span>{t('Deal::Won')}</span>, active: deal?.status === DealStatus.WON, onClick: this.onDealWonClick, color: 'white', backgroundColor: Style.color.brandSuccess, showColorOnHover: true },
                      {
                        element: <Popover
                          active={lostDealsPopoverActive && deal.status !== DealStatus.LOST}
                          activator={
                            <span>{t('Deal::Lost')}</span>
                          }
                          onClose={() => this.setState({ lostDealsPopoverActive: false })}
                          placement='bottom'
                        >
                          {lostDealsPopoverActive && <LostDealsPopover
                            onClose={() => this.setState({ lostDealsPopoverActive: false })}
                            onSubmit={this.onDealCloseSubmit}
                          />}
                        </Popover>,
                        active: deal?.status === DealStatus.LOST,
                        onClick: this.onDealCloseClick,
                        color: 'white',
                        backgroundColor: Style.color.brandDanger,
                        showColorOnHover: true
                      },
                    ]}
                  />
                </PageHeaderNavigationRight>
              </PageHeaderNavigation>
            }
            mainActions={[
              { key: 'edit', content: t('Deal::Edit deal'), icon: 'edit', onClick: this.onEditDealClick },
              { key: 'delete', content: t('Deal::Delete deal'), icon: 'trash-alt-solid', destructive: true, onClick: this.onDeleteDealClick }
            ]}
          />
          {!didInitialLoad && <PageLoader />}

          {didInitialLoad && <>
            <Timeline steps={this.getTimelineSteps()} />

            <div className='grid'>
              <div className='grid-cell with-4col'>
                <Panel
                  title={t('Deal::Details')}
                  headerRight={<PanelAction icon='pen' onClick={this.onEditDealClick} />}
                  style={{ minHeight: 385 }}
                >
                  <DetailsList>
                    {deal?.lost_reason?.length > 0 && <ProfileDetailItem
                      label={t('Deal::Lost reason')}
                      value={<span style={{ color: Style.color.brandDanger }}>{deal?.lost_reason}</span>}
                    />}
                    <ProfileDetailItem
                      label={t('Deal::Amount')}
                      value={deal.amount ? <Link onClick={this.onEditDealClick}>{NumberFormatter.formatCurrency(deal.currency, setting.number_format, deal.amount)}</Link> : <Link onClick={this.onEditDealClick}>{t('Deal::Add an amount')}</Link>}
                    />
                    <ProfileDetailItem
                      label={t('Deal::Contact')}
                      value={<Link onClick={this.onEditDealClick}>{deal?.contact?.name || t('Deal::Add a contact')}</Link>}
                    />
                    <ProfileDetailItem
                      label={t('Deal::Project')}
                      value={<Link onClick={this.onEditDealClick}>{deal?.project?.name || t('Deal::Add a project')}</Link>}
                    />
                    <ProfileDetailItem
                      label={t('Deal::Assignee')}
                      value={deal.assignee ? <Link style={{ textAlign: 'left' }} onClick={this.onContactClick}>{[deal?.assignee?.name].join(" ")}</Link> : <Link onClick={this.onEditDealClick}>{t('Deal::Add assignee')}</Link>}
                    />
                    <ProfileDetailItem
                      label={t('Deal::Deadline')}
                      value={deal.due_on ? <Link onClick={this.onEditDealClick}>{deal.due_on}</Link> : <Link onClick={this.onEditDealClick}>{t('Deal::Add a deadline')}</Link>}
                    />
                    <ProfileDetailItem
                      label={t('Deal::Notes')}
                      value={deal.notes?.length > 0 ? <FroalaEditorView model={deal.notes} /> : <a href='javascript://' onClick={this.onEditNotesClick}>{t('Deal::Add notes')}</a>}
                    />
                  </DetailsList>
                </Panel>
              </div>

              <div className='grid-cell with-8col'>
                <Panel title={t('Deal::Planned actions')}>
                  <ButtonGroup
                    items={[
                      { element: <AddActivityButton><Icon icon='envelope' /><span>{t('Deal::Email')}</span></AddActivityButton>, onClick: this.onEmailClick },
                      { element: <AddActivityButton><Icon icon='calendar-day' /><span>{t('Deal::Appointment')}</span></AddActivityButton>, onClick: this.onScheduleAppointmentClick },
                      { element: <AddActivityButton><Icon icon='phone' /><span>{t('Deal::Call')}</span></AddActivityButton>, onClick: this.onScheduleCallClick },
                      { element: <AddActivityButton><Icon icon='tasks' /><span>{t('Deal::Task')}</span></AddActivityButton>, onClick: this.onScheduleTaskClick }
                    ]}
                  />

                  <ActivityFeedContainer>
                    <ActivityFeed
                      ref={this.activityFeed}
                      trackableType={ActivityTrackableType.DEAL}
                      trackableId={deal.id}
                      history={this.props.history}
                    />
                  </ActivityFeedContainer>
                </Panel>

                {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.FILE) && <Panel title={t('Deal::Files')} contentClasses='no-padding'>
                  <ResourceFiles
                    resourceId={deal.id}
                    resourceType={BizzeyFileResourceType.DEAL}
                  />
                </Panel>}
              </div>

              {UserWorkspaceSettingHelper.hasScope(UserWorkspaceSettingScope.INVOICE) &&
                <div className='grid-cell with-12col'>
                  <Panel
                    title={t('Deal::Track your deal')}
                    contentClasses='no-padding'
                  >
                    <TabItems style={{ paddingLeft: 12, paddingRight: 12 }}>
                      <TabItem
                        active={activeDealTab === LedgerItemTab.QUOTATIONS}
                        onClick={() => this.onActiveLedgerItemTabChange(LedgerItemTab.QUOTATIONS)}
                      >
                        {t('Deal::Offers')}
                      </TabItem>
                      <TabItem
                        active={activeDealTab === LedgerItemTab.PROPOSALS}
                        onClick={() => this.onActiveLedgerItemTabChange(LedgerItemTab.PROPOSALS)}
                      >
                        {t('Deal::Proposals')}
                      </TabItem>
                      <TabItem
                        active={activeDealTab === LedgerItemTab.INVOICES}
                        onClick={() => this.onActiveLedgerItemTabChange(LedgerItemTab.INVOICES)}
                      >
                        {t('Deal::Invoices')}
                      </TabItem>
                      <TabItem
                        active={activeDealTab === LedgerItemTab.TIMESHEET}
                        onClick={() => this.onActiveLedgerItemTabChange(LedgerItemTab.TIMESHEET)}
                      >
                        {t('Deal::Timesheet')}
                      </TabItem>
                    </TabItems>

                    <div>
                      {activeDealTab === LedgerItemTab.QUOTATIONS && <LedgerItemsTable
                        type={LedgerItemType.QUOTATION}
                        contactId={deal?.contact_id}
                        projectId={deal?.project_id}
                        dealId={deal.id}
                        primaryActionEnabled={true}
                      />}

                      {activeDealTab === LedgerItemTab.PROPOSALS && <ProposalsTable
                        contactId={deal?.contact_id}
                        projectId={deal?.project_id}
                        dealId={deal?.id}
                        primaryActionEnabled={true}
                      />}

                      {activeDealTab === LedgerItemTab.INVOICES && <LedgerItemsTable
                        type={LedgerItemType.INVOICE}
                        contactId={deal?.contact_id}
                        projectId={deal?.project_id}
                        dealId={deal.id}
                        primaryActionEnabled={true}
                      />}

                      {activeDealTab === LedgerItemTab.TIMESHEET && <TimeEntriesTable
                        contactId={deal?.contact_id}
                        projectId={deal?.project_id}
                        dealId={deal?.id}
                      />}
                    </div>
                  </Panel>
                </div>}
            </div>
          </>}
        </PageContent >
      </>
    )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser,
    },
  } = state

  return {
    currentUser: currentUser,
  }
}

const mapDispatchToProps: IDispatchToProps = {
  showDealModal,
  showConfirmModal,
  showCalendarEventModal,
  showTaskModal,
  showSendEmailModal
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Deal))