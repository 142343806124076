import * as React from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router-dom'
import Button from '../components/Button/Button'
import ScrollToTopOnMount from '../components/Effects/ScrollToTopOnMount'
import TopNavigation from '../components/Navigation/TopNavigation'
import PageContent from '../components/Page/PageContent'
import PageHeader from '../components/Page/PageHeader'
import ContractsTable from '../components/Contracts/ContractsTable'
import ERoute from '../ERoute'
import RouteHelper from '../helpers/RouteHelper'
import FullPageContent from '../components/Page/FullPageContent'
import Images from '../images'
import CurrentUserHelper from '../helpers/CurrentUserHelper'

type IProps = & RouteComponentProps<any>

const Contracts = (props: IProps) => {
	const { t } = useTranslation()
	const { history } = props

	const onNewContractClick = async () => {
		history.push(RouteHelper.process(ERoute.PATH_CONTRACTS_CREATE))
	}

	return (
		<>
			<Helmet>
				<title>{t('Contracts::{{__appName}} | Contracts')}</title>
			</Helmet>
			<ScrollToTopOnMount />

			<TopNavigation
				icon='file-contract'
				title={t('Contacts::Contracts')}
				action={
					<Button
						type='primary'
						icon='plus'
						text={t('Contracts::New Contract')}
						onClick={onNewContractClick}
						extraClasses='page-action'
					/>
				}
			/>

			<PageContent>
				<PageHeader title={t('Contracts::Contracts')} />
				<ContractsTable />
			</PageContent>
		</>
	)
}

export default Contracts