import * as React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Task } from '../../types'
import ResourceCheckbox from '../Resource/ResourceCheckbox'
import TableContainer from './TableContainer'
import Table from './Table'
import TableCell from './TableCell'
import TableHead from './TableHead'
import TableCellContent from './TableCellContent'
import TableBody from './TableBody'
import TableRow from './TableRow'
import TaskTitle from './TaskTitle'
import moment from '../../utilities/Moment'
import TaskHelper from '../../helpers/TaskHelper'
import Icon from '../Icons/Icon'
import { useDebouncedCallback } from 'use-debounce'
import { Style } from '../../styles'
import TaskLabel from './TaskLabel'
import Avatar from '../Avatar/Avatar'
import AvatarStack from '../Avatar/AvatarStack'
import TaskIndicatorsContainer from './TaskIndicatorsContainer'
import TaskIndicators from './TaskIndicators'
import { TaskViewMode } from './TaskView'
import TableSortButton from './TableSortButton'

const BoardIndicator = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 4px;
	overflow: hidden;
	padding-left: 2px;
	position: absolute;
	left: -6px;
	border-radius: 3px;
	z-index: 999;
	cursor: pointer;

	&:hover {
		overflow: visible;
		background-color: transparent !important;
	}
`

const BoardIndicatorContent = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	position: relative;
	height: 100%;
	padding: 4px 8px;
	color: white;
	border-radius: 3px;
	margin-left: -2px;

	svg {
		width: 15px;
		margin-right: 4px;
	}
`

const Container = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	height: 100%;
	width: 100%;
`

const CheckboxContainer = styled.div`
	margin-right: 8px;
`

const TableCellTaskName = styled(TableCell)`
	width: 287.223px;
	min-width: 287.223px;
	max-width: 287.223px;

	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
		width: 150px;
		min-width: 150px;
		max-width: 150px;
	}
`

const EstimatedTimeContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	grid-gap: 4px;

`

type IProps = {
	mode?: TaskViewMode
	tasks: Task[]
	onTaskClick: (task: Task) => void
	onTaskChange: (task: Task) => void
	onTaskBoardClick: (task: Task) => void
	maxHeight?: string
	dateFormat: string
	sortValue?: string
	onSortChange?: (sortValue: string) => void
	onEndReached?: () => void
}

const TasksTable = (props: IProps) => {
	const container = React.useRef<HTMLDivElement>()
	const { t } = useTranslation()
	const { mode, tasks, dateFormat, maxHeight, sortValue, onSortChange, onEndReached } = props
	const nowMoment = moment()

	const debouncedScrollChange = useDebouncedCallback(
		(event) => onScrollChange(event), 50
	);

	const onScrollChange = (event) => {
		var node = event.target;
		const endReached = node.scrollHeight - node.scrollTop - 200 <= node.clientHeight;

		if (endReached && onEndReached) onEndReached()
	}

	React.useEffect(() => {
		if (container.current) container.current.addEventListener('scroll', debouncedScrollChange)

		return () => {
			if (container.current) container.current.removeEventListener('scroll', debouncedScrollChange)
		}
	}, [])

	const onBoardIndicatorClick = (task: Task) => {
		props.onTaskBoardClick(task)
	}

	const onTaskClick = (task: Task) => {
		props.onTaskClick(task)
	}

	const onTaskCompletedChange = (task: Task) => {
		props.onTaskChange({ ...task, completed: !task.completed })
	}

	const onSortToggle = (sortValue) => {
		props.onSortChange(sortValue)
	}

	const getHeaders = () => {

		if (mode === TaskViewMode.WORK_OVERVIEW) {
			const totalTasksEstimate = tasks.reduce((acc, task) => acc + task.time_estimate, 0)
			return (
				<>
					<TableCellTaskName>
						<TableCellContent>
							{t('TasksTableView::Task')}
							{onSortChange && <TableSortButton
								sortValue={sortValue}
								property='name'
								onClick={onSortToggle}
							/>}
						</TableCellContent>
					</TableCellTaskName>
					<TableCell>
						<TableCellContent justifyContent='flex-end'>
							{onSortChange && <TableSortButton
								property='users.name'
								sortValue={sortValue}
								onClick={onSortToggle}
							/>}
							{t('TasksTableView::Assignee')}
						</TableCellContent>
					</TableCell>
					<TableCell>
						<TableCellContent justifyContent='flex-end'>
							{onSortChange && <TableSortButton
								property='board_lists.name'
								sortValue={sortValue}
								onClick={onSortToggle}
							/>}
							{t('TasksTableView::List')}
						</TableCellContent>
					</TableCell>
					<TableCell>
						<TableCellContent justifyContent='flex-end'>
							{onSortChange && <TableSortButton
								property='due_on'
								sortValue={sortValue}
								onClick={onSortToggle}
							/>}
							{t('TasksTableView::Due date')}
						</TableCellContent>
					</TableCell>
					<TableCell>
						<TableCellContent justifyContent='space-between'>
							<EstimatedTimeContainer>
								{t('TasksTableView::Estimated time')}
								{onSortChange && <TableSortButton
									property='time_estimate'
									sortValue={sortValue}
									onClick={onSortToggle}
								/>}
							</EstimatedTimeContainer>
							{moment.duration({ 'seconds': totalTasksEstimate }).format(`hh[${t("TimeFormatter::h")}]mm`, { trim: false })}
						</TableCellContent>
					</TableCell>
				</>
			)
		}

		return (
			<>
				<TableCellTaskName>
					<TableCellContent>
						{t('TasksTableView::Task')}
						{onSortChange && <TableSortButton
							sortValue={sortValue}
							property='name'
							onClick={onSortToggle}
						/>}
					</TableCellContent>
				</TableCellTaskName>
				<TableCell>
					<TableCellContent justifyContent='flex-end'>
						{onSortChange && <TableSortButton
							property='users.name'
							sortValue={sortValue}
							onClick={onSortToggle}
						/>}
						{t('TasksTableView::Assignee')}
					</TableCellContent>
				</TableCell>
				<TableCell>
					<TableCellContent justifyContent='flex-end'>
						{onSortChange && <TableSortButton
							property='board_lists.name'
							sortValue={sortValue}
							onClick={onSortToggle}
						/>}
						{t('TasksTableView::List')}
					</TableCellContent>
				</TableCell>

				<TableCell>
					<TableCellContent justifyContent='flex-end'>
						{onSortChange && <TableSortButton
							property='due_on'
							sortValue={sortValue}
							onClick={onSortToggle}
						/>}
						{t('TasksTableView::Due date')}
					</TableCellContent>
				</TableCell>

				<TableCell>
					<TableCellContent justifyContent='flex-end'>
						{t('TasksTableView::Labels')}
					</TableCellContent>
				</TableCell>

				<TableCell>
					<TableCellContent justifyContent='flex-end'>
						{t('TasksTableView::Status')}
					</TableCellContent>
				</TableCell>
			</>
		)
	}

	const getDataRow = (task: Task) => {
		const momentDueOn = task.due_on ? moment(task.due_on) : null

		if (mode === TaskViewMode.WORK_OVERVIEW) {
			return (
				<TableRow key={task.id}>
					<TableCellTaskName>
						<TableCellContent>
							{task?.board && <BoardIndicator style={{ backgroundColor: task.board.color }} onClick={() => onBoardIndicatorClick(task)}>
								<BoardIndicatorContent style={{ backgroundColor: task.board.color }}>
									<Icon icon='trello' />
									{task.board.name}
								</BoardIndicatorContent>
							</BoardIndicator>}

							<CheckboxContainer>
								<ResourceCheckbox
									checked={Boolean(task.completed)}
									disabled={false}
									onCheckedChange={() => onTaskCompletedChange(task)}
								/>
							</CheckboxContainer>

							<TaskTitle onClick={() => onTaskClick(task)} title={task.name}>
								{task.name}
							</TaskTitle>
						</TableCellContent>
					</TableCellTaskName>

					<TableCell onClick={() => onTaskClick(task)}>
						<TableCellContent justifyContent='flex-end'>
							{task?.assignees?.length === 0 && '-'}
							{task?.assignees?.length > 0 && <>
								<AvatarStack width={25.5}>
									{task.assignees.map(assignee => (
										<Avatar
											width={25.5}
											name={TaskHelper.getAssigneeName(assignee)}
											rounded
										/>
									))}
								</AvatarStack>
							</>}
						</TableCellContent>
					</TableCell>


					<TableCell onClick={() => onTaskClick(task)}>
						<TableCellContent justifyContent='flex-end'>
							{task.list ? task?.list?.name : '-'}
						</TableCellContent>
					</TableCell>

					<TableCell onClick={() => onTaskClick(task)}>
						<TableCellContent justifyContent='flex-end'>
							{momentDueOn && <>
								{nowMoment.isAfter(momentDueOn, 'day') && <span style={{ marginRight: 8, color: Style.color.brandDanger }} data-tip={t('TasksTable::This task is {{count}} days overdue', { count: nowMoment.diff(momentDueOn, 'day') })}>
									<Icon icon='exclamation-triangle' />
								</span>}

								{momentDueOn.format(dateFormat)}
							</>}
							{!momentDueOn && '-'}
						</TableCellContent>
					</TableCell>


					<TableCell onClick={() => onTaskClick(task)}>
						<TableCellContent justifyContent='flex-end'>
							{task?.time_estimate > 0 ? moment.duration({ 'seconds': task.time_estimate }).format(`hh[${t("TimeFormatter::h")}]mm`, { trim: false }) : '-'}
						</TableCellContent>
					</TableCell>
				</TableRow >
			)
		}

		return (
			<TableRow key={task.id}>
				<TableCellTaskName>
					<TableCellContent>
						{task?.board && <BoardIndicator style={{ backgroundColor: task.board.color }} onClick={() => onBoardIndicatorClick(task)}>
							<BoardIndicatorContent style={{ backgroundColor: task.board.color }}>
								<Icon icon='trello' />
								{task.board.name}
							</BoardIndicatorContent>
						</BoardIndicator>}

						<CheckboxContainer>
							<ResourceCheckbox
								checked={Boolean(task.completed)}
								disabled={false}
								onCheckedChange={() => onTaskCompletedChange(task)}
							/>
						</CheckboxContainer>

						<TaskTitle onClick={() => onTaskClick(task)} title={task.name}>
							{task.name}
						</TaskTitle>
					</TableCellContent>
				</TableCellTaskName>

				<TableCell onClick={() => onTaskClick(task)}>
					<TableCellContent justifyContent='flex-end'>
						{task?.assignees?.length === 0 && '-'}
						{task?.assignees?.length > 0 && <>
							<AvatarStack width={25.5}>
								{task.assignees.map(assignee => (
									<Avatar
										width={25.5}
										name={TaskHelper.getAssigneeName(assignee)}
										rounded
									/>
								))}
							</AvatarStack>
						</>}
					</TableCellContent>
				</TableCell>


				<TableCell onClick={() => onTaskClick(task)}>
					<TableCellContent justifyContent='flex-end'>
						{task?.list?.name || '-'}
					</TableCellContent>
				</TableCell>


				<TableCell onClick={() => onTaskClick(task)}>
					<TableCellContent justifyContent='flex-end'>
						{momentDueOn && <>
							{nowMoment.isAfter(momentDueOn, 'day') && <span style={{ marginRight: 8, color: Style.color.brandDanger }} data-tip={t('TasksTable::This task is {{count}} days overdue', { count: nowMoment.diff(momentDueOn, 'day') })}>
								<Icon icon='exclamation-triangle' />
							</span>}
							{momentDueOn.format(dateFormat)}
						</>}
						{!momentDueOn && '-'}
					</TableCellContent>
				</TableCell>

				<TableCell onClick={() => onTaskClick(task)}>
					<TableCellContent justifyContent='flex-end'>
						{task?.labels?.map((label) => {
							return (
								<TaskLabel
									key={label.id}
									labelsExpanded={true}
									background={label.color}
								>
									<span>{label.name}</span>
								</TaskLabel>
							)
						})}
						{task?.labels?.length === 0 && '-'}
					</TableCellContent>
				</TableCell>

				<TableCell onClick={() => onTaskClick(task)}>
					<TableCellContent justifyContent='flex-end'>
						<TaskIndicatorsContainer noWrap>
							<TaskIndicators task={task} />
						</TaskIndicatorsContainer>
					</TableCellContent>
				</TableCell>
			</TableRow>
		)
	}

	return (
		<Container>
			<TableContainer ref={container} maxHeight={maxHeight}>
				<Table>
					<TableHead>
						{getHeaders()}
					</TableHead>

					<TableBody>
						{tasks.map(task => {
							return getDataRow(task)
						})}
					</TableBody>
				</Table>
			</TableContainer>
		</Container>
	)
}

export default TasksTable